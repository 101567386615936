import { useAppSelector } from '../../hooks/redux'

const useProfile = () => {
  const { userProfile, token } = useAppSelector(state => ({
    userProfile: state.User.user,
    token: state.User.accessToken,
  }))

  return { userProfile, token }
}

export { useProfile }
