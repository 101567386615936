import axios from 'axios'

import { api } from '../config'
import {
  GET_DEPARTMENTS,
  GET_POSITIONS,
  POST_FORGOT_PASSWORD,
  POST_LOGIN,
  POST_RESET_PASSWORD,
  GET_LOGS,
  GET_COMPANIES_FACILITIES,
  POST_GROUP,
  PATCH_GROUP,
  POST_DOCUMENT,
  GET_DOCUMENTS,
  DEFAULT_COURSE_SETTINGS,
  CERTIFICATE_TEMPLATES,
  ONLINE_COURSE_CATEGORIES,
  POST_ONLINE_COURSE_CATEGORIES,
  ONLINE_TAGS,
  POST_ONLINE_TAG,
  PUT_ONLINE_TAG,
  LANGUAGES,
  POST_GENERAL_DRAFT_ONLINE_COURSE,
  POST_GENERAL_ONLINE_COURSE,
  POST_DIGITAL_COURSE_FILE,
  PUBLISH_COURSE,
  PUT_DIGITAL_COURSE,
  DELETE_COURSE_FORMAT,
  GET_ONLINE_COURSES,
  GET_ONLINE_COURSE,
  GET_ROLES,
  DELETE_SCHEDULED_COURSES,
  GET_SCHEDULED_COURSES,
  POST_COURSE_DEFAULT,
  POST_COURSE_FORMAT,
  GET_LIVE_COURSE,
  DELETE_ONLINE_COURSE,
  CLONE_ONLINE_COURSE,
  PUT_COMPETENCY_GENERAL_COURSE,
  PATCH_SCHEDULED_COURSES,
  ADD_LIVE_COURSE_MEDIA,
  DELETE_LIVE_COURSE_MEDIA,
  GET_COURSE_QUESTIONS,
  POST_COURSE_QUESTION,
  DELETE_COURSE_QUESTION,
  GET_COURSE_QUESTION_GROUP,
  PUT_COURSE_QUESTION_GROUP,
  CREATE_COURSE_REVISION,
  POST_COURSE_QUESTIONS_ORDER,
  GET_TRANSLATABLE_LANGUAGES,
  LANGUAGES_LIST,
} from './url_helper'
import {
  LoginUserDTO,
  ForgotPasswordDTO,
  ResetPasswordDTO,
  GetOnlineCoursesDTO,
  PostOnlineCourseQuestionDTO,
  GetDepartmentsDTO,
  GetLogsDTO,
  GetCompaniesFacilitiesDTO,
  CreateGroupDTO,
  CreateDocumentDto,
  GetDocumentsDTO,
  PostScheduledCourseDTO,
  GetOnlineCategoriesDTO,
  GetOnlineTagsDTO,
  PublishOnlineCourseDTO,
  EditOnlineTagDTO,
  CreateOnlineTagDTO,
  CreateOnlineCategoryDTO,
  PatchOnlineCourseDTO,
  PostDefaultOnlineCourseDTO,
  PostOnlineCourseFormatDTO,
  GetDefaultCourseSettingsDTO,
  PutDefaultCourseSettingsDTO,
  GetCertificateTemplatesDTO,
  GetOnlineCourseDTO,
  PutDigitalCourseDTO,
  RemovetOnlineCourseFormatDTO,
  PutCompetencyGeneralCourseDTO,
  TGroup,
  GetPositionsDTO,
  PermissionSet,
  GetScheduledCourseDTO,
  GetLiveCourseDTO,
  AddLiveCourseMediaDTO,
  GetCourseQuestionsDTO,
  QuestionItem,
  TLanguage,
  ScheduledEntitiesTypes,
  CreateCourseRevisionDTO,
  UpdateCourseQuestionsOrderDTO,
} from '../sharedTypes'
import { store } from '../slices'
import { logoutUser } from '../slices/auth/user/thunk'
import { publish } from './events'

export * from './api/attachments'
export * from './api/scheduledCourses'
export * from './api/instructors'
export * from './api/scheduledCategories'
export * from './api/admins'
export * from './api/roles'
export * from './api/support'
export * from './api/facilities'
export * from './api/tutorials'
export * from './api/users'
export * from './api/companyNews'
export * from './api/assignments'
export * from './api/courses'
//export API instance
export const API = axios.create({
  baseURL: api.API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const authUser = sessionStorage.getItem('authUser')
const token = authUser ? JSON.parse(authUser).accessToken : null
if (token) {
  API.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
// intercepting to capture errors
API.interceptors.response.use(
  function (response) {
    publish('Non500ErrorOccurred')
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (axios.isAxiosError(error)) {
      const response = error.response
      if (response) {
        const statusCode = response.status
        if (statusCode === 401) {
          store.dispatch(logoutUser())
        }
        if (statusCode >= 500) {
          publish('500ErrorOccurred')
        } else {
          publish('Non500ErrorOccurred')
        }
      }
    } else {
      publish('Non500ErrorOccurred')
    }

    return Promise.reject(error)
  },
)

/**
 * Sets the default authorization
 * @param {*} token
 */
export const setAuthorization = (token: string) => {
  API.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

//AUTH
export const logInUser = (data: LoginUserDTO.Request) => {
  return API.post<LoginUserDTO.Response>(POST_LOGIN, data)
}

export const getUserRoles = () => {
  return API.get<PermissionSet>(`${GET_ROLES}/user`)
}

export const forgotPassword = (data: ForgotPasswordDTO.Request) => {
  return API.post(POST_FORGOT_PASSWORD, data)
}
export const resetPassword = (data: ResetPasswordDTO.Request) => {
  return API.post<ResetPasswordDTO.Response>(POST_RESET_PASSWORD, data)
}

// DEFAULT SETTINGS
export const getDefaultCourseSettings = (
  params: GetDefaultCourseSettingsDTO.Request,
) => {
  return API.get<GetDefaultCourseSettingsDTO.Response>(
    DEFAULT_COURSE_SETTINGS,
    {
      params,
    },
  ).then(d => d.data)
}

export const putDefaultCourseSettings = (data: FormData) => {
  return API.put<PutDefaultCourseSettingsDTO.Response>(
    DEFAULT_COURSE_SETTINGS,
    data,
  ).then(d => d.data)
}

export const getCertificateTemplates = () => {
  return API.get<GetCertificateTemplatesDTO.Response>(
    CERTIFICATE_TEMPLATES,
  ).then(d => d.data)
}

//ONLINE CATEGORIES
export const getOnlineCategories = (params: GetOnlineCategoriesDTO.Request) => {
  return API.get<GetOnlineCategoriesDTO.Response>(ONLINE_COURSE_CATEGORIES, {
    params,
  }).then(d => d.data)
}

export const updateOnlineCourseQuestionOrder = (
  data: UpdateCourseQuestionsOrderDTO.Request,
) => {
  return API.post<UpdateCourseQuestionsOrderDTO.Response>(
    POST_COURSE_QUESTIONS_ORDER,
    data,
  ).then(d => d.data)
}

export const postOnlineCategory = (data: CreateOnlineCategoryDTO.Request) => {
  return API.post<CreateOnlineCategoryDTO.Response>(
    POST_ONLINE_COURSE_CATEGORIES,
    data,
  )
}

// ONLINE COURSES

export const getOnlineCourses = (params: GetOnlineCoursesDTO.Request) => {
  return API.get<GetOnlineCoursesDTO.Response>(GET_ONLINE_COURSES, {
    params,
  }).then(d => d.data)
}

export const postOnlineCourseDraft = (body: FormData) => {
  return API.post<PatchOnlineCourseDTO.Response>(
    POST_GENERAL_DRAFT_ONLINE_COURSE,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  ).then(d => d.data)
}

export const postOnlineCourse = (body: FormData) => {
  return API.post<PatchOnlineCourseDTO.Response>(
    POST_GENERAL_ONLINE_COURSE,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  ).then(d => d.data)
}

export const putOnlineCourse = (id: number, body: FormData) => {
  return API.put<PatchOnlineCourseDTO.Response>(
    `${POST_GENERAL_ONLINE_COURSE}/${id}`,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  ).then(d => d.data)
}

export const putOnlineCourseImages = (id: number, body: FormData) => {
  return API.post<any>(`${POST_GENERAL_ONLINE_COURSE}/${id}/images`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(d => d.data)
}

export const deleteOnlineCourseImages = (id: number) => {
  return API.delete<any>(`${POST_GENERAL_ONLINE_COURSE}/${id}/images`).then(
    d => d.data,
  )
}

export const postDigitalCourseFile = (id: number, body: FormData) => {
  return API.post<PutDigitalCourseDTO.Response>(
    `${POST_DIGITAL_COURSE_FILE}/${id}`,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  ).then(d => d.data)
}

export const removeCourseFormat = (
  courseId: number,
  body: RemovetOnlineCourseFormatDTO.Request,
) => {
  return API.delete<RemovetOnlineCourseFormatDTO.Response>(
    `${DELETE_COURSE_FORMAT}/${courseId}/${body.format}`,
  ).then(d => d.data)
}

export const publishOnlineCourse = (courseId: number) => {
  return API.post<PublishOnlineCourseDTO.Response>(
    `${PUBLISH_COURSE}/${courseId}`,
  ).then(d => d.data)
}

export const addLiveCourseMedia = (
  courseId: number,
  body: AddLiveCourseMediaDTO.Request,
) => {
  return API.post<AddLiveCourseMediaDTO.Response>(
    `${ADD_LIVE_COURSE_MEDIA}/${courseId}`,
    body,
  ).then(d => d.data)
}

export const deleteLiveCourseMedia = (courseId: number, mediaId: number) => {
  return API.delete<void>(
    `${DELETE_LIVE_COURSE_MEDIA}/${courseId}/${mediaId}`,
  ).then(d => d.data)
}

export const putDigitalCourse = (
  courseId: number,
  body: PutDigitalCourseDTO.Request,
) => {
  return API.put<PutDigitalCourseDTO.Response>(
    `${PUT_DIGITAL_COURSE}/${courseId}`,
    body,
  ).then(d => d.data)
}

export const putCompetencyGeneralCourse = (
  courseId: number,
  body: PutCompetencyGeneralCourseDTO.Request,
) => {
  return API.put<PutCompetencyGeneralCourseDTO.Response>(
    `${PUT_COMPETENCY_GENERAL_COURSE}/${courseId}`,
    body,
  ).then(d => d.data)
}

export const postDefaultOnlineCourseFile = (
  body: PostDefaultOnlineCourseDTO.Request,
) => {
  return API.post<PostDefaultOnlineCourseDTO.Response>(
    `${POST_COURSE_DEFAULT}`,
    body,
  ).then(d => d.data)
}

export const posOnlineCourseFormat = (
  id: number,
  body: PostOnlineCourseFormatDTO.Request,
) => {
  return API.post<PostOnlineCourseFormatDTO.Response>(
    `${POST_COURSE_FORMAT}/${id}/${body.format}`,
  ).then(d => d.data)
}

export const getOnlineCourse = (
  id: number,
  params: GetOnlineCourseDTO.Request,
) => {
  return API.get<GetOnlineCourseDTO.Response>(GET_ONLINE_COURSE + '/' + id, {
    params,
  }).then(d => d.data)
}

export const postOnlineCourseQuestion = (
  id: number,
  data: PostOnlineCourseQuestionDTO.Request,
) => {
  return API.post<PostOnlineCourseQuestionDTO.Response>(
    POST_COURSE_QUESTION + '/' + id,
    data,
  ).then(d => d.data)
}

export const putOnlineCourseQuestion = (
  courseId: number,
  groupId: string,
  data: PostOnlineCourseQuestionDTO.Request,
) => {
  return API.put<PostOnlineCourseQuestionDTO.Response>(
    PUT_COURSE_QUESTION_GROUP(courseId, groupId),
    data,
  ).then(d => d.data)
}

export const deletetOnlineCourseQuestion = (
  courseId: number,
  groupId: string,
) => {
  return API.delete(`${DELETE_COURSE_QUESTION}/${courseId}/${groupId}`)
}

export const getOnlineCourseQuestions = (
  params: GetCourseQuestionsDTO.Request,
) => {
  return API.get<GetCourseQuestionsDTO.Response>(GET_COURSE_QUESTIONS, {
    params,
  }).then(d => d.data)
}

export const getOnlineCourseQuestionGroup = (
  courseId: number,
  groupId: string,
) => {
  return API.get<QuestionItem[]>(
    GET_COURSE_QUESTION_GROUP(courseId, groupId),
  ).then(d => d.data)
}

export const getCourseLiveSettings = (id: number) => {
  return API.get<GetLiveCourseDTO.Response>(GET_LIVE_COURSE + '/' + id).then(
    d => d.data,
  )
}

export const getScheduledCourses = (params: GetScheduledCourseDTO.Request) => {
  return API.get<GetScheduledCourseDTO.Response>(GET_SCHEDULED_COURSES, {
    params,
  }).then(d => d.data)
}
export const putScheduledCourses = (
  id: number,
  entityId: number,
  body: any,
) => {
  return API.put<PostScheduledCourseDTO.Response>(
    `${PATCH_SCHEDULED_COURSES}/${entityId}/${id}`,
    body,
  ).then(d => d.data)
}

export const deleteScheduledCourse = (
  id: number,
  entityId: number,
  entityType: ScheduledEntitiesTypes,
) => {
  return API.delete(
    `${DELETE_SCHEDULED_COURSES}/${id}/${entityType}/${entityId}`,
  )
}

export const postScheduledCourses = (courseId: number, body: any) => {
  return API.post<PostScheduledCourseDTO.Response>(
    PATCH_SCHEDULED_COURSES + '/' + courseId,
    body,
  ).then(d => d.data)
}

export const deleteOnlineCourse = (id: number) => {
  return API.delete(DELETE_ONLINE_COURSE + `/${id}`)
}

export const cloneOnlineCourse = (id: number) => {
  return API.post<GetOnlineCourseDTO.Response>(
    CLONE_ONLINE_COURSE + `/${id}`,
  ).then(d => d.data)
}

export const createCourseRevision = (
  id: number,
  data: CreateCourseRevisionDTO.Request,
) => {
  return API.post<GetOnlineCourseDTO.Response>(
    CREATE_COURSE_REVISION + `/${id}`,
    data,
  ).then(d => d.data)
}

//ONLINE TAGS
export const getOnlineTags = (params: GetOnlineTagsDTO.Request) => {
  return API.get<GetOnlineTagsDTO.Response>(ONLINE_TAGS, {
    params,
  }).then(d => d.data)
}

export const postOnlineTags = (body: CreateOnlineTagDTO.Request) => {
  return API.post<CreateOnlineTagDTO.Response>(POST_ONLINE_TAG, body).then(
    d => d.data,
  )
}

export const putOnlineTags = (id: number, body: EditOnlineTagDTO.Request) => {
  return API.put<EditOnlineTagDTO.Response>(
    `${PUT_ONLINE_TAG}/${id}`,
    body,
  ).then(d => d.data)
}

// LANGUAGE
export const getLanguages = () => {
  return API.get<TLanguage[]>(LANGUAGES).then(d => d.data)
}

export const getAllLanguages = () => {
  return API.get<TLanguage[]>(LANGUAGES_LIST).then(d => d.data)
}

export const getTranslatableLanguages = () => {
  return API.get<TLanguage[]>(GET_TRANSLATABLE_LANGUAGES).then(d => d.data)
}

// DOCUMENT
export const getDocumentsDTO = (params: GetDocumentsDTO.Request) => {
  return API.get<GetDocumentsDTO.Response>(GET_DOCUMENTS, { params }).then(
    d => d.data,
  )
}

export const postDocument = (values: CreateDocumentDto.Request) => {
  const formData = new FormData()

  formData.append('type', values.type)
  formData.append('organization', values.organization)
  formData.append('validDate', new Date(values.validDate).toISOString())
  formData.append('expiryDate', new Date(values.expiryDate).toISOString())
  formData.append('document', values.document)
  formData.append('certificateId', values.certificateId)

  return API.post<CreateDocumentDto.Response>(POST_DOCUMENT, formData).then(
    d => d.data,
  )
}

//DEPARTMENTS
export const getDepartments = (params: GetDepartmentsDTO.Request) => {
  return API.get<GetDepartmentsDTO.Response>(GET_DEPARTMENTS, { params })
}

//POSITIONS
export const getPositions = (params = {}) => {
  return API.get<GetPositionsDTO.Response>(GET_POSITIONS, {
    params,
  })
}

//LOGS
export const getImportLogs = (params: GetLogsDTO.Request) => {
  return API.get<GetLogsDTO.Response>(GET_LOGS, { params })
}

//COMPANIES
export const getCompaniesHierarchy = (
  params: GetCompaniesFacilitiesDTO.Request,
) => {
  return API.get<GetCompaniesFacilitiesDTO.Response>(GET_COMPANIES_FACILITIES, {
    params,
  })
}

//GROUPS
export const postGroup = (data: CreateGroupDTO.Request) => {
  return API.post<CreateGroupDTO.Response>(POST_GROUP, data)
}

export const getGroups = () => {
  return API.get<TGroup[]>(POST_GROUP)
}

export const patchGroup = (
  id: number,
  data: Partial<CreateGroupDTO.Request>,
) => {
  return API.patch(PATCH_GROUP + `/${id}`, data)
}
