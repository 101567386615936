import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { USER_STATUSES } from '../../../sharedTypes'

interface FilterTabsProps {
  navTab?: USER_STATUSES
  navToggle: (tab?: USER_STATUSES) => void
}
const tabs = [
  { label: 'All', value: undefined },
  { label: 'Active', value: USER_STATUSES.ACTIVE },
  { label: 'Inactive', value: USER_STATUSES.INACTIVE },
]

const FilterTabs = ({ navTab, navToggle }: FilterTabsProps) => {
  return (
    <Nav className='nav-customs-bordered'>
      {tabs.map(tab => (
        <NavItem key={tab.label}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
            }}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
