import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { LOG_TYPES } from '../../../sharedTypes'

interface FilterTabsProps {
  navTab?: LOG_TYPES
  navToggle: (tab?: LOG_TYPES) => void
}
const tabs = [
  { label: 'All', value: undefined },
  { label: 'New', value: LOG_TYPES.NEW },
  { label: 'Updated', value: LOG_TYPES.UPDATED },
  { label: 'Error', value: LOG_TYPES.ERROR },
]

const FilterTabs = ({ navTab, navToggle }: FilterTabsProps) => {
  return (
    <Nav className='nav-customs-bordered'>
      {tabs.map(tab => (
        <NavItem key={tab.value}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
            }}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
