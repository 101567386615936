import {
  Button,
  Card,
  CardBody,
  CardHeader,
  TabContent,
  TabPane,
} from 'reactstrap'
import React, { useMemo, useRef, useState } from 'react'
import UsersNotRegistered from './UsersNotRegistered'
import ImportErrors from './ImportErrors'
import { Tabs } from '../types'
import AnnualEducationOverdue from './AnnualEducationOverdue'
import { TUser, UserManagementPermissions } from '../../../sharedTypes'
import { usePermissions } from '../../../hooks/usePermissions'

const SCROLL_SIZE = 220

const MainTabs = ({ user }: { user: TUser | null }) => {
  const permissions = {
    viewUserListing: usePermissions(
      UserManagementPermissions.VIEW_USER_LISTING,
    ),
    viewImportErrors: usePermissions(UserManagementPermissions.VIEW_IMPORT_LOG),
  }

  const defaultActiveTab = useMemo(() => {
    if (permissions.viewUserListing) {
      return Tabs.USERS_NOT_REGISTERED
    }

    if (permissions.viewImportErrors) {
      return Tabs.IMPORT_ERRORS
    }

    return Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION
  }, [permissions])

  const [activeTab, setActiveTab] = useState(defaultActiveTab)

  const [totalCounts, setTotalCounts] = useState({
    [Tabs.USERS_NOT_REGISTERED]: 0,
    [Tabs.IMPORT_ERRORS]: 0,
    [Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION]: 0,
  })

  const [scrollLeft, setScrollLeft] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const tabsData = useMemo(() => {
    const allowedTabs = []
    if (permissions.viewUserListing) {
      allowedTabs.push(Tabs.USERS_NOT_REGISTERED)
    }

    if (permissions.viewImportErrors) {
      allowedTabs.push(Tabs.IMPORT_ERRORS)
    }

    allowedTabs.push(Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION)
    return allowedTabs
  }, [permissions])

  const tabChange = (tab: Tabs) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleScrollRight = () => {
    if (containerRef.current) {
      const scrollSize = scrollLeft >= SCROLL_SIZE ? -SCROLL_SIZE : SCROLL_SIZE
      const newScrollLeft = containerRef.current.scrollLeft + scrollSize
      containerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      })
      setScrollLeft(newScrollLeft)
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <div
            className='d-flex gap-3 slide-tabs-container'
            id='slide-tabs-container'
            ref={containerRef}
          >
            {Object.values(tabsData).map((tab, index) => (
              <div key={index} className='d-flex flex-grow-1'>
                <Button
                  color={'primary'}
                  className={`${
                    activeTab === tab ? 'btn btn-primary' : 'btn-light'
                  } align-middle cursor-pointer w-100`}
                  onClick={() => {
                    tabChange(tab)
                  }}
                >
                  {tab} ({totalCounts[tab]})
                </Button>
              </div>
            ))}
          </div>
          {scrollLeft >= SCROLL_SIZE ? (
            <div
              className={`slide-tab-arrow left cursor-pointer d-flex ${
                !user?.facilityId ? 'd-xxl-none' : ''
              }`}
              onClick={handleScrollRight}
            >
              <i className='ri-arrow-left-line fs-4'></i>
            </div>
          ) : (
            <div
              className={`slide-tab-arrow right cursor-pointer d-flex ${
                !user?.facilityId ? 'd-xxl-none' : ''
              }`}
              onClick={handleScrollRight}
            >
              <i className='ri-arrow-right-line fs-4'></i>
            </div>
          )}
        </CardHeader>
        <CardBody className='p-0'>
          <TabContent activeTab={activeTab}>
            {permissions.viewUserListing && (
              <TabPane tabId={Tabs.USERS_NOT_REGISTERED}>
                <UsersNotRegistered setTotalCounts={setTotalCounts} />
              </TabPane>
            )}
            {permissions.viewImportErrors && (
              <TabPane tabId={Tabs.IMPORT_ERRORS}>
                <ImportErrors setTotalCounts={setTotalCounts} />
              </TabPane>
            )}

            <TabPane tabId={Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION}>
              <AnnualEducationOverdue setTotalCounts={setTotalCounts} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  )
}

export default MainTabs
