import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

export interface BreadCrumbProps {
  title: string
  items: {
    title: string
    linkTo?: string
    active?: boolean
  }[]
}

const BreadCrumb = ({ title, items }: BreadCrumbProps) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <h4 className='mb-sm-0'>{title}</h4>

            {/*<div className='page-title-right'>*/}
            {/*  <ol className='breadcrumb m-0'>*/}
            {/*    {items.map(item =>*/}
            {/*      item.active ? (*/}
            {/*        <li className='breadcrumb-item active' key={item.title}>*/}
            {/*          {item.title}*/}
            {/*        </li>*/}
            {/*      ) : (*/}
            {/*        <li className='breadcrumb-item' key={item.title}>*/}
            {/*          {item.linkTo ? (*/}
            {/*            <Link to={item.linkTo}>{item.title}</Link>*/}
            {/*          ) : (*/}
            {/*            item.title*/}
            {/*          )}*/}
            {/*        </li>*/}
            {/*      ),*/}
            {/*    )}*/}
            {/*  </ol>*/}
            {/*</div>*/}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BreadCrumb
