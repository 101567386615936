import React, { useEffect, useMemo } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  FormFeedback,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { SingleValue } from 'react-select'
import _ from 'lodash'

import { TCompany, TGroup, CreateGroupDTO } from '../../../sharedTypes'
import {
  GroupSelectWithSearch,
  IGroupOptions,
} from '../../../Components/Common/SelectWithSearch'
import { patchGroup, postGroup } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import { successToastOptions, handleError } from '../../../helpers/toast_helper'

interface CreateNewCroupModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  toggle: ModalProps['toggles']
  companies: TCompany[]
  onSubmit: () => void
  editGroup?: TGroup
}

interface Option extends IGroupOptions {
  parentType?: 'company' | 'group'
  companyId: number
}

interface IForm {
  name: string
  parent: Option
}

const getGroupOption = (
  groups: TGroup[],
  parentType: Option['parentType'],
  parentValue: Option['parentValue'],
  level: number,
  editGroup?: TGroup,
) => {
  const options: Option[] = []
  groups.forEach(group => {
    if (editGroup && editGroup.id === group.id) {
      return
    }

    const option: Option = {
      label: group.name,
      value: 'group:' + group.id,
      parentValue: parentValue,
      parentType: parentType,
      level,
      hasChildren: false,
      companyId: group.companyId,
    }
    if (!_.isEmpty(group.subGroups)) {
      const subGroups = getGroupOption(
        group.subGroups,
        'group',
        option.value,
        level + 1,
        editGroup,
      )
      option.hasChildren = !_.isEmpty(subGroups)
      options.push(option)
      options.push(...subGroups)
    } else {
      options.push(option)
    }
  })
  return options
}

const getParentOption = (options: Option[], group?: TGroup) => {
  if (group) {
    const value = group.parentGroupId
      ? 'group:' + group.parentGroupId
      : 'company:' + group.companyId
    const selected = options.find(option => {
      return option.value === value
    })
    if (selected) {
      return selected
    }
  }
  return options[0]
}

const CreateNewGroupModal = ({
  onClose,
  toggle,
  isOpen,
  companies,
  onSubmit,
  editGroup,
}: CreateNewCroupModalProps) => {
  const groupOptions = useMemo(() => {
    const options: Option[] = []
    companies.forEach(company => {
      const option: Option = {
        label: company.name,
        value: 'company:' + company.id,
        level: 0,
        hasChildren: false,
        companyId: company.id,
      }
      if (!_.isEmpty(company.groups)) {
        const groups = getGroupOption(
          company.groups,
          'company',
          option.value,
          1,
          editGroup,
        )

        option.hasChildren = !_.isEmpty(groups)
        options.push(option)
        options.push(...groups)
      } else {
        options.push(option)
      }
    })

    return options
  }, [companies, editGroup])

  const onCreateGroup = ({ name, parent }: IForm) => {
    const data: CreateGroupDTO.Request = {
      name,
      companyId: parent.companyId,
    }

    const [type, id] = parent.value.split(':')
    if (type === 'group' && id) {
      data.parentGroupId = +id
    } else if (editGroup) {
      data.parentGroupId = null
    }

    const action = editGroup ? patchGroup(editGroup.id, data) : postGroup(data)

    action
      .then(() => {
        editGroup
          ? toast('Success - Group has been updated', successToastOptions)
          : toast('Success - New Group has been created', successToastOptions)
        onSubmit()
      })
      .catch(handleError)
      .finally(() => {
        form.setSubmitting(false)
      })
  }

  useEffect(() => {
    !isOpen && form.resetForm()
  }, [isOpen])

  const form = useFormik<IForm>({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: editGroup?.name || '',
      parent: getParentOption(groupOptions, editGroup),
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Group Name'),
    }),
    onSubmit: onCreateGroup,
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>
            {editGroup ? 'Edit Group' : 'Add New Group'}
          </h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={toggle}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='FacilityManagement#'
        >
          <div className='vstack gap-3'>
            <div>
              <label htmlFor='groupName' className='form-label'>
                Group Name*
              </label>
              <Input
                name='name'
                className='form-control'
                id='groupName'
                placeholder='Enter group name'
                type='text'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.name || ''}
                invalid={!!(form.touched.name && form.errors.name)}
              />
              {form.touched.name && form.errors.name ? (
                <FormFeedback type='invalid'>{form.errors.name}</FormFeedback>
              ) : null}
            </div>
            <div>
              <label htmlFor='parent' className='form-label'>
                Parent Group*
              </label>
              <GroupSelectWithSearch<Option>
                name='parent'
                id='parent'
                onChange={(option: SingleValue<Option>) =>
                  form.setFieldValue('parent', option)
                }
                onBlur={form.handleBlur}
                value={form.values.parent}
                isMulti={false}
                isClearable={false}
                isSearchable={true}
                placeholder={''}
                options={groupOptions}
              />
            </div>
          </div>
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={onClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={!(form.isValid && form.dirty) || form.isSubmitting}
            >
              Submit
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default CreateNewGroupModal
