import { ASSIGNMENT_STATUS, TAssignment } from '../models'
import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import { ASSIGNMENT_FILTER_TYPES } from '../../pages/Reporting/CourseHistory/types'
import { COURSE_TYPES, ReportingPermissions } from '../lib/types'

export enum AssignmentsSortBy {
  ID = 'id',
  PACKAGE = 'packageCode',
  CODE = 'code',
  COURSE = 'courseName',
  FORMAT = 'format',
  PROGRESS = 'progress',
  TEST_SCORE = 'competencyTestScore',
  DUE_DATE = 'dueDate',
  STATUS = 'status',
}

export namespace GetAssignmentsDTO {
  export interface Request extends PaginationRequest {
    sortBy?: AssignmentsSortBy
    status?: ASSIGNMENT_STATUS
    userId: number
    key?: string
  }
  export interface Response extends PaginatedBaseResponse {
    assignments: TAssignment[]
    userHasAnyCompletedAssignments: boolean
  }
}

export enum CoursesAssignmentsSortBy {
  USER = 'user',
  CODE = '"Assignments"."code"',
  PACKAGE = '"package"."code"',
  COURSE = `"course->translation"."content"->>'name'`,
  PROGRESS = '"Assignments"."progress"',
  STATUS = '"Assignments"."status"',
  COMPLETED = '"Assignments"."endDate"',
  DAYS_OVERDUE = '"Assignments"."daysOverdue"',
  TEST_ATTEMPTS = '"Assignments"."totalCompetencyTestTries"',
  TEST_SCORE = '"Assignments"."competencyTestScore"',
  COURSE_TYPE = '"course"."type"',
}

export namespace GetCoursesAssignmentsDTO {
  export interface Request extends PaginationRequest {
    sortBy?: CoursesAssignmentsSortBy
    facilityIds?: number[]
    groupIds?: number[]
    positionIds?: number[]
    departmentIds?: number[]
    courseIds?: number[]
    completedDate?: Date[]
    status?: ASSIGNMENT_STATUS[]
    type?: ASSIGNMENT_FILTER_TYPES
    courseType?: COURSE_TYPES[]
    key?: string
    startDate?: Date
    endDate?: Date
    permission: ReportingPermissions
  }
  export interface Response extends PaginatedBaseResponse {
    assignments: TAssignment[]
  }
}
