import Filters from '../../UserManagment/Filters'
import { Col, Row } from 'reactstrap'
import React, { Dispatch, SetStateAction } from 'react'
import { GetUserReportDTO } from '../../../sharedTypes/api/userReport'
import { FilterOption, USER_REGISTERED_STATUSES } from '../../../sharedTypes'
import _ from 'lodash'
import MultiSelect from '../../../Components/Common/MultiSelect'
import {
  COURSES_COMPLETED,
  COURSES_IN_PROGRESS,
  COURSES_NOT_STARTED,
  COURSES_OVERDUE,
  coursesCompleted,
  coursesInProgress,
  coursesNotStarted,
  coursesOverdue,
  SelectedFilters,
} from './types'

interface ReportFiltersProps {
  showFilters: boolean
  setQuery: Dispatch<SetStateAction<GetUserReportDTO.Request>>
  query: GetUserReportDTO.Request
  setSelectedFilters: (filters: SelectedFilters) => void
  setFetchData: Dispatch<SetStateAction<boolean>>
}

const ReportFilters = ({
  showFilters,
  setQuery,
  query,
  setSelectedFilters,
  setFetchData,
}: ReportFiltersProps) => {
  return (
    <>
      <Row className={`gap-4 ${showFilters ? 'my-3' : 'my-0'} mx-0`}>
        <Filters
          visible={showFilters}
          setFilters={data => {
            setQuery(prev => ({
              ...prev,
              groupIds: data.groups,
              facilityIds: data.facility,
              departmentIds: data.department,
              positionIds: data.position,
              status: data.status,
              hireDate: data.hireDate,
              signupDate: data.signupDate,
              page: 1,
            }))
            setFetchData(true)
          }}
          filterBy={{
            departments: true,
            positions: true,
            groups: false,
            status: true,
            registered: false,
            hireDate: true,
            signupDate: true,
          }}
          setSelectedFilters={setSelectedFilters}
        />
      </Row>
      {showFilters && (
        <Row className='mx-0 gap-4'>
          <Col className='mt-0 p-0'>
            <label htmlFor='coursesNotStarted' className='form-label'>
              Courses Not Started
            </label>

            <MultiSelect
              name='coursesNotStarted'
              id='coursesNotStarted'
              isMulti
              options={coursesNotStarted}
              isSearchable={true}
              placeholder='Select amount'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setQuery(prev => ({
                  ...prev,
                  coursesNotStarted: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSES_NOT_STARTED[]),
                }))
              }}
              defaultValue={coursesNotStarted.filter(course =>
                (query.coursesNotStarted || []).includes(course.value),
              )}
            />
          </Col>
          <Col className='mt-0 p-0'>
            <label htmlFor='coursesInProgress' className='form-label'>
              Courses in Progress
            </label>

            <MultiSelect
              name='coursesInProgress'
              id='coursesInProgress'
              isMulti
              options={coursesInProgress}
              isSearchable={true}
              placeholder='Select amount'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setQuery(prev => ({
                  ...prev,
                  coursesInProgress: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSES_IN_PROGRESS[]),
                }))
              }}
              defaultValue={coursesInProgress.filter(course =>
                (query.coursesInProgress || []).includes(course.value),
              )}
            />
          </Col>
          <Col className='mt-0 p-0'>
            <label htmlFor='coursesCompleted' className='form-label'>
              Courses Completed
            </label>

            <MultiSelect
              name='coursesCompleted'
              id='coursesCompleted'
              isMulti
              options={coursesCompleted}
              isSearchable={true}
              placeholder='Select amount'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setQuery(prev => ({
                  ...prev,
                  coursesCompleted: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSES_COMPLETED[]),
                }))
              }}
              defaultValue={coursesCompleted.filter(course =>
                (query.coursesCompleted || []).includes(course.value),
              )}
            />
          </Col>

          <Col className='mt-0 p-0'>
            <label htmlFor='coursesOverdue' className='form-label'>
              Courses Overdue
            </label>

            <MultiSelect
              name='coursesOverdue'
              id='coursesOverdue'
              isMulti
              options={coursesOverdue}
              isSearchable={true}
              placeholder='Select amount'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setQuery(prev => ({
                  ...prev,
                  coursesOverdue: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSES_OVERDUE[]),
                }))
              }}
              defaultValue={coursesOverdue.filter(course =>
                (query.coursesOverdue || []).includes(course.value),
              )}
            />
          </Col>

          <Col className='mt-0 p-0'>
            <label htmlFor='group' className='form-label'>
              Filter by Registered Status
            </label>

            <MultiSelect
              name='status'
              id='userRegisteredStatus'
              isMulti
              options={Object.values(USER_REGISTERED_STATUSES).map(status => ({
                value: status,
                label: _.capitalize(status),
              }))}
              isSearchable={false}
              placeholder='Select status'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                setQuery(prev => ({
                  ...prev,
                  registeredStatuses: selectedOptions.map(
                    option => option.value,
                  ) as USER_REGISTERED_STATUSES[],
                }))
              }}
              defaultValue={(query.registeredStatuses || []).map(status => ({
                value: status,
                label: _.capitalize(status),
              }))}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default ReportFilters
