//ADMINS
import { GetAdminsDTO, TUser, PatchAdminsDTO } from '../../sharedTypes'
import { GET_ADMINS, UPDATE_ME } from '../url_helper'
import _ from 'lodash'
import { API } from '../api_helper'

export const getAdmins = (params: GetAdminsDTO.Request) => {
  return API.get<GetAdminsDTO.Response>(GET_ADMINS, { params })
}

export const deactivateAdminById = (userId: number) => {
  return API.delete<TUser>(`${GET_ADMINS}/${userId}`)
}

export const getAdminByEmail = (email: string) => {
  return API.get<TUser | null>(GET_ADMINS + `/${email}`)
}

export const patchAdmin = (data: PatchAdminsDTO.Request) => {
  const body = new FormData()

  _.keys(data).map(k => {
    const value = (data as any)[k]
    if (k === 'departmentIds') {
      if (Array.isArray(value)) {
        value.forEach(v => {
          body.append(`${k}[]`, v.toString())
        })
      }
    } else {
      body.append(k, value)
    }
  })
  return API.patch<PatchAdminsDTO.Response>(GET_ADMINS, body).then(d => d.data)
}

export const updateMe = (data: PatchAdminsDTO.Request) => {
  const body = new FormData()
  _.keys(data).map(k => {
    body.append(k, (data as any)[k])
  })
  return API.put<PatchAdminsDTO.Response>(UPDATE_ME, body).then(d => d.data)
}

export const resendActivation = (userId: number) => {
  return API.post<{ message: string }>(
    `${GET_ADMINS}/${userId}/resend-create-password`,
  ).then(response => response.data)
}
