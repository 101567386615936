import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap'
import _ from 'lodash'
import { arrayMoveImmutable } from 'array-move'
import { toast } from 'react-toastify'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import {
  assignRoleToUsers,
  getRoles,
  patchRole,
  postRole,
  postRoleOrder,
} from '../../../helpers/api/roles'
import {
  GetRolesPermissions,
  SettingsPermissions,
  TRole,
} from '../../../sharedTypes'
import CreateNewRoleModal from './CreateNewRoleModal'
import DeleteRoleModal from './DeleteRoleModal'
import { deleteRole as deleteRoleAPI } from '../../../helpers/api_helper'
import {
  errorToastOptions,
  handleError,
  successToastOptions,
} from '../../../helpers/toast_helper'

import { SortableColumnContainer, SortableColumnItem } from './drag'
import { usePermissions } from '../../../hooks/usePermissions'
import AssignRoleToUsersModal from '../../../Components/Modals/AssignRoleToUsersModal'

export interface ISubPermission {
  label: string
  value:
    | keyof TRole['permissionSet']['userManagement']
    | keyof TRole['permissionSet']['courses']
    | keyof TRole['permissionSet']['facilityManagement']
    | keyof TRole['permissionSet']['reporting']
    | keyof TRole['permissionSet']['support']
    | keyof TRole['permissionSet']['calendar']
    | keyof TRole['permissionSet']['settings']
    | keyof TRole['permissionSet']['communication']
  automaticCheck?: string
  dependent?: string | string[]
}

export interface IPermissionSet {
  label: string
  valueKey: keyof TRole['permissionSet']
  subHeaders: {
    label: string | null
    permissions: ISubPermission[]
  }[]
}

export interface IPermission {
  value: string | undefined
  label: string
}

const permissionSet: IPermissionSet[] = [
  {
    label: 'User Management',
    valueKey: 'userManagement',
    subHeaders: [
      {
        label: 'User Listing',
        permissions: [{ label: 'View User Listing', value: 'viewUserListing' }],
      },
      {
        label: 'User Profile',
        permissions: [
          {
            label: 'View User Profile',
            value: 'viewUserProfile',
            dependent: 'viewUserListing',
          },
          {
            label: 'View User Info',
            value: 'viewUserInfo',
            dependent: ['viewUserListing', 'viewUserProfile'],
          },
          {
            label: 'View User Course History',
            value: 'viewUserCourseHistory',
            dependent: ['viewUserListing', 'viewUserProfile'],
          },
          // {
          //   label: 'View User Documents',
          //   value: 'viewUserDocuments',
          //   dependent: 'viewUserListing',
          // },
          {
            label: 'Assign Course to User',
            value: 'assignCourseToUser',
            dependent: ['viewUserListing', 'viewUserProfile'],
          },
          {
            label: 'Request Edit of User Info',
            value: 'requestEditOfUserInfo',
            dependent: ['viewUserListing', 'viewUserProfile'],
          },
          {
            label: 'View Certificate',
            value: 'viewCertificate',
            dependent: [
              'viewUserListing',
              'viewUserProfile',
              'viewUserCourseHistory',
            ],
          },
          {
            label: 'Print Transcript',
            value: 'printTranscript',
            dependent: ['viewUserListing', 'viewUserProfile'],
          },
          {
            label: 'Grand Admin Portal Access',
            value: 'grandAdminPortalAccess',
            dependent: ['viewUserListing', 'viewUserProfile'],
          },
          {
            label: 'Deactivate a User',
            value: 'deactivateUser',
            dependent: ['viewUserListing', 'viewUserProfile'],
          },
          {
            label: 'Edit Assignment',
            value: 'editAssignment',
            dependent: [
              'viewUserListing',
              'viewUserProfile',
              'viewUserCourseHistory',
            ],
          },
          {
            label: 'Remove Assignment',
            value: 'removeAssignment',
            dependent: [
              'viewUserListing',
              'viewUserProfile',
              'viewUserCourseHistory',
            ],
          },
        ],
      },
      {
        label: 'Admin Listing',
        permissions: [
          {
            label: 'View Admin Listing',
            value: 'viewAdminListing',
          },
          {
            label: 'Add New Admin',
            value: 'addNewAdmin',
            dependent: 'viewAdminListing',
          },
          {
            label: 'Edit Admin',
            value: 'editAdmin',
            dependent: 'viewAdminListing',
          },
          {
            label: 'Deactivate Admin',
            value: 'deactivateAdmin',
            dependent: 'viewAdminListing',
          },
        ],
      },
      {
        label: 'Admin Profile',
        permissions: [
          {
            label: 'Override own course result',
            value: 'overrideOwnCourseResult',
            dependent: 'viewAdminListing',
          },
          {
            label: 'Edit own course assignment',
            value: 'editOwnCourseAssignment',
            dependent: 'viewAdminListing',
          },
          {
            label: 'Remove own assigned course',
            value: 'removeOwnAssignedCourse',
            dependent: 'viewAdminListing',
          },
        ],
      },
      // {
      //   label: 'Course Assignment by Position',
      //   permissions: [
      //     {
      //       label: 'View Course Assignment by Position',
      //       value: 'viewCourseAssignmentByPosition',
      //     },
      //   ],
      // },
      {
        label: 'Import Log',
        permissions: [{ label: 'View Import Log', value: 'viewImportLog' }],
      },
    ],
  },
  {
    label: 'Courses',
    valueKey: 'courses',
    subHeaders: [
      {
        label: 'Course Creation/Editing',
        permissions: [
          { label: 'View Course Listing', value: 'viewCourseListing' },
          {
            label: 'Add/Edit a Course',
            value: 'addEditCourse',
            dependent: 'viewCourseListing',
          },
          {
            label: 'Clone a Course',
            value: 'cloneCourse',
            dependent: 'viewCourseListing',
          },
          // {
          //   label: 'Add a Course Revision',
          //   value: 'addCourseRevision',
          //   dependent: 'viewCourseListing',
          // },
          {
            label: 'Assign a Course',
            value: 'assignCourse',
            dependent: 'viewCourseListing',
          },
          {
            label: 'Add a Course Schedule',
            value: 'addCourseSchedule',
            dependent: 'viewCourseListing',
          },
          {
            label: 'Edit a Course Schedule',
            value: 'editCourseSchedule',
            dependent: 'viewCourseListing',
          },
          {
            label: 'Delete a Course Schedule',
            value: 'deleteCourseSchedule',
            dependent: 'viewCourseListing',
          },
          {
            label: 'Delete a Course',
            value: 'deleteCourse',
            dependent: 'viewCourseListing',
          },
          {
            label: 'Publish a Course',
            value: 'publishCourse',
            dependent: 'viewCourseListing',
          },
        ],
      },
      {
        label: 'Course Packages',
        permissions: [
          {
            label: 'Add/Clone a Package',
            value: 'addClonePackage',
          },
          {
            label: 'Edit a Package',
            value: 'editPackage',
          },
          {
            label: 'Add Courses to a Package',
            value: 'addCoursesToPackage',
          },
          {
            label: 'Remove Courses from a Package',
            value: 'removeCoursesFromPackage',
          },
          {
            label: 'Add a Schedule',
            value: 'addScheduleForPackage',
          },
          {
            label: 'Edit a Schedule',
            value: 'editScheduleForPackage',
          },
          {
            label: 'Delete a Schedule',
            value: 'deleteScheduleForPackage',
          },
          {
            label: 'Publish a Package',
            value: 'publishPackage',
          },
          {
            label: 'Delete a Package',
            value: 'deletePackage',
          },
        ],
      },
      {
        label: 'Media Library',
        permissions: [
          { label: 'View Media Library', value: 'viewMediaLibrary' },
          {
            label: 'View Media',
            value: 'viewMedia',
            dependent: 'viewMediaLibrary',
          },
          {
            label: 'Add Media',
            value: 'addMedia',
            dependent: 'viewMediaLibrary',
          },
          {
            label: 'Edit Media',
            value: 'editMedia',
            dependent: 'viewMediaLibrary',
          },
          {
            label: 'Delete Media',
            value: 'deleteMedia',
            dependent: 'viewMediaLibrary',
          },
        ],
      },
      {
        label: ' ',
        permissions: [
          { label: 'View Translations', value: 'viewTranslations' },
          {
            label: 'Edit Translation - Spanish',
            value: 'editSpanishTranslation',
            dependent: 'viewTranslations',
          },
          {
            label: 'Edit Translation - Creole',
            value: 'editCreoleTranslation',
            dependent: 'viewTranslations',
          },
        ],
      },
    ],
  },
  {
    label: 'Facility Management',
    valueKey: 'facilityManagement',
    subHeaders: [
      {
        label: null,
        permissions: [
          {
            label: 'View Facility Management',
            value: 'viewFacilityManagement',
          },
          {
            label: 'View Facility Details',
            value: 'viewFacilityDetails',
            dependent: 'viewFacilityManagement',
          },
          {
            label: 'View Facility Employees',
            value: 'viewFacilityEmployees',
            dependent: ['viewFacilityManagement', 'viewFacilityDetails'],
          },
          {
            label: 'Add New Facility',
            value: 'addNewFacility',
            dependent: 'viewFacilityManagement',
          },
          {
            label: 'Edit Facility',
            value: 'editFacility',
            dependent: 'viewFacilityManagement',
          },
          {
            label: 'Activate Facility',
            value: 'activateFacility',
            dependent: 'viewFacilityManagement',
          },
          {
            label: 'Deactivate Facility',
            value: 'deactivateFacility',
            dependent: 'viewFacilityManagement',
          },
          {
            label: 'Add New Group',
            value: 'addNewGroup',
            dependent: 'viewFacilityManagement',
          },
          {
            label: 'Edit a Group',
            value: 'editGroup',
            dependent: 'viewFacilityManagement',
          },
          {
            label: 'Delete a Group',
            value: 'deleteGroup',
            dependent: 'viewFacilityManagement',
          },
        ],
      },
    ],
  },
  {
    label: 'Reporting',
    valueKey: 'reporting',
    subHeaders: [
      {
        label: null,
        permissions: [
          {
            label: 'View Reports',
            value: 'viewReports',
          },
          {
            label: 'View Course History Report',
            value: 'viewCourseHistoryReport',
            dependent: 'viewReports',
          },
          {
            label: 'View User Report',
            value: 'viewUserReport',
            dependent: 'viewReports',
          },
          {
            label: 'View Course Report',
            value: 'viewCourseReport',
            dependent: 'viewReports',
          },
          {
            label: 'View Course Feedback Report',
            value: 'viewCourseFeedbackReport',
            dependent: 'viewReports',
          },
          {
            label: 'View Course History Summary Report',
            value: 'viewCourseHistorySummaryReport',
            dependent: 'viewReports',
          },
        ],
      },
    ],
  },
  {
    label: 'Support',
    valueKey: 'support',
    subHeaders: [
      {
        label: 'Tutorials',
        permissions: [
          { label: 'View Tutorials Listing', value: 'viewTutorialsListing' },
          {
            label: 'Add a Tutorial',
            value: 'addTutorial',
            dependent: 'viewTutorialsListing',
          },
          {
            label: 'Edit a Tutorial',
            value: 'editTutorial',
            dependent: 'viewTutorialsListing',
          },
          {
            label: 'Delete a Tutorial',
            value: 'deleteTutorial',
            dependent: 'viewTutorialsListing',
          },
        ],
      },
      {
        label: 'FAQ',
        permissions: [{ label: 'View FAQ Listing', value: 'viewFAQListing' }],
      },
      {
        label: 'Support Categories',
        permissions: [
          { label: 'View Support Categories', value: 'viewSupportCategories' },
          {
            label: 'Add Support Category',
            value: 'addSupportCategories',
            dependent: 'viewSupportCategories',
          },
          {
            label: 'Edit Support Categories',
            value: 'editSupportCategories',
            dependent: 'viewSupportCategories',
          },
          {
            label: 'Delete Support Categories',
            value: 'deleteSupportCategories',
            dependent: 'viewSupportCategories',
          },
        ],
      },
    ],
  },
  {
    label: 'Calendar',
    valueKey: 'calendar',
    subHeaders: [
      {
        label: null,
        permissions: [{ label: 'View Calendar', value: 'viewCalendar' }],
      },
      {
        label: 'Facilitator Course View',
        permissions: [
          {
            label: 'View Facilitator Course View',
            value: 'viewFacilitatorCourseView',
          },
          {
            label: 'Add Participants',
            value: 'addParticipants',
            dependent: 'viewFacilitatorCourseView',
          },
          {
            label: 'Remove Participants',
            value: 'removeParticipants',
            dependent: 'viewFacilitatorCourseView',
          },
          {
            label: 'Check Participant In',
            value: 'checkParticipantIn',
            dependent: 'viewFacilitatorCourseView',
          },
          {
            label: 'Preview Test',
            value: 'previewTest',
            dependent: 'viewFacilitatorCourseView',
          },
          {
            label: 'Release Test',
            value: 'releaseTest',
            dependent: 'viewFacilitatorCourseView',
          },
          {
            label: 'Override Test Score',
            value: 'overrideTestScore',
            dependent: 'viewFacilitatorCourseView',
          },
        ],
      },
    ],
  },
  {
    label: 'Communication',
    valueKey: 'communication',
    subHeaders: [
      {
        label: 'Surveys',
        permissions: [
          { label: 'View Survey Listing', value: 'viewSurveyListing' },
          {
            label: 'View Surveys',
            value: 'viewSurveys',
            dependent: 'viewSurveyListing',
          },
          {
            label: 'Create/Clone Surveys',
            value: 'createCloneSurvey',
            dependent: 'viewSurveyListing',
          },
          {
            label: 'Edit Surveys',
            value: 'editSurvey',
            dependent: 'viewSurveyListing',
          },
          {
            label: 'Send/Schedule Surveys',
            value: 'sendScheduleSurvey',
            dependent: 'viewSurveyListing',
          },
          {
            label: 'View Individual Survey Responses',
            value: 'viewIndividualSurveyResponses',
            dependent: ['viewSurveyListing', 'viewSurveys'],
          },
          {
            label: 'View Survey Response Overview',
            value: 'viewSurveyResponseOverview',
            dependent: ['viewSurveyListing', 'viewSurveys'],
          },
          {
            label: 'Delete Surveys',
            value: 'deleteSurvey',
            dependent: 'viewSurveyListing',
          },
        ],
      },
      {
        label: 'Notifications',
        permissions: [
          {
            label: 'View Notification Listing',
            value: 'viewNotificationListing',
          },
          {
            label: 'View Notifications',
            value: 'viewNotifications',
            dependent: 'viewNotificationListing',
          },
          {
            label: 'Create/Clone Notifications',
            value: 'createCloneNotifications',
            dependent: 'viewNotificationListing',
          },
          {
            label: 'Edit Notifications',
            value: 'editNotifications',
            dependent: 'viewNotificationListing',
          },
          {
            label: 'Send/Schedule Notifications',
            value: 'sendScheduleNotifications',
            dependent: 'viewNotificationListing',
          },
          {
            label: 'Delete Notifications',
            value: 'deleteNotifications',
            dependent: 'viewNotificationListing',
          },
        ],
      },
      {
        label: 'Company News',
        permissions: [
          {
            label: 'View Company News Listing',
            value: 'viewCompanyNewsListing',
          },
          {
            label: 'View Company News',
            value: 'viewCompanyNews',
            dependent: 'viewCompanyNewsListing',
          },
          {
            label: 'Add Company News',
            value: 'addCompanyNews',
            dependent: 'viewCompanyNewsListing',
          },
          {
            label: 'Edit Company News',
            value: 'editCompanyNews',
            dependent: 'viewCompanyNewsListing',
          },
          {
            label: 'Publish/Schedule Company News',
            value: 'publishCompanyNews',
            dependent: 'viewCompanyNewsListing',
          },
          {
            label: 'Delete Company News',
            value: 'deleteCompanyNews',
            dependent: 'viewCompanyNewsListing',
          },
        ],
      },
    ],
  },
  {
    label: 'Settings',
    valueKey: 'settings',
    subHeaders: [
      {
        label: 'Default Settings',
        permissions: [
          {
            label: 'View Default Settings',
            value: 'viewDefaultCourseSettings',
          },
          {
            label: 'Edit Default Settings',
            value: 'editDefaultCourseSettings',
            dependent: 'viewDefaultCourseSettings',
          },
        ],
      },
      {
        label: 'Instructor Management',
        permissions: [
          { label: 'View Instructor Listing', value: 'viewInstructorListing' },
          {
            label: 'Add Instructor',
            value: 'addInstructor',
            dependent: 'viewInstructorListing',
          },
          {
            label: 'Edit Instructor',
            value: 'editInstructor',
            dependent: 'viewInstructorListing',
          },
          {
            label: 'Delete Instructor',
            value: 'deleteInstructor',
            dependent: 'viewInstructorListing',
          },
        ],
      },
      {
        label: 'Roles & Permissions',
        permissions: [
          {
            label: 'View Roles & Permissions',
            value: 'viewRolesAndPermissions',
          },
          {
            label: 'Add/Clone Role',
            value: 'addCloneRole',
            dependent: 'viewRolesAndPermissions',
          },
          {
            label: 'Edit Role Name',
            value: 'editRoleName',
            dependent: 'viewRolesAndPermissions',
          },
          {
            label: 'Edit Role Permissions',
            value: 'editRolePermissions',
            dependent: 'viewRolesAndPermissions',
          },
          {
            label: 'Delete Role',
            value: 'deleteRole',
            dependent: 'viewRolesAndPermissions',
          },
          {
            label: 'Assign Role To Users',
            value: 'assignRoleToUsers',
            dependent: 'viewRolesAndPermissions',
          },
        ],
      },
    ],
  },
]

const RolePermissions = () => {
  document.title = 'Roles & Permissions | Mastered - Admin & Dashboard'

  const [roles, setRoles] = useState<TRole[]>([])
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false)
  const [needDeleteRole, setDeleteRole] = useState<TRole | null>(null)
  const [editRole, setEditRole] = useState<TRole | null>(null)
  const [assignRole, setAssignRole] = useState<TRole | null>(null)

  const permissions = {
    add: usePermissions(SettingsPermissions.ADD_CLONE_ROLE),
    editName: usePermissions(SettingsPermissions.EDIT_ROLE_NAME),
    editPermissions: usePermissions(SettingsPermissions.EDIT_ROLE_PERMISSIONS),
    assign: usePermissions(SettingsPermissions.ASSIGN_ROLE_TO_USERS),
    delete: usePermissions(SettingsPermissions.DELETE_ROLE),
  }

  const tableWrapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    getRoles({ permission: GetRolesPermissions.VIEW_ROLES_AND_PERMISSIONS })
      .then(res => {
        setRoles(res.data)
      })
      .catch(() => {})
  }, [])

  const onAddedNewRole = (newRole: TRole) => {
    setRoles([...roles, newRole])
    setEditRole(newRole)

    setTimeout(() => {
      if (tableWrapRef.current) {
        tableWrapRef.current.scrollLeft = tableWrapRef.current.scrollWidth
      }
    }, 100)
  }

  const getPermissionValue = (
    role: TRole,
    set: IPermissionSet,
    permission: any,
  ) => {
    return _.get(role.permissionSet[set.valueKey], permission.value, false)
  }

  const isDependent = (role: TRole, set: IPermissionSet, permission: any) => {
    const automaticCheck = permission.automaticCheck
    const dependent = permission.dependent

    if (!automaticCheck && !dependent) {
      return false
    }

    if (automaticCheck && dependent) {
      const depValue = _.get(role.permissionSet[set.valueKey], dependent)
      return depValue
        ? !!_.get(role.permissionSet[set.valueKey], automaticCheck)
        : true
    }

    const checkValue =
      _.get(role.permissionSet[set.valueKey], automaticCheck) || false
    const dependentValue = Array.isArray(dependent)
      ? dependent.every(
          dep => _.get(role.permissionSet[set.valueKey], dep) || false,
        )
      : _.get(role.permissionSet[set.valueKey], dependent) || false

    return automaticCheck ? !!checkValue : !dependentValue
  }

  const onDeleteRole = (role: TRole) => {
    setDeleteRole(role)
  }

  const onChange = (keyPath: string, value: string | boolean) => {
    if (!editRole) {
      return
    }
    const edited: TRole = { ...editRole }
    _.set(edited, keyPath, value)
    setEditRole(edited)
  }

  const onCancelEdit = () => {
    setRoles(roles => roles.filter(role => role.id > 0))
    setEditRole(null)
  }

  const onSaveChanges = () => {
    if (!editRole) {
      return
    }
    if (!editRole.name) {
      return toast('The role name is mandatory', errorToastOptions)
    }

    const isNew = editRole.id > 0
    const updatedData: Partial<TRole> = {}

    if (isNew) {
      if (permissions.editPermissions) {
        updatedData.permissionSet = editRole.permissionSet
      }
      if (permissions.editName) {
        updatedData.name = editRole.name
      }
    }

    const action = isNew
      ? patchRole(editRole.id, updatedData)
      : postRole({
          name: editRole.name,
          permissionSet: editRole.permissionSet,
        })

    action
      .then(res => {
        const newRolesSet = roles.map(role => {
          if (role.id !== editRole.id) {
            return role
          }
          if (isNew) {
            return editRole
          }
          return res.data
        })
        toast('Successfully saved', successToastOptions)
        setRoles(newRolesSet)
        if (isNew) {
          updateOrder(newRolesSet)
        }
        setEditRole(null)
      })
      .catch(handleError)
  }

  const deleteRole = () => {
    if (!needDeleteRole) {
      return
    }
    deleteRoleAPI(needDeleteRole.id)
      .then(() => {
        const _roles = roles.filter(r => r.id !== needDeleteRole.id)
        setRoles(_roles)
        updateOrder(_roles)
        toast('Successfully deleted', successToastOptions)
      })
      .catch(handleError)
      .finally(() => {
        setDeleteRole(null)
      })
  }

  const onClone = (role: TRole) => {
    const copyRole = { ..._.cloneDeep(role), name: `${role.name}_Copy`, id: -1 }
    const index = roles.findIndex(r => r.id === role.id)
    if (index >= 0) {
      const _n = [...roles]
      _n.splice(index + 1, 0, copyRole)
      setRoles(_n)
      setEditRole(copyRole)
    }
  }

  const updateOrder = (_roles: TRole[]) => {
    const newOrder = _roles.map(role => role.id)
    postRoleOrder({ order: newOrder }).catch(() => {})
  }

  const onToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    set: IPermissionSet,
    permission: ISubPermission,
    header: { label: string | null; permissions: IPermission[] },
  ) => {
    onChange(
      `permissionSet.${set.valueKey}.${permission.value}`,
      event.target.checked,
    )
    const perSet = permissionSet.find(pSet => pSet.valueKey === set.valueKey)
    if (perSet) {
      const automaticChecks = header.permissions.filter(
        pSet => _.get(pSet, 'automaticCheck') === permission.value,
      )
      automaticChecks.forEach(item => {
        onChange(
          `permissionSet.${perSet.valueKey}.${item.value}`,
          event.target.checked,
        )
      })
      if (!event.target.checked) {
        set.subHeaders.forEach(subHeader => {
          const dependent = subHeader.permissions.filter(pSet => {
            const depValue = _.get(pSet, 'dependent')
            return Array.isArray(depValue)
              ? depValue.includes(permission.value)
              : depValue === permission.value
          })
          dependent.forEach(item => {
            onChange(
              `permissionSet.${perSet.valueKey}.${item.value}`,
              event.target.checked,
            )
          })
        })
      }
    }
  }

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }) => {
    const updatedOrder = arrayMoveImmutable(roles, oldIndex, newIndex)
    updateOrder(updatedOrder)
    setRoles(updatedOrder)
  }

  const handleAssign = async (userIds: number[]) => {
    try {
      if (assignRole) {
        await assignRoleToUsers(assignRole.id, { users: userIds })
        toast('Role assigned successfully', successToastOptions)
        setAssignRole(null)
      }
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='User Roles & Permissions'
            items={[
              {
                linkTo: '/',
                title: 'Settings',
              },
              {
                active: true,
                title: 'User Roles & Permissions',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='d-flex align-items-center'>
                    <h5 className='card-title flex-grow-1 mb-0'>Permissions</h5>
                    {permissions.add && (
                      <div className='flex-shrink-0'>
                        <Button
                          color={'primary'}
                          className='btn btn-primary align-middle'
                          onClick={() => setShowCreateRoleModal(true)}
                        >
                          <i className='ri-add-line me-1 fs-16'></i>Add Role
                        </Button>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <div
                    className='table-responsive table-card'
                    ref={tableWrapRef}
                  >
                    <table className='table align-middle table-bordered table-responsive mb-0 w-100'>
                      <SortableColumnContainer
                        onSortEnd={onSortEnd}
                        useDragHandle
                        axis='x'
                        lockAxis='x'
                      >
                        {roles.map((role, index) => {
                          const isEdit = editRole?.id === role.id
                          return (
                            <SortableColumnItem
                              index={index}
                              key={role.id}
                              isEdit={isEdit}
                              role={role}
                              editRole={editRole}
                              onChange={onChange}
                              onSaveChanges={onSaveChanges}
                              onCancelEdit={onCancelEdit}
                              setEditRole={setEditRole}
                              setAssignRole={setAssignRole}
                              onClone={onClone}
                              onDeleteRole={onDeleteRole}
                              permissions={permissions}
                            />
                          )
                        })}
                      </SortableColumnContainer>
                      <tbody>
                        {permissionSet.map(set => (
                          <Fragment key={set.valueKey}>
                            <tr>
                              <th
                                colSpan={roles.length + 1}
                                className='w-100 fs-16 bg-light text-decoration-underline'
                              >
                                {set.label}
                              </th>
                            </tr>
                            {set.subHeaders.map(header => (
                              <Fragment key={header.label}>
                                {header.label && (
                                  <tr>
                                    <th
                                      colSpan={roles.length + 1}
                                      className='w-100 fs-14 bg-light'
                                    >
                                      {header.label}
                                    </th>
                                  </tr>
                                )}

                                {header.permissions.map(permission => (
                                  <tr
                                    key={permission.value}
                                    className='row-height-64 align-middle'
                                  >
                                    <td>
                                      <div className='d-flex'>
                                        <div className='flex-shrink-0 fs-14'>
                                          {permission.label}
                                        </div>
                                      </div>
                                    </td>
                                    {roles.map(role => (
                                      <td key={role.id}>
                                        <div className='d-flex'>
                                          <div className='flex-shrink-0 fs-14'>
                                            <div className='form-check form-switch'>
                                              <Input
                                                className='form-check-input'
                                                type='checkbox'
                                                role='switch'
                                                checked={getPermissionValue(
                                                  role,
                                                  set,
                                                  permission,
                                                )}
                                                onChange={e =>
                                                  onToggle(
                                                    e,
                                                    set,
                                                    permission,
                                                    header,
                                                  )
                                                }
                                                disabled={
                                                  editRole?.id !== role.id ||
                                                  isDependent(
                                                    role,
                                                    set,
                                                    permission,
                                                  ) ||
                                                  !permissions.editPermissions
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </Fragment>
                            ))}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <CreateNewRoleModal
          isOpen={showCreateRoleModal}
          toggle={() => {
            setShowCreateRoleModal(v => !v)
          }}
          onClose={() => setShowCreateRoleModal(false)}
          roles={roles}
          onAddedNewRole={onAddedNewRole}
        />
        <DeleteRoleModal
          isOpen={!!needDeleteRole}
          onClose={() => setDeleteRole(null)}
          role={needDeleteRole}
          onDelete={deleteRole}
        />
        {assignRole && (
          <AssignRoleToUsersModal
            onClose={() => {
              setAssignRole(null)
            }}
            isOpen={!!assignRole}
            handleAdd={userIds => {
              handleAssign(userIds)
            }}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default RolePermissions
