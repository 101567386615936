import { PermissionSet, TUser } from '../models'

export namespace ForgotPasswordDTO {
  export interface Request {
    email: string
  }
}

export namespace LoginUserDTO {
  export interface Request {
    password: string
    login: string
  }
  export interface Response {
    user: TUser
    access_token: string
    permissionSet: PermissionSet | null
  }
}

export namespace ResetPasswordDTO {
  export interface Request {
    token: string
    newPassword: string
  }
  export interface Response {
    email: string
  }
}
