import React from 'react'
import { CREDENTIALS_TYPE, TLog } from '../../../sharedTypes'
import Highlighter from 'react-highlight-words'

interface Props {
  log: TLog
  globalSearch?: string
  displayDetails?: boolean
}

const UserInfo = ({ log, globalSearch, displayDetails = false }: Props) => {
  const email = log.user?.credentials?.find(
    i => i.credentialType === CREDENTIALS_TYPE.EMAIL,
  )?.credential

  const displayPhotoName = log.user
    ? log.user?.firstName[0] + ' ' + log.user?.lastName[0]
    : log.rawUser.firstName[0] + ' ' + log.rawUser?.lastName[0]
  const displayName = log.user
    ? log.user?.firstName + ' ' + log.user?.lastName
    : log.rawUser.firstName + ' ' + log.rawUser?.lastName

  const displayEmail = email || log.rawUser?.email

  return (
    <div className='d-flex align-items-center'>
      <div className='flex-shrink-0'>
        {log.user?.photo ? (
          <img
            src={log.user?.photo}
            alt=''
            className='avatar-xs rounded-circle'
          />
        ) : (
          <div className='avatar-xs'>
            <div className='avatar-title rounded-circle bg-soft-primary text-primary'>
              {displayPhotoName}
            </div>
          </div>
        )}
      </div>
      <div className='flex-grow-1 ms-2'>
        <p className='card-title mb-0 fs-14'>
          <Highlighter
            highlightClassName='text-highlight'
            searchWords={[globalSearch || '']}
            highlightTag={'span'}
            autoEscape={true}
            textToHighlight={displayName || ''}
          />
        </p>
        {displayDetails ? (
          <p className='text-muted mb-0 fs-12'>
            <span>
              {log.user?.facility?.name || log.rawUser?.facility || ''}
            </span>
            <span> | </span>
            <span>
              {log.user?.position?.name || log.rawUser?.position || ''}
            </span>
          </p>
        ) : (
          <p className='text-muted mb-0 fs-12'>
            <Highlighter
              highlightClassName='text-highlight'
              searchWords={[globalSearch || '']}
              highlightTag={'span'}
              autoEscape={true}
              textToHighlight={displayEmail || ''}
            />
          </p>
        )}
      </div>
    </div>
  )
}

export default UserInfo
