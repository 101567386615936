import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  CoursesPermissions,
  CoursesSortBy,
  CoursesTabs,
  GetCoursesDTO,
  GetOnlineCoursesDTO,
  ONLINE_COURSE_STATUS,
  OnlineCourseItem,
  OrderType,
} from '../../../sharedTypes'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { getOnlineCourses } from '../../../helpers/api_helper'
import { Pagination } from '../../../Components/Common/Pagination'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import Filters from './Filters'
import { usePermissions } from '../../../hooks/usePermissions'
import { ViewType } from '../MediaLibrary'
import CourseTabs from '../../../Components/Course/CoursesTabs'
import CoursesGridView from './CoursesGridView'
import {
  GetCoursePackagesDTO,
  PackagesSortBy,
} from '../../../sharedTypes/api/coursePackages'
import {
  CoursePackageFormatsEnum,
  CoursePackageStatus,
  TCoursePackage,
} from '../../../sharedTypes/models/coursePackage'
import {
  duplicatePackage,
  getCoursePackages,
} from '../../../helpers/api/coursePackages'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import CoursesTableView from './CoursesTableView'
import _ from 'lodash'
import { ItemsToAssign } from './types'
import PackageTableView from './PackageTableView'
import PackagesGridView from './PackagesGridView'
import { toast } from 'react-toastify'
import AssignCourseModal from '../../../Components/Modals/AssignCourseModal'
import { useCloneCourse, useDeleteCourse } from '../../../hooks/course'

const OnlineCourses = ({ router }: WithRouterProps) => {
  document.title = 'Online Courses | Mastered - Admin & Dashboard'
  const [isLoading, setIsLoading] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [viewType, setViewType] = useState<ViewType>(ViewType.LIST)
  const hasPermissionToAssign = usePermissions(CoursesPermissions.ASSIGN_COURSE)
  const [showAssign, setShowAssign] = useState(false)
  const [coursesData, setCoursesData] = useState<GetCoursesDTO.Response>({
    page: 0,
    pages: 0,
    count: 0,
    data: [],
  })

  const [query, setQuery] = useState<GetCoursesDTO.Request>({
    page: 1,
    limit: 10,
    activeTab: CoursesTabs.COURSES,
    sortBy: CoursesSortBy.UPDATED_AT,
    orderBy: OrderType.DESC,
    publishedDate: [],
  })

  const { renderCloneModal, setClonedCourseId } = useCloneCourse()
  const { renderDeleteModal, setDeleteCourseId } = useDeleteCourse(() => {
    setQuery(prev => ({ ...prev, page: getPage() }))
  })
  const [selectedEntities, setSelectedEntities] = useState<ItemsToAssign>({
    courses: [],
    packages: [],
    trainingDays: [],
  })

  const coursePermissions = {
    addEdit: usePermissions(CoursesPermissions.ADD_EDIT_COURSE),
    clone: usePermissions(CoursesPermissions.CLONE_COURSE),
    delete: usePermissions(CoursesPermissions.DELETE_COURSE),
    viewTranslations: usePermissions(CoursesPermissions.VIEW_TRANSLATIONS),
  }

  const packagePermissions = {
    clone: usePermissions(CoursesPermissions.ADD_CLONE_PACKAGE),
  }

  useEffect(() => {
    setIsLoading(true)
    fetchItemsToEnroll()
  }, [query])

  const fetchItemsToEnroll = () => {
    const activeTab = query.activeTab
    let requestPromise

    if (activeTab === CoursesTabs.COURSES) {
      const { activeTab, format, publishedDate, ...courseQuery } = query

      requestPromise = getOnlineCourses(
        courseQuery as GetOnlineCoursesDTO.Request,
      ).then(res => ({
        data: res.courses,
        page: res.page,
        count: res.count,
        pages: res.pages,
      }))
    } else {
      const packageQuery: GetCoursePackagesDTO.Request = {
        page: query.page,
        limit: query.limit,
        key: query.key ?? undefined,
        format:
          activeTab === CoursesTabs.PACKAGES
            ? CoursePackageFormatsEnum.PACKAGE
            : CoursePackageFormatsEnum.TRAINING_DAY,
        sortBy: query.sortBy as PackagesSortBy,
        orderBy: query.orderBy,
        publishedDate: query.publishedDate,
        status: query.statuses as CoursePackageStatus[],
      }
      requestPromise = getCoursePackages(packageQuery).then(res => ({
        data: res.content,
        page: res.page,
        count: res.count,
        pages: res.pages,
      }))
    }

    if (requestPromise) {
      requestPromise
        .then(res => {
          setCoursesData(res)
        })
        .catch(e => {
          handleError(e)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const isFiltering = useMemo<boolean>(
    () =>
      !_.isEmpty(query.statuses) ||
      !_.isEmpty(query.types) ||
      !_.isEmpty(query.states) ||
      !_.isEmpty(query.publishedDate) ||
      !!query.key,
    [query],
  )

  const handleSort = useCallback((column: CoursesSortBy | PackagesSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const toggleViewType = useCallback(() => {
    if (viewType === ViewType.LIST) {
      setViewType(ViewType.GRID)
    } else {
      setViewType(ViewType.LIST)
    }
  }, [viewType])

  const selected = useMemo(() => {
    return {
      [CoursesTabs.COURSES]: selectedEntities[CoursesTabs.COURSES].length,
      [CoursesTabs.PACKAGES]: selectedEntities[CoursesTabs.PACKAGES].length,
      [CoursesTabs.TRAINING_DAYS]:
        selectedEntities[CoursesTabs.TRAINING_DAYS].length,
    }
  }, [selectedEntities])

  const onSelect = useCallback(
    (course: OnlineCourseItem | TCoursePackage) => {
      const { activeTab } = query
      setSelectedEntities(prev => ({
        ...prev,
        [activeTab]: _.xorBy(prev[activeTab], [course], 'id'),
      }))
    },
    [query],
  )

  const handleSelectAll = (checked: boolean) => {
    const allCourses = getAllCoursesForOnePage()
    const allCoursesIds = allCourses.map(course => course.id)

    setSelectedEntities(prev => ({
      ...prev,
      [query.activeTab]: checked
        ? [...prev[query.activeTab], ...allCourses]
        : [...prev[query.activeTab]].filter(
            entity => !allCoursesIds.includes(entity.id),
          ),
    }))
  }

  const getAllCoursesForOnePage = () => {
    if (query.activeTab === CoursesTabs.COURSES) {
      return (coursesData.data as OnlineCourseItem[]).filter(
        course => course.status !== ONLINE_COURSE_STATUS.DRAFT,
      )
    }

    return (coursesData.data as TCoursePackage[]).filter(
      course => !course.isDraft,
    )
  }

  const areSelectedAll = () => {
    const allCourses = getAllCoursesForOnePage()
    const allCoursesIds = allCourses.map(entity => entity.id)
    const selectedIds = selectedEntities[query.activeTab].map(
      entity => entity.id,
    )

    return !_.isEmpty(selectedIds)
      ? _.every(allCoursesIds, courseId => _.includes(selectedIds, courseId))
      : false
  }

  const getPage = () => {
    if (coursesData.data.length <= 1 && coursesData.page > 1) {
      return coursesData.page - 1
    }

    return coursesData.page
  }

  const handlePackageDuplicate = async (coursePackage: TCoursePackage) => {
    try {
      const data = await duplicatePackage(coursePackage.id)
      router.navigate(`/courses/packages/manage/${data.id}`)

      toast(
        `Package ${coursePackage.name} has been successfully cloned!`,
        successToastOptions,
      )
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Courses'
            items={[
              {
                linkTo: '/',
                title: 'Courses',
              },
              {
                active: true,
                title: 'All Courses',
              },
            ]}
          />
          <Row>
            <Col>
              <Card
                style={viewType === ViewType.LIST ? {} : { boxShadow: 'none' }}
              >
                <CardHeader className='p-0 py-2'>
                  <div className='d-flex flex-shrink-0 gap-3 justify-content-end mb-2 card-header px-0 py-1'>
                    <div className='d-flex align-items-center gap-2 px-2 p-0'>
                      <Button
                        className='btn-ghost-primary align-middle p-0'
                        onClick={
                          viewType === ViewType.GRID
                            ? toggleViewType
                            : undefined
                        }
                      >
                        <i
                          className={`ri-list-check fs-20  ${
                            viewType === ViewType.LIST ? '' : 'text-black-83'
                          }`}
                        ></i>
                      </Button>
                      <Button
                        className='btn-ghost-primary align-middle p-0'
                        onClick={
                          viewType === ViewType.LIST
                            ? toggleViewType
                            : undefined
                        }
                      >
                        <i
                          className={`bx bx-grid-alt fs-20 ${
                            viewType === ViewType.GRID ? '' : 'text-black-83'
                          }`}
                        ></i>
                      </Button>
                    </div>
                  </div>
                  <div className='hstack gap-3 mx-n3 justify-content-between px-4 py-1 flex-wrap'>
                    <div className='d-flex align-items-center gap-2 ps-2'>
                      <Input
                        className='form-check-input cursor-pointer m-0'
                        type='checkbox'
                        name={`selectCourses`}
                        id={`select-courses`}
                        checked={areSelectedAll()}
                        onChange={e => handleSelectAll(e.target.checked)}
                      />
                      <Label
                        className='form-check-label text-primary'
                        htmlFor={`select-courses`}
                      >
                        Select all
                      </Label>
                    </div>

                    <SearchInput
                      style={{ maxWidth: 370 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key || ''}
                    />
                    <CourseTabs
                      activeTab={query.activeTab}
                      onTabChange={activeTab => {
                        setQuery(prev => ({
                          ...prev,
                          activeTab: activeTab,
                          publishedDate: [],
                          statuses: undefined,
                          states: [],
                          page: 1,
                        }))
                      }}
                      extraTabData={selected}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      {hasPermissionToAssign && (
                        <Button
                          color={'primary'}
                          className='btn btn-primary align-middle'
                          onClick={() => {
                            setShowAssign(true)
                          }}
                        >
                          <i className='ri-edit-box-line me-1 fs-16'></i>Assign
                          {_.flatMap(selectedEntities).length
                            ? ` (${_.flatMap(selectedEntities).length})`
                            : ''}
                        </Button>
                      )}
                      {query.activeTab === CoursesTabs.COURSES ? (
                        <>
                          {coursePermissions.addEdit && (
                            <Button
                              color={'primary'}
                              className='btn btn-secondary align-middle'
                              onClick={() => {
                                router.navigate('/courses/default')
                              }}
                            >
                              <i className='ri-add-line me-1 fs-16'></i>Add New
                              Course
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {packagePermissions.clone && (
                            <Button
                              color={'primary'}
                              className='btn btn-secondary align-middle'
                              onClick={() => {
                                router.navigate('/courses/packages/general')
                              }}
                            >
                              <i className='ri-add-line me-1 fs-16'></i>Add New
                              {query.activeTab === CoursesTabs.PACKAGES
                                ? ' Package'
                                : ' Training Day'}
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <Row
                    className={`gap-4 ${showFilters ? 'my-3' : 'my-0'} mx-2`}
                  >
                    <Filters<GetCoursesDTO.Request>
                      visible={showFilters}
                      setFilters={setQuery}
                      activeTab={query.activeTab}
                    />
                  </Row>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={isFiltering}
                  pages={coursesData.pages}
                  style={
                    viewType === ViewType.LIST
                      ? { paddingTop: '0', paddingBottom: '0' }
                      : {
                          backgroundColor: '#f7f8f9',
                          boxShadow: 'none',
                          padding: 0,
                        }
                  }
                >
                  {query.activeTab === CoursesTabs.COURSES ? (
                    <>
                      {viewType === ViewType.LIST ? (
                        <CoursesTableView
                          courses={coursesData.data as OnlineCourseItem[]}
                          query={query as GetOnlineCoursesDTO.Request}
                          handleDelete={course => {
                            setDeleteCourseId(course.id)
                          }}
                          handleClone={course => {
                            setClonedCourseId(course.id)
                          }}
                          handleSort={handleSort}
                          onSelect={onSelect}
                          selectedEntities={selectedEntities[query.activeTab]}
                          permissions={coursePermissions}
                        />
                      ) : (
                        <CoursesGridView
                          courses={coursesData.data as OnlineCourseItem[]}
                          query={query as GetOnlineCoursesDTO.Request}
                          handleDelete={course => {
                            setDeleteCourseId(course.id)
                          }}
                          handleClone={course => {
                            setClonedCourseId(course.id)
                          }}
                          onSelect={onSelect}
                          selectedEntities={selectedEntities[query.activeTab]}
                          permissions={coursePermissions}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {viewType === ViewType.LIST ? (
                        <PackageTableView
                          packages={coursesData.data as TCoursePackage[]}
                          query={query}
                          handleSort={handleSort}
                          handleDuplicate={handlePackageDuplicate}
                          onSelect={onSelect}
                          selectedEntities={selectedEntities[query.activeTab]}
                          permissions={packagePermissions}
                        />
                      ) : (
                        <PackagesGridView
                          packages={coursesData.data as TCoursePackage[]}
                          query={query}
                          handleDuplicate={handlePackageDuplicate}
                          onSelect={onSelect}
                          selectedEntities={selectedEntities[query.activeTab]}
                          permissions={packagePermissions}
                        />
                      )}
                    </>
                  )}
                  <div className='my-3'>
                    <Pagination
                      currentPage={coursesData.page - 1}
                      totalPages={coursesData.pages}
                      totalRecords={coursesData.count}
                      setPage={page => {
                        setQuery(prev => ({ ...prev, page: ++page }))
                      }}
                    />
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>

            {coursePermissions.delete && renderDeleteModal()}
            {coursePermissions.clone && renderCloneModal()}
            {hasPermissionToAssign && (
              <AssignCourseModal
                isOpen={showAssign}
                onClose={() => setShowAssign(false)}
                onSubmit={() =>
                  setQuery(prev => ({ ...prev, page: coursesData.page }))
                }
                selectedEntities={selectedEntities}
              />
            )}
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
export default withRouter(OnlineCourses)
