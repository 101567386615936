import React from 'react'
import { Col, Row } from 'reactstrap'
import logoLight from '../../assets/images/logo-header.png'

const ParticlesAuthHeader = () => {
  return (
    <Row>
      <Col lg={12}>
        <div className='text-center mt-sm-5 text-white-50 pt-5 mb-4'>
          <div className='d-inline-block auth-logo'>
            <img src={logoLight} alt='' height='26' />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default ParticlesAuthHeader
