import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import { PackageLayout } from './PackageLayout'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import {
  deleteCourseFromPackage,
  getCoursePackage,
  getPackageCourses,
  updatePackage,
} from '../../../helpers/api/coursePackages'
import {
  CoursePackageFormatsEnum,
  TCoursePackage,
} from '../../../sharedTypes/models/coursePackage'
import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  COURSE_FILTER_FORMATS,
  CoursesPermissions,
  OnlineCourseItem,
  OrderType,
} from '../../../sharedTypes'
import { ViewType } from '../MediaLibrary'
import SelectCoursesModal from '../../../Components/Modals/CoursesModal/SelectCoursesModal'
import BooksAndStethoscope from '../../../assets/images/svg/Books_and_stethoscope.svg'
import {
  EmptyPackageCoursesProps,
  PACKAGE_TABS,
  SelectedCoursesData,
} from './types'
import axios from 'axios'
import { toast } from 'react-toastify'
import {
  errorToastOptions,
  handleError,
  successToastOptions,
} from '../../../helpers/toast_helper'
import PackageCoursesTableView from './PackageCoursesTableView'
import PackageCoursesGridView from './PackageCoursesGridView'
import { Pagination } from '../../../Components/Common/Pagination'
import {
  GetPackageCoursesDTO,
  PackageCoursesSortBy,
} from '../../../sharedTypes/api/coursePackages'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import _ from 'lodash'
import classnames from 'classnames'
import TrainingDay from './TrainingDay'
import PublishConfirmation from '../../../Components/Modals/PrimaryConfirmationModal'
import { usePermissions } from '../../../hooks/usePermissions'

interface GenerateStepProps extends WithRouterProps {}

const ManagePackageCourses = ({ router }: GenerateStepProps) => {
  const [coursePackage, setCoursePackage] = useState<TCoursePackage | null>(
    null,
  )
  const [coursesCount, setCoursesCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [deleteCourse, setDeleteCourse] = useState<OnlineCourseItem | null>(
    null,
  )
  const [publishConfirm, setPublishConfirm] = useState<boolean>(false)
  const [data, setData] = useState<GetPackageCoursesDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    packageCourses: [],
  })

  const [query, setQuery] = useState<GetPackageCoursesDTO.Request>({
    page: 1,
    limit: 10,
  })
  const [viewType, setViewType] = useState<ViewType>(ViewType.LIST)
  const [isOpenAddCoursesModal, setIsOpenAddCoursesModal] =
    useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<PACKAGE_TABS>(PACKAGE_TABS.COURSES)
  const [trainingDaysCount, setTrainingDaysCount] = useState<number>(0)

  const hasPermissionToAddCourse = usePermissions(
    CoursesPermissions.ADD_COURSES_TO_PACKAGE,
  )

  const tabChange = (tab: PACKAGE_TABS) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleViewType = useCallback(() => {
    if (viewType === ViewType.LIST) {
      setViewType(ViewType.GRID)
    } else {
      setViewType(ViewType.LIST)
    }
  }, [viewType])

  useEffect(() => {
    fetchCoursePackage()
  }, [router.params.packageId])

  const fetchCoursePackage = () => {
    setQuery(prev => ({
      ...prev,
      page: 1,
    }))
    tabChange(PACKAGE_TABS.COURSES)

    getCoursePackage(Number(router.params.packageId))
      .then(res => {
        setCoursePackage(res)
        setCoursesCount(res.coursesCount || 0)
        setTrainingDaysCount(res.trainingDaysCount || 0)
      })
      .catch(error => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          router.navigate('/404')
        }
      })
  }

  useEffect(() => {
    if (router.params.packageId) {
      setIsLoading(true)
      getPackageCourses(query, Number(router.params.packageId))
        .then(res => {
          setData(res)
        })
        .catch(e => {
          toast('Something went wrong', errorToastOptions)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query])

  const handleSort = useCallback((column: PackageCoursesSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const tabs = useMemo(() => {
    const initialTabs = [
      {
        label: 'Courses',
        key: PACKAGE_TABS.COURSES,
      },
    ]

    if (coursePackage?.format === CoursePackageFormatsEnum.TRAINING_DAY) {
      return [
        ...initialTabs,
        {
          label: 'Schedule',
          key: PACKAGE_TABS.SCHEDULE,
        },
      ]
    }

    return initialTabs
  }, [coursePackage])

  const publishMessage = useMemo(() => {
    return (
      <div>
        Are you sure you want to publish the package: <br />'
        {coursePackage?.name}'? <br />
        You won't be able to edit the package once it is published.
      </div>
    )
  }, [coursePackage])

  const isPackageDraft = useMemo(() => {
    return coursePackage?.isDraft
  }, [coursePackage])

  const handleSelect = async (coursesData: SelectedCoursesData[]) => {
    try {
      if (coursePackage) {
        await updatePackage(+coursePackage.id, { courses: coursesData })
        setCoursesCount(coursesData.length)
        setIsOpenAddCoursesModal(false)
        setCoursePackage({
          ...coursePackage,
          coursesCount: coursesData.length,
        })
        setQuery(prev => ({
          ...prev,
          page: 1,
        }))
      }
    } catch (e) {
      handleError(e)
    }
  }

  const onSaveAsDraft = async () => {
    try {
      if (coursePackage) {
        await updatePackage(+coursePackage.id, { isDraft: true })
        router.navigate('/courses') // TODO change redirect path
      }
    } catch (e) {
      handleError(e)
    }
  }

  const onPublish = async () => {
    try {
      if (coursePackage) {
        await updatePackage(+coursePackage.id, { isDraft: false })
        toast('Success! The package has been published!', successToastOptions)

        router.navigate('/courses') // TODO change redirect path
      }
    } catch (e) {
      handleError(e)
    }
  }

  const onDeleteCourse = useCallback(async () => {
    try {
      if (coursePackage && deleteCourse) {
        await deleteCourseFromPackage(+coursePackage.id, deleteCourse.id)
        setQuery(prev => ({
          ...prev,
          page: 1,
        }))
        setDeleteCourse(null)
        setCoursesCount(coursesCount - 1)
      }
    } catch (e: any) {
      handleError(e)
    }
  }, [coursePackage, deleteCourse])

  return (
    <PackageLayout
      backText='Cancel'
      router={router}
      coursePackage={coursePackage}
      coursesCount={coursesCount}
      trainingDaysCount={trainingDaysCount}
      onSaveAsDraft={onSaveAsDraft}
      onPublish={() => setPublishConfirm(true)}
      fetchCoursePackage={fetchCoursePackage}
      tabChange={
        activeTab !== PACKAGE_TABS.SCHEDULE &&
        coursePackage?.format === CoursePackageFormatsEnum.TRAINING_DAY
          ? () => tabChange(PACKAGE_TABS.SCHEDULE)
          : undefined
      }
    >
      <Fragment>
        <Nav
          className='nav nav-tabs nav-tabs-custom rounded bg-white course-package-tabs'
          role='tablist'
        >
          {tabs.map((tab, index) => (
            <NavItem key={index}>
              <NavLink
                to='#'
                className={`${classnames({
                  active: activeTab === tab.key,
                })} cursor-pointer`}
                onClick={() => {
                  tabChange(tab.key)
                }}
              >
                {tab.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'courses'}>
            {!coursesCount ? (
              <EmptyPackageCourses
                emptyCoursesLabel={'No Courses Added yet!'}
                emptyImg={BooksAndStethoscope}
                addLabel={'Add Course'}
                hasPermissionToAddCourse={hasPermissionToAddCourse}
                onAdd={() => setIsOpenAddCoursesModal(true)}
              />
            ) : (
              <div className='package-courses-container'>
                <div className=' bg-white p-3'>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <div className='d-flex align-items-center gap-2'>
                        <Button
                          className='btn-ghost-primary align-middle p-0'
                          onClick={
                            viewType === ViewType.GRID
                              ? toggleViewType
                              : undefined
                          }
                        >
                          <i
                            className={`ri-list-check fs-20  ${
                              viewType === ViewType.LIST ? '' : ' text-black-83'
                            }`}
                          ></i>
                        </Button>
                        <Button
                          className='btn-ghost-primary align-middle p-0'
                          onClick={
                            viewType === ViewType.LIST
                              ? toggleViewType
                              : undefined
                          }
                        >
                          <i
                            className={`bx bx-grid-alt fs-20 ${
                              viewType === ViewType.GRID ? '' : ' text-black-83'
                            }`}
                          ></i>
                        </Button>
                      </div>
                      {hasPermissionToAddCourse && (
                        <Button
                          color={'primary'}
                          disabled={!isPackageDraft}
                          className={`btn btn-primary align-middle ${
                            !isPackageDraft ? 'pe-none' : ''
                          }`}
                          onClick={() => {
                            setIsOpenAddCoursesModal(true)
                          }}
                        >
                          <i className='ri-add-line me-1 fs-16'></i>Add Course
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <Card
                  style={
                    viewType === ViewType.LIST ? {} : { boxShadow: 'none' }
                  }
                >
                  <NoResultTableWrapper
                    isLoading={isLoading}
                    isFiltering={false}
                    pages={data.pages}
                    style={
                      viewType === ViewType.LIST
                        ? {}
                        : { backgroundColor: '#f7f8f9', boxShadow: 'none' }
                    }
                  >
                    <div className='mt-2'>
                      {viewType === ViewType.LIST ? (
                        <div className='overflow-x-auto'>
                          <PackageCoursesTableView
                            packageCourses={data.packageCourses}
                            query={query}
                            handleSort={handleSort}
                            handleDelete={setDeleteCourse}
                            viewOnly={!isPackageDraft}
                          />
                        </div>
                      ) : (
                        <PackageCoursesGridView
                          packageCourses={data.packageCourses}
                          query={query}
                          handleDelete={setDeleteCourse}
                          viewOnly={!isPackageDraft}
                        />
                      )}
                      <div className='mx-3 m-3'>
                        <Pagination
                          currentPage={data.page - 1}
                          totalPages={data.pages}
                          totalRecords={data.count}
                          setPage={page => {
                            setQuery(prev => ({ ...prev, page: ++page }))
                          }}
                        />
                      </div>
                    </div>
                  </NoResultTableWrapper>
                </Card>
              </div>
            )}
          </TabPane>

          <TabPane tabId={'schedule'}>
            {coursePackage &&
              coursePackage.format ===
                CoursePackageFormatsEnum.TRAINING_DAY && (
                <TrainingDay
                  coursePackageId={coursePackage.id}
                  trainingDaysCount={trainingDaysCount}
                  setTrainingDaysCount={setTrainingDaysCount}
                />
              )}
          </TabPane>
        </TabContent>

        {isOpenAddCoursesModal && (
          <SelectCoursesModal
            isOpen={isOpenAddCoursesModal}
            packageCourses={data.packageCourses}
            onClose={() => {
              setIsOpenAddCoursesModal(false)
            }}
            handleSelect={handleSelect}
            filteredFormats={
              coursePackage &&
              coursePackage.format === CoursePackageFormatsEnum.TRAINING_DAY
                ? [COURSE_FILTER_FORMATS.IN_PERSON]
                : []
            }
          />
        )}

        <DeleteConfirmation
          isOpen={!!deleteCourse}
          title='Delete Course'
          message={`Are you sure you want to delete ${_.get(
            deleteCourse,
            'translations[0].content.name',
            '-',
          )} from this Package?`}
          onDelete={onDeleteCourse}
          onClose={() => {
            setDeleteCourse(null)
          }}
        />
        <PublishConfirmation
          onConfirm={onPublish}
          onClose={() => setPublishConfirm(false)}
          isOpen={publishConfirm}
          message={publishMessage}
          title={'Publish Confirmation'}
          confirmButtonLabel={'Publish'}
        />
      </Fragment>
    </PackageLayout>
  )
}

export const EmptyPackageCourses = ({
  emptyCoursesLabel,
  emptyImg,
  addLabel,
  onAdd,
  hasPermissionToAddCourse,
}: EmptyPackageCoursesProps) => {
  return (
    <Fragment>
      <Card>
        <CardBody className='empty-course-package d-flex justify-content-center align-items-center'>
          <div className='d-flex align-items-center gap-2 flex-column mb-2'>
            <img src={emptyImg} className='img-fluid mx-auto d-flex' alt='' />
            <h5>{emptyCoursesLabel}</h5>
            {hasPermissionToAddCourse && (
              <div>
                <Button
                  color={'primary'}
                  size='sm'
                  onClick={onAdd}
                  className='btn btn-primary align-middle'
                >
                  <i className='ri-add-line me-1 fs-16'></i>
                  {addLabel}
                </Button>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  )
}
export default withRouter(ManagePackageCourses)
