import { getUsers } from '../helpers/api/users'
import { getFacilities } from '../helpers/api/facilities'
import { getDepartments, getPositions } from '../helpers/api_helper'
import {
  AssignTo,
  UserPermissions,
  TFacility,
  TUser,
  TPosition,
  TDepartment,
  Option,
} from '../sharedTypes'
import { pick } from 'lodash'
import { getUserDisplayName } from '../helpers/user'

export interface RecipientsParams {
  page?: number
  limit: number
  key: string | undefined
}

export interface RecipientsResponse {
  data: { label: string; value: string | number }[]
  count: number
  page: number
  pages: number
}

export const mapRecipientsToOptions = (
  recipients: Array<TFacility | TUser | TPosition | TDepartment>,
): Option<number>[] =>
  recipients.map(entity => ({
    label: 'name' in entity ? entity.name : getUserDisplayName(entity) || '',
    value: entity.id,
  }))

export const fetchRecipientsOptions = (
  sendTo: AssignTo,
  params: RecipientsParams,
  userPermission: UserPermissions,
): Promise<RecipientsResponse> => {
  let action = null

  if (sendTo === AssignTo.USERS) {
    action = getUsers({
      ...params,
      permission: userPermission,
    }).then(res => ({
      ...res.data,
      data: mapRecipientsToOptions(res.data.users),
    }))
  } else if (sendTo === AssignTo.FACILITIES) {
    action = getFacilities(params).then(res => ({
      ...res.data,
      data: mapRecipientsToOptions(res.data.facilities),
    }))
  } else if (sendTo === AssignTo.DEPARTMENTS) {
    action = getDepartments(params).then(res => ({
      ...res.data,
      data: mapRecipientsToOptions(res.data.departments),
    }))
  } else {
    action = getPositions({ ...params, groupBy: 'name' }).then(res => ({
      ...res.data,
      data: mapRecipientsToOptions(res.data.positions),
    }))
  }

  return action.then(res => {
    return pick(res, ['data', 'count', 'page', 'pages'])
  })
}

export const getAveragePercent = (specCount: number, totalCount: number) => {
  return totalCount != 0 ? Math.round((specCount / totalCount) * 100) : 0
}
