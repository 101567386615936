import { CREDENTIALS_TYPE, TUser, TUserCredentials } from '../models'
import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import {
  CalendarPermissions,
  CommunicationPermissions,
  CoursesPermissions,
  FacilityManagementPermissions,
  ReportingPermissions,
  USER_COURSE_STATUSES,
  USER_REGISTERED_STATUSES,
  USER_STATUSES,
  UserManagementPermissions,
} from '../lib/types'
import { NewUsersStatuses } from '../../pages/DashboardAnalytics/types'

export enum UsersSortBy {
  FACILITY_NAME = '"User->facility"."name"',
  POSITION_NAME = '"User->position"."name"',
  EMPLOYEE_ID = '"User"."employeeId"',
  ID = '"User"."id"',
  COURSE_STATUS = '"User"."courseStatus"',
  HIRE_DATE = '"User"."hireDate"',
  REGISTRATION_DATE = '"User"."signupDate"',
  USER = 'user',
  LASTNAME = '"User"."lastName"',
  STATUS = '"User"."status"',
  REGISTERED = '"User"."isSignedUp"',
  COURSES_NOT_STARTED = '"User->reportData"."coursesNotStarted"',
  COURSES_IN_PROGRESS = '"User->reportData"."coursesInProgress"',
  COURSES_COMPLETED_IN_PAST_12_MONTHS = '"User->reportData"."coursesCompletedInPast12Months"',
  COURSES_OVERDUE = '"User->reportData"."coursesOverdue"',
}

export type UserPermissions =
  | UserManagementPermissions.VIEW_USER_LISTING
  | FacilityManagementPermissions.VIEW_FACILITY_EMPLOYEES
  | CommunicationPermissions.EDIT_NOTIFICATIONS
  | CommunicationPermissions.CREATE_CLONE_NOTIFICATIONS
  | CommunicationPermissions.CREATE_CLONE_SURVEY
  | CommunicationPermissions.EDIT_SURVEY
  | UserManagementPermissions.ASSIGN_COURSE_TO_USER
  | CoursesPermissions.ASSIGN_COURSE
  | CalendarPermissions.ADD_PARTICIPANTS
  | ReportingPermissions.VIEW_USER_REPORT

export namespace GetUsersDTO {
  export interface Request extends PaginationRequest {
    groupIds?: number[]
    facilityIds?: number[]
    departmentIds?: number[]
    positionIds?: number[]
    sortBy?: UsersSortBy
    key?: string
    status?: USER_STATUSES[]
    registeredStatuses?: USER_REGISTERED_STATUSES[]
    courseStatus?: USER_COURSE_STATUSES
    permission?: UserPermissions
  }
  export interface Response extends PaginatedBaseResponse {
    users: TUser[]
  }
}

export namespace GetEditRequestFieldsDto {
  export interface Response {
    fields: { field_name: string; current_value: string | null }[]
  }
}

export namespace PostEditRequestFieldsDto {
  export interface Request {
    field_name: string
    field_value: string | number
  }
}

export namespace UpdateUserCredentialDto {
  export interface Request {
    credentialType: CREDENTIALS_TYPE
    phone?: string
    email?: string
    id?: number
  }

  export interface Response {
    credential: TUserCredentials
  }
}

export namespace ChangeUserPasswordDTO {
  export interface Request {
    currentPassword: string
    newPassword: string
    confirmPassword: string
  }
  export interface Response extends TUser {}
}

export namespace PatchUserRoleUpdateDTO {
  export interface Request {
    roleId: number
  }
  export interface Response extends TUser {}
}

export namespace GetUsersCourseStatusAnalyticsDTO {
  export interface Request {
    facilityId?: number
    groupId?: number
  }
  export interface Response {
    data: {
      [courseStatus in USER_COURSE_STATUSES]: number
    }
  }
}

export namespace GetAnalyticsForLast30DaysDTO {
  export interface Request {
    facilityId?: number
    groupId?: number
  }
  export interface Response {
    usersPendingCompletion: number
    coursesCompleted: number
  }
}

export namespace GetUserStatusAnalyticsDTO {
  export interface Request {
    facilityId?: number
    groupId?: number
  }
  export interface Response {
    [NewUsersStatuses.NOT_REGISTERED]: {
      count: number
      percent: number
    }
    [NewUsersStatuses.NOT_STARTED_ONBOARDING]: {
      count: number
      percent: number
    }
    [NewUsersStatuses.ONBOARDING_IN_PROGRESS]: {
      count: number
      percent: number
    }
    [NewUsersStatuses.ONBOARDING_COMPLETE]: {
      count: number
      percent: number
    }
    totalUsersCount: number
  }
}
