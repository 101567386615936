import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap'
import { toast } from 'react-toastify'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  AttachmentsSortBy,
  CoursesPermissions,
  GetAttachmentsDTO,
  OrderType,
  TAttachment,
} from '../../../sharedTypes'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { successToastOptions, handleError } from '../../../helpers/toast_helper'
import { deleteAttachment, getAttachments } from '../../../helpers/api_helper'
import { Pagination } from '../../../Components/Common/Pagination'
import MediaUploadModal from '../../../Components/Modals/MediaUploadModal'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import FileDetailsModal from '../../../Components/Modals/FileDetailsModal'
import Filters from './Filters'
import MediaTableView from './TableView'
import MediaGridView from './GridView'
import { usePermissions } from '../../../hooks/usePermissions'

export enum ViewType {
  'LIST' = 'list',
  'GRID' = 'grid',
}

const ScheduledCourses = () => {
  document.title = 'Media Library | Mastered - Admin & Dashboard'

  const [isLoading, setIsLoading] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [viewType, setViewType] = useState<ViewType>(ViewType.LIST)
  const [data, setData] = useState<GetAttachmentsDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    attachments: [],
  })

  const [query, setQuery] = useState<GetAttachmentsDTO.Request>({
    page: 1,
    limit: 10,
  })

  const isFiltering = useMemo<boolean>(() => !!query.key, [query])

  const [showAddModal, setShowAddModal] = useState(false)
  const [deleteMedia, setDeleteMedia] = useState<TAttachment | null>(null)
  const [showDetailsMedia, setShowDetailsMedia] = useState<TAttachment | null>(
    null,
  )

  const hasPermissionToAdd = usePermissions(CoursesPermissions.ADD_MEDIA)

  const handleSort = useCallback((column: AttachmentsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const toggleViewType = useCallback(() => {
    if (viewType === ViewType.LIST) {
      setViewType(ViewType.GRID)
    } else {
      setViewType(ViewType.LIST)
    }
  }, [viewType])

  useEffect(() => {
    if (viewType === ViewType.LIST) {
      setQuery(prev => ({
        ...prev,
        page: 1,
        limit: 10,
      }))
    } else {
      setQuery(prev => ({
        ...prev,
        page: 1,
        limit: 24,
      }))
    }
  }, [viewType])

  //Fetch data
  useEffect(() => {
    setIsLoading(true)
    getAttachments(query)
      .then(res => {
        setData(res.data)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  const onAddNewMedia = () => {
    setShowAddModal(false)
    setQuery(q => ({ ...q, page: 1 }))
  }

  const onSubmitEditMedia = (media: TAttachment) => {
    setShowDetailsMedia(media)
    setData(prev => ({
      ...prev,
      attachments: prev.attachments.map(a => {
        if (a.id === media.id) {
          return media
        }
        return a
      }),
    }))
  }

  const onDeleteMedia = useCallback(() => {
    if (deleteMedia) {
      deleteAttachment(deleteMedia.id)
        .then(() => {
          toast(
            'Success - Media file successfully deleted',
            successToastOptions,
          )
          setDeleteMedia(null)
          setShowDetailsMedia(null)
          setQuery(q => ({ ...q }))
        })
        .catch(handleError)
    }
  }, [deleteMedia])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Media Library'
            items={[
              {
                linkTo: '/',
                title: 'Courses',
              },
              {
                active: true,
                title: 'Media Library',
              },
            ]}
          />
          <Row>
            <Col>
              <Card
                style={viewType === ViewType.LIST ? {} : { boxShadow: 'none' }}
              >
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <div>
                        <Button
                          className='btn-ghost-primary align-middle'
                          onClick={toggleViewType}
                        >
                          <i
                            className={`ri-list-check fs-20  ${
                              viewType === ViewType.LIST ? '' : ' text-black-83'
                            }`}
                          ></i>
                        </Button>
                        <Button
                          className='btn-ghost-primary align-middle'
                          onClick={toggleViewType}
                        >
                          <i
                            className={`bx bx-grid-alt fs-20 ${
                              viewType === ViewType.GRID ? '' : ' text-black-83'
                            }`}
                          ></i>
                        </Button>
                      </div>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      {hasPermissionToAdd && (
                        <Button
                          color={'primary'}
                          className='btn btn-primary align-middle'
                          onClick={() => setShowAddModal(true)}
                        >
                          <i className='ri-add-line me-1 fs-16'></i>Add Media
                        </Button>
                      )}
                    </div>
                  </div>
                  <Row
                    className={`gap-4 ${showFilters ? 'my-3' : 'my-0'} mx-0`}
                  >
                    <Filters
                      visible={showFilters}
                      setFilters={data => {
                        setQuery(prev => ({
                          ...prev,
                          type: data.type,
                          access: data.access,
                          page: 1,
                        }))
                      }}
                    />
                  </Row>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={isFiltering}
                  pages={data.pages}
                  style={
                    viewType === ViewType.LIST
                      ? {}
                      : { backgroundColor: '#f7f8f9', boxShadow: 'none' }
                  }
                >
                  <div className='table-card'>
                    {viewType === ViewType.LIST ? (
                      <div className='overflow-x-auto'>
                        <MediaTableView
                          attachments={data.attachments}
                          query={query}
                          handleSort={handleSort}
                          setDeleteMedia={setDeleteMedia}
                          onShow={setShowDetailsMedia}
                        />
                      </div>
                    ) : (
                      <MediaGridView
                        attachments={data.attachments}
                        query={query}
                        handleSort={handleSort}
                        setDeleteMedia={setDeleteMedia}
                        onShow={setShowDetailsMedia}
                      />
                    )}
                    <div className='mx-3 mt-3'>
                      <Pagination
                        limit={query.limit}
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <MediaUploadModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
        onSubmit={onAddNewMedia}
      />
      <DeleteConfirmation
        isOpen={!!deleteMedia}
        onClose={() => {
          setDeleteMedia(null)
        }}
        title='Delete Media'
        message={`Are you sure you want to delete media "${deleteMedia?.name}"?`}
        onDelete={onDeleteMedia}
      />
      {showDetailsMedia && (
        <FileDetailsModal
          onClose={() => setShowDetailsMedia(null)}
          isOpen={true}
          onSubmit={onSubmitEditMedia}
          attachment={showDetailsMedia}
          setDeleteMedia={setDeleteMedia}
        />
      )}
    </React.Fragment>
  )
}

export default ScheduledCourses
