import { TFacility, TFacilityStatus } from '../../../sharedTypes'
import React, { useState } from 'react'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import AdminsBlock from './AdminsBlock'
import { ReactComponent as FlashLightOff } from '../../../assets/images/svg/flashOff.svg'

const BadgeColorByStatus = {
  [TFacilityStatus.ACTIVE]: 'primary',
  [TFacilityStatus.INACTIVE]: 'warning',
}

interface FacilityProps extends WithRouterProps {
  facility: TFacility
  onEdit: (facility: TFacility) => void
  onSignatoryChange: (facility: TFacility) => void
  onToggleActivation: () => void
  hasPermissionToView: boolean
  hasPermissionToEdit: boolean
  hasPermissionToActivate: boolean
  hasPermissionToDeactivate: boolean
}

const FacilityItem = ({
  facility,
  router,
  onEdit,
  hasPermissionToView,
  hasPermissionToEdit,
  hasPermissionToActivate,
  hasPermissionToDeactivate,
  onSignatoryChange,
  onToggleActivation,
}: FacilityProps) => {
  const [actionDropdownOpen, setActionDropdownOpen] = useState<boolean>(false)
  const navigateToFacility = () => {
    router.navigate('/facility-management/facility/' + facility.id)
  }

  return (
    <tr
      key={facility.id}
      style={{ height: 64 }}
      onClick={hasPermissionToView ? () => navigateToFacility() : undefined}
    >
      <td></td>
      <td>
        <span className='cursor-pointer'>
          <i className='ri-building-line me-2 text-primary'></i>
          {facility.name}
        </span>
      </td>
      <td>
        <span
          className={`badge badge-soft-${
            BadgeColorByStatus[facility.status]
          } fs-12 fw-normal text-capitalize`}
        >
          {facility.status}
        </span>
      </td>
      <td>
        <div className='d-flex justify-content-between'>
          {facility.signatory ? (
            <AdminsBlock admins={[facility.signatory]} />
          ) : (
            '-'
          )}
          {(hasPermissionToView ||
            hasPermissionToEdit ||
            hasPermissionToActivate ||
            hasPermissionToDeactivate) && (
            <Dropdown
              isOpen={actionDropdownOpen}
              toggle={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                setActionDropdownOpen(prevState => !prevState)
              }}
            >
              <DropdownToggle
                className={`badge btn ${
                  actionDropdownOpen
                    ? 'btn-primary'
                    : 'text-light-purple btn-soft-primary'
                }`}
                tag='button'
              >
                <i className='mdi mdi-dots-horizontal align-middle' />
              </DropdownToggle>
              <DropdownMenu>
                {hasPermissionToView && (
                  <DropdownItem>
                    <div onClick={navigateToFacility}>
                      <i className='ri-eye-line text-muted cursor-pointer'></i>
                      <span className='mx-2'>View Information</span>
                    </div>
                  </DropdownItem>
                )}

                {hasPermissionToActivate &&
                  facility.status === TFacilityStatus.INACTIVE && (
                    <DropdownItem>
                      <div onClick={onToggleActivation}>
                        <i className='ri-flashlight-line text-green-500 cursor-pointer'></i>
                        <span className='mx-2 text-green-500'>
                          Activate Facility
                        </span>
                      </div>
                    </DropdownItem>
                  )}

                {hasPermissionToDeactivate &&
                  facility.status === TFacilityStatus.ACTIVE && (
                    <DropdownItem>
                      <div
                        onClick={onToggleActivation}
                        className='d-flex align-items-center'
                      >
                        <FlashLightOff
                          style={{ width: 14, height: 14 }}
                          className='flash-light-off-success'
                        />
                        <span className='mx-2 text-green-500'>
                          Deactivate Facility
                        </span>
                      </div>
                    </DropdownItem>
                  )}

                {hasPermissionToEdit && (
                  <DropdownItem>
                    <div onClick={() => onEdit(facility)}>
                      <i className='ri-pencil-line text-muted cursor-pointer'></i>
                      <span className='mx-2'>Edit Information</span>
                    </div>
                  </DropdownItem>
                )}

                {hasPermissionToEdit && (
                  <DropdownItem>
                    <div onClick={() => onSignatoryChange(facility)}>
                      <i
                        className={`${
                          facility?.signatory ? 'ri-pencil-line' : 'ri-add-line'
                        } text-muted cursor-pointer`}
                      ></i>
                      <span className='mx-2'>
                        {facility?.signatory
                          ? 'Edit Signatory'
                          : 'Add Signatory'}
                      </span>
                    </div>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </td>
    </tr>
  )
}

export default withRouter(FacilityItem)
