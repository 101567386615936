import React from 'react'
import { Link } from 'react-router-dom'
import { showMessages } from '@intercom/messenger-js-sdk'

//import images
import logoSm from '../assets/images/logo-sm.png'
import logoLight from '../assets/images/logo-header.png'

//import Components
import ProfileDropdown from '../Components/Common/ProfileDropdown'
import FacilitiesDropdown from '../Components/Common/FacilitiesDropdown'

interface HeaderProps {
  headerClass?: string
}

const Header = ({ headerClass }: HeaderProps) => {
  const toggleMenuBtn = () => {
    const windowSize = document.documentElement.clientWidth

    if (windowSize > 767) {
      document.querySelector('.hamburger-icon')?.classList.toggle('open')
    }

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      document.body.classList.contains('menu')
        ? document.body.classList.remove('menu')
        : document.body.classList.add('menu')
    }

    //For collapse vertical and semibox menu
    if (
      document.documentElement.getAttribute('data-layout') === 'vertical' ||
      document.documentElement.getAttribute('data-layout') === 'semibox'
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable')
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm')
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable')
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg')
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable')
        document.documentElement.setAttribute('data-sidebar-size', 'lg')
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      document.body.classList.contains('twocolumn-panel')
        ? document.body.classList.remove('twocolumn-panel')
        : document.body.classList.add('twocolumn-panel')
    }
  }

  return (
    <React.Fragment>
      <header id='page-topbar' className={headerClass}>
        <div className='layout-width'>
          <div className='navbar-header'>
            <div className='d-flex'>
              <div className='navbar-brand-box horizontal-logo'>
                <Link to='/' className='logo logo-light'>
                  <span className='logo-sm'>
                    <img src={logoSm} alt='' height='22' />
                  </span>
                  <span className='logo-lg'>
                    <img src={logoLight} alt='' height='26' />
                  </span>
                </Link>
              </div>

              <button
                onClick={toggleMenuBtn}
                type='button'
                className='btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger'
                id='topnav-hamburger-icon'
              >
                <span className='hamburger-icon'>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className='d-flex align-items-center'>
              <FacilitiesDropdown />
              <i
                onClick={() => showMessages()}
                className='ri-question-mark text-white ms-sm-3 fs-24 cursor-pointer'
              />
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
