import { Input, Table, UncontrolledTooltip } from 'reactstrap'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import { Link, useNavigate } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import moment from 'moment/moment'
import { BadgeColorByStatus } from '../../../helpers/course'
import React from 'react'
import { PackagesGridViewProps } from './types'
import {
  CoursePackageStatus,
  TCoursePackage,
} from '../../../sharedTypes/models/coursePackage'
import { PackagesSortBy } from '../../../sharedTypes/api/coursePackages'
import _ from 'lodash'

const Columns = [
  {
    name: '',
    style: { width: '3%' },
  },
  {
    name: 'Code',
    sortBy: PackagesSortBy.CODE,
    style: { width: 120 },
  },
  {
    name: 'Name',
    sortBy: PackagesSortBy.NAME,
  },
  {
    name: 'Description',
    sortBy: PackagesSortBy.DESCRIPTION,
    style: { width: '30%' },
  },
  {
    name: 'Publish Date',
    sortBy: PackagesSortBy.DATE_PUBLISHED,
    style: { width: '10%' },
  },
  {
    name: 'Num of Courses',
    sortBy: PackagesSortBy.NUM_OF_COURSES,
    style: { width: '10%' },
  },
  {
    name: 'Status',
    sortBy: PackagesSortBy.STATUS,
    style: { width: '7%' },
  },
  {
    name: 'Actions',
    style: { width: '5%' },
  },
]

export const getCoursePackageStatus = (coursePackage: TCoursePackage) => {
  return coursePackage.isDraft
    ? CoursePackageStatus.DRAFT
    : CoursePackageStatus.PUBLISHED
}

const PackagesTableView = ({
  packages,
  query,
  handleSort,
  handleDuplicate,
  onSelect,
  selectedEntities,
  permissions,
}: PackagesGridViewProps) => {
  const navigate = useNavigate()

  const handleRowClick = (packageId: number) => {
    navigate(`/courses/packages/manage/${packageId}`)
  }

  return (
    <>
      <div className='table-card pb-3 packages-table'>
        <Table className='align-middle table-nowrap mb-0 mt-3'>
          <thead className='table-light'>
            <tr className='text-muted fs-14'>
              {Columns.map(column => (
                <th
                  scope='col'
                  className='align-middle'
                  style={column.style}
                  key={column.name}
                >
                  {column.name}
                  {!!column.sortBy && (
                    <ColumnSortIcon<PackagesSortBy>
                      sortOrder={query.orderBy}
                      sortedColumn={query.sortBy as PackagesSortBy}
                      column={column.sortBy}
                      handleSort={handleSort}
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {packages.map((coursePackage, i) => (
              <tr
                key={i}
                className='fs-14 fw-light'
                onClick={() => handleRowClick(coursePackage.id)}
              >
                <td>
                  <Input
                    className='form-check-input cursor-pointer m-0'
                    type='checkbox'
                    name={`selectPackage${coursePackage.id}`}
                    id={`selectPackage${coursePackage.id}`}
                    disabled={coursePackage.isDraft}
                    onChange={() => {
                      onSelect(coursePackage)
                    }}
                    checked={
                      !!_.find(selectedEntities, { id: coursePackage.id })
                    }
                  />
                </td>
                <td>
                  <Highlighter
                    highlightClassName='text-highlight'
                    className='fw-normal text-primary cursor-pointer'
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={coursePackage.code}
                  />
                </td>
                <td>
                  <Highlighter
                    highlightClassName='text-highlight'
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={coursePackage.name}
                  />
                </td>
                <td>
                  <UncontrolledTooltip
                    placement='top'
                    target={`packageDescription${coursePackage.id}`}
                    style={{ minWidth: 500 }}
                    autohide={false}
                  >
                    {coursePackage.description}
                  </UncontrolledTooltip>
                  <Highlighter
                    highlightClassName='text-highlight'
                    id={`packageDescription${coursePackage.id}`}
                    className='text-truncate-two-lines course-description text-muted'
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={coursePackage.description ?? ''}
                  />
                </td>
                <td>
                  <span className='badge badge-soft-grey fs-12 fw-normal text-capitalize'>
                    {coursePackage.publishedDate
                      ? moment(coursePackage.publishedDate).format('MM/DD/YYYY')
                      : '-'}
                  </span>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <span className='badge badge-soft-grey fs-12 fw-normal'>
                      {coursePackage.coursesCount}
                    </span>
                  </div>
                </td>
                <td>
                  <span
                    className={`badge badge-soft-${
                      BadgeColorByStatus[
                        coursePackage.isDraft
                          ? CoursePackageStatus.DRAFT
                          : CoursePackageStatus.PUBLISHED
                      ]
                    } fs-12 fw-normal text-capitalize`}
                  >
                    {getCoursePackageStatus(coursePackage)}
                  </span>
                </td>
                <td>
                  <div className='d-flex justify-content-between'>
                    <span
                      className='d-flex gap-2 text-muted justify-content-end'
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <UncontrolledTooltip
                        placement='top'
                        target={`editPackage${coursePackage.id}`}
                      >
                        Edit
                      </UncontrolledTooltip>
                      <Link to={`/courses/packages/manage/${coursePackage.id}`}>
                        <i
                          className='ri-pencil-line cursor-pointer text-muted'
                          id={`editPackage${coursePackage.id}`}
                        ></i>
                      </Link>
                      {permissions.clone && (
                        <>
                          <UncontrolledTooltip
                            placement='top'
                            target={`duplicatePackage${coursePackage.id}`}
                          >
                            Duplicate
                          </UncontrolledTooltip>
                          <i
                            className='bx bx-duplicate cursor-pointer'
                            id={`duplicatePackage${coursePackage.id}`}
                            onClick={() => {
                              handleDuplicate(coursePackage)
                            }}
                          ></i>
                        </>
                      )}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default PackagesTableView
