import { TInstructor } from './instructor'
import { TLanguage } from './language'
import { TAttachment } from './attachments'
import { TCoursePackage } from './coursePackage'
import { COURSE_TYPES } from '../../sharedTypes'

export enum ONLINE_COURSE_STATUS {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  INACTIVE = 'inactive',
}

export enum OnlineCourseFormatsEnum {
  DIGITAL = 'Digital',
  LIVE = 'Live',
  COMPETENCY = 'Competency Only',
  KNOWLEDGE_CHECK = 'Knowledge Check',
}

export enum COURSE_FILTER_FORMATS {
  DIGITAL = 'Digital',
  WEBINAR = 'Webinar',
  IN_PERSON = 'In Person',
  COMPETENCY_ONLY = 'Competency Only',
}

export const CourseFiltersFormatsOptions = Object.keys(
  COURSE_FILTER_FORMATS,
).map(key => ({
  value: COURSE_FILTER_FORMATS[key as keyof typeof COURSE_FILTER_FORMATS],
  label: COURSE_FILTER_FORMATS[key as keyof typeof COURSE_FILTER_FORMATS],
}))

export enum ViewResultReportEnum {
  NO = 'no',
  AFTER_PASSING = 'after_passing',
  ALWAYS = 'always',
  NEVER = 'never',
}

export type TOnlineCourseCategory = {
  id: number
  name: string
  description: string
}

export type TOnlineCourseTranslationItem = {
  id?: number
  languageId: number
  language: TLanguage
  content: {
    name: string
    description: string
    objective: string
    notes: string
    resources: string
  }
}

export type TCourseRawTranslation = {
  id?: number
  languageId: number
  language: TLanguage
  content: {
    name: {
      value: string
      approved: boolean
    }
    description: {
      value: string
      approved: boolean
    }
    objective: {
      value: string
      approved: boolean
    }
    resources: {
      value: string
      approved: boolean
    }
    questions: {
      [key: string]: {
        question: string
        approved: boolean
        answers?: { answer: string }[]
      }
    }
  }
}

export interface DigitalCourse {
  id: number
  articulateType: 'rise' | 'storyline' | null
  articulateId: string | null
  quizAttempts: number
  completeDays: number
  file: {
    name: string
    size: number
    path: string
  }
  courseId: number
}

export interface CompetencyTestMessage {
  id: number
  languageId: number
  language: TLanguage
  content: {
    success: string
    fail: string
    retake: string
  }
}

export interface CompetencyTest {
  id: number
  courseId: number
  quizAttempts: number
  quizQuestionsNumber: number
  randomizeQuestions: boolean
  viewResultReport: ViewResultReportEnum
  messages: CompetencyTestMessage[]
  questions: {
    id: number
  }[]
}

export interface LiveCourseMedia {
  id: number
  languageId: number
  language: TLanguage
  liveCourseId: number
  attachmentId: number
  attachment: TAttachment
}

export interface LiveCourseSettings {
  id: number
  courseId: number
  course: OnlineCourseItem
  media: LiveCourseMedia[]
}

export interface OnlineCourseItem {
  id: number
  revisionId: number | null
  level: string
  code: string
  category: TOnlineCourseCategory | null
  status: ONLINE_COURSE_STATUS
  type: COURSE_TYPES
  duration: number
  effectiveDate: string | undefined
  expirationDate: string | undefined
  feedbackSurvey: boolean
  feedbackAnonymous: boolean
  certificateRequired: boolean
  categoryId: number
  deletable: boolean
  instructorId: number
  companyId: number
  tags: number[]
  states: string[]
  createdAt: string
  updatedAt: string
  formats: OnlineCourseFormatsEnum[]
  available_formats: COURSE_FILTER_FORMATS[]
  instructor: TInstructor | null
  translations: TOnlineCourseTranslationItem[]
  cover?: TAttachment
  coverId: number | null
  liveCourse?: LiveCourseSettings
  digital: DigitalCourse | null
  competencyTest: CompetencyTest
  publishedDate: string | null
  hasFeedback?: boolean
  package?: TCoursePackage
  positionIds: number[]
  positions?: string[]
  originalCourse?: Pick<OnlineCourseItem, 'id' | 'code' | 'formats'>
}

export enum CoursesTabs {
  COURSES = 'courses',
  PACKAGES = 'packages',
  TRAINING_DAYS = 'trainingDays',
}
