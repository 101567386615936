import { createSlice } from '@reduxjs/toolkit'
import { PermissionSet, TUser } from '../../../sharedTypes'

export const initialState: UserState = {
  user: null,
  accessToken: null,
  permissions: null,
}

const loginSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }: { payload: UserState['user'] }) {
      state.user = payload
    },
    setLoginUser(state, { payload }: { payload: UserState }) {
      state.user = payload.user
      state.accessToken = payload.accessToken
      state.permissions = payload.permissions
    },
    clearUser() {
      return initialState
    },
    setToken(state, { payload }: { payload: UserState['accessToken'] }) {
      state.accessToken = payload
    },
    setPermissions(state, { payload }: { payload: UserState['permissions'] }) {
      state.permissions = payload
    },
  },
})

export const { setUser, setLoginUser, clearUser, setPermissions } =
  loginSlice.actions

export type UserState = {
  user: TUser | null
  accessToken: string | null
  permissions: PermissionSet | null
}

export default loginSlice.reducer
