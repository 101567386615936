import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { useNavigate } from 'react-router-dom'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { GetSurveysDTO, SurveysSortBy } from '../../../sharedTypes'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import Highlighter from 'react-highlight-words'
import { getStatusColor } from '../../../helpers/common'
import moment from 'moment'
import { Pagination } from '../../../Components/Common/Pagination'
import {
  CommunicationPermissions,
  OrderType,
  SurveyStatusTypes,
  TSurvey,
} from '../../../sharedTypes'
import {
  deleteSurvey,
  duplicateSurvey,
  getSurveys,
  sendSurvey,
} from '../../../helpers/api/surveys'
import FilterTabs from './Tabs'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { toast } from 'react-toastify'
import {
  errorToastOptions,
  handleError,
  successToastOptions,
} from '../../../helpers/toast_helper'
import { getAveragePercent } from '../../../utils/users'
import { usePermissions } from '../../../hooks/usePermissions'
import {
  SurveyActionDropDown,
  ISurveyPermissions,
} from '../../../Components/ActionDropDown'
import SendSurveyModal from '../../../Components/Modals/SendSurveyModal'

const Columns = [
  {
    name: 'Name',
    sortBy: SurveysSortBy.NAME,
  },
  {
    name: 'Description',
    sortBy: SurveysSortBy.DESCRIPTION,
    style: { width: 200 },
  },
  {
    name: 'Recipients',
    sortBy: SurveysSortBy.RECIPIENTS,
  },
  {
    name: 'Completed',
    sortBy: SurveysSortBy.COMPLETED,
  },
  {
    name: 'Date sent',
    sortBy: SurveysSortBy.SEND_DATE,
  },
  {
    name: 'Deadline',
    sortBy: SurveysSortBy.DEADLINE,
  },
  {
    name: 'Status',
    sortBy: SurveysSortBy.STATUS,
  },

  {
    name: 'Actions',
    style: { width: 135, textAlign: 'center' } as any,
  },
]

interface SelectedSurvey {
  item: TSurvey | null
  type: 'delete' | 'send'
}

const Surveys = () => {
  document.title = 'Surveys | Mastered - Admin & Dashboard'

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedSurvey, setSelectedSurvey] = useState<SelectedSurvey>({
    item: null,
    type: 'delete',
  })

  const [query, setQuery] = useState<GetSurveysDTO.Request>({
    page: 1,
    status: undefined,
    limit: 10,
  })
  const [data, setData] = useState<GetSurveysDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    surveys: [],
  })

  const permissions: ISurveyPermissions = {
    add: usePermissions(CommunicationPermissions.CREATE_CLONE_SURVEY),
    edit: usePermissions(CommunicationPermissions.EDIT_SURVEY),
    view: usePermissions(CommunicationPermissions.VIEW_SURVEYS),
    send: usePermissions(CommunicationPermissions.SEND_SCHEDULE_SURVEY),
    delete: usePermissions(CommunicationPermissions.DELETE_SURVEY),
  }

  const hasPermissions = useMemo(
    () => Object.values(permissions).some(value => value),
    [],
  )

  useEffect(() => {
    setIsLoading(true)
    getSurveys(query)
      .then(setData)
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  const handleSort = useCallback((column: SurveysSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const isSurveySent = (status: SurveyStatusTypes) => {
    return [SurveyStatusTypes.SENT, SurveyStatusTypes.COMPLETED].includes(
      status,
    )
  }

  const onDelete = useCallback(async () => {
    try {
      await deleteSurvey(selectedSurvey.item?.id as number)
      setQuery(prev => ({ ...prev, page: 1 }))
      setSelectedSurvey(prev => ({ ...prev, item: null }))
      toast('Success - Survey successfully deleted', successToastOptions)
    } catch (e) {
      toast('Something went wrong', errorToastOptions)
    }
  }, [data.page, selectedSurvey])

  const handleDuplicate = async (id: number) => {
    try {
      const data = await duplicateSurvey(id)
      setQuery(prev => ({ ...prev, page: 1 }))
      navigate(`/surveys/manage/${data.id}`)

      toast('Success - Survey has been duplicated!', successToastOptions)
    } catch (e) {
      toast('Something went wrong', errorToastOptions)
    }
  }

  const columns = useMemo(() => {
    if (Object.values(permissions).every(value => !value)) {
      return Columns.filter(column => column.name !== 'Actions')
    }

    return Columns
  }, [permissions])

  const onResetData = useCallback((values: Partial<GetSurveysDTO.Request>) => {
    setQuery(prev => ({ ...prev, ...values, page: 1 }))
  }, [])

  const handleRowClick = (survey: TSurvey) => {
    if (
      permissions.edit &&
      ![SurveyStatusTypes.SENT, SurveyStatusTypes.COMPLETED].includes(
        survey.status,
      )
    ) {
      navigate(`/surveys/manage/${survey.id}`)
    }
  }

  const onSend = async (values: any, callBack: () => void) => {
    try {
      if (
        permissions.send &&
        selectedSurvey.item &&
        selectedSurvey.item.status === SurveyStatusTypes.SCHEDULED
      ) {
        const sentSurvey = await sendSurvey(selectedSurvey.item.id, values)
        setSelectedSurvey(prev => ({ ...prev, item: null }))

        const updatedSurveys = data.surveys.map(survey => {
          if (survey.id === sentSurvey.id) {
            return sentSurvey
          }
          return survey
        })
        setData(prevData => ({
          ...prevData,
          surveys: updatedSurveys,
        }))

        toast('Success - Survey has been sent!', successToastOptions)
      }
    } catch (e) {
      callBack()
      handleError(e)
    }
  }

  return (
    <div className='page-content surveys-list'>
      <Container fluid>
        <BreadCrumb
          title='Surveys'
          items={[
            {
              title: 'Surveys',
              active: true,
            },
          ]}
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className='d-flex justify-content-between align-items-center gap-3'>
                  <SearchInput
                    style={{ maxWidth: 400 }}
                    onChange={key => {
                      onResetData({ key })
                    }}
                    value={query.key}
                  />
                  <FilterTabs
                    navTab={query.status}
                    navToggle={status => {
                      onResetData({ status })
                    }}
                  />
                  {permissions.add && (
                    <Button
                      color={'primary'}
                      onClick={() => navigate('/surveys/manage')}
                      className='btn btn-primary align-middle d-flex align-items-center'
                    >
                      <i className='ri-add-line me-1 fs-16'></i>Add New
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card className='m-0 p-0 table-card'>
          <NoResultTableWrapper
            isLoading={isLoading}
            isFiltering={false}
            pages={data.pages}
            className={'py-0'}
          >
            <div className='table-card mt-0'>
              <div className='overflow-x-auto'>
                <Table className='align-middle table-nowrap mb-0 surveys-table'>
                  <thead className='table-light'>
                    <tr className='text-muted fs-14'>
                      {columns.map(column => (
                        <th
                          scope='col'
                          className='align-middle'
                          style={column.style ?? {}}
                          key={column.name}
                        >
                          {column.name}
                          {!!column.sortBy && (
                            <ColumnSortIcon<SurveysSortBy>
                              sortOrder={query.orderBy}
                              sortedColumn={query.sortBy}
                              column={column.sortBy}
                              handleSort={handleSort}
                            />
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.surveys.map((survey, i) => (
                      <tr
                        key={i}
                        className='fs-14'
                        onClick={() => handleRowClick(survey)}
                      >
                        <td>
                          <Highlighter
                            highlightClassName='text-highlight'
                            searchWords={[query.key || '']}
                            highlightTag={'span'}
                            autoEscape={true}
                            textToHighlight={
                              survey.translations[0].content.name
                            }
                          />
                        </td>
                        <td>
                          <Highlighter
                            highlightClassName='text-highlight'
                            searchWords={[query.key || '']}
                            highlightTag={'span'}
                            autoEscape={true}
                            textToHighlight={
                              survey.translations[0].content.description
                            }
                            className='message'
                          />
                        </td>
                        <td>
                          <span>{survey.recipientsCount || '-'}</span>
                        </td>
                        <td>
                          <span>
                            {isSurveySent(survey.status)
                              ? `${survey.completedUsersCount}
                          (${getAveragePercent(
                            survey.completedUsersCount || 0,
                            survey.recipientsCount || 0,
                          )}%)`
                              : '-'}
                          </span>
                        </td>
                        <td>
                          {isSurveySent(survey.status)
                            ? moment(survey.updatedAt).format(
                                'MM/DD/YYYY, HH:mm',
                              )
                            : '-'}
                        </td>
                        <td>
                          {survey.deadline
                            ? moment(survey.deadline, 'YYYY-MM-DD').format(
                                'MM/DD/YYYY',
                              )
                            : '-'}
                        </td>
                        <td>
                          <span
                            className={`${getStatusColor(
                              survey.status,
                            )} badge text-capitalize`}
                          >
                            {survey.status}
                          </span>
                        </td>
                        {hasPermissions && (
                          <td>
                            <div className='d-flex justify-content-center'>
                              <span className='d-flex gap-2 text-muted justify-content-end'>
                                <SurveyActionDropDown
                                  survey={survey}
                                  permissions={permissions}
                                  onDelete={() => {
                                    setSelectedSurvey({
                                      item: survey,
                                      type: 'delete',
                                    })
                                  }}
                                  onDuplicate={() => {
                                    handleDuplicate(survey.id)
                                  }}
                                  onEdit={() => {
                                    navigate(`/surveys/manage/${survey.id}`)
                                  }}
                                  onSend={() => {
                                    setSelectedSurvey({
                                      item: survey,
                                      type: 'send',
                                    })
                                  }}
                                  onView={() => {
                                    navigate(`/surveys/view/${survey.id}`)
                                  }}
                                />
                              </span>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className='my-3 pt-3'>
              <Pagination
                currentPage={data.page - 1}
                totalPages={data.pages}
                totalRecords={data.count}
                setPage={page => {
                  setQuery(prev => ({ ...prev, page: ++page }))
                }}
              />
            </div>
          </NoResultTableWrapper>
        </Card>

        <DeleteConfirmation
          isOpen={!!selectedSurvey.item && selectedSurvey.type === 'delete'}
          title='Delete survey?'
          message={`Are you sure you want to delete "${selectedSurvey.item?.translations[0].content.name}" survey?`}
          onDelete={onDelete}
          onClose={() => {
            setSelectedSurvey(prev => ({ ...prev, item: null }))
          }}
        />

        {permissions.send && (
          <SendSurveyModal
            isOpen={!!selectedSurvey.item && selectedSurvey.type === 'send'}
            translations={selectedSurvey.item?.translations || []}
            id={selectedSurvey.item?.id as number}
            onClose={() => {
              setSelectedSurvey(prev => ({ ...prev, item: null }))
            }}
            onSend={onSend}
          />
        )}
      </Container>
    </div>
  )
}

export default Surveys
