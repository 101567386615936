import React from 'react'

import FilterTabs, { FilterTabsProps } from '../Common/FilterTabs'
import { CoursesTabs } from '../../sharedTypes'

const tabs = [
  { label: 'Courses', value: CoursesTabs.COURSES },
  { label: 'Packages', value: CoursesTabs.PACKAGES },
  { label: 'Training Days', value: CoursesTabs.TRAINING_DAYS },
]

interface CoursesTabsProps
  extends Pick<FilterTabsProps<CoursesTabs>, 'extraTabData'> {
  activeTab: CoursesTabs
  onTabChange: (tab: CoursesTabs) => void
}

const CourseTabs = ({
  activeTab,
  onTabChange,
  extraTabData,
}: CoursesTabsProps) => (
  <FilterTabs<CoursesTabs>
    tabs={tabs}
    navTab={activeTab}
    navToggle={_activeTab => {
      if (_activeTab && _activeTab !== activeTab) {
        onTabChange(_activeTab)
      }
    }}
    extraTabData={extraTabData}
  />
)

export default CourseTabs
