import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, FormFeedback, Input, Row } from 'reactstrap'
import {
  CoursePackageFormatsEnum,
  TCoursePackage,
} from '../../../sharedTypes/models/coursePackage'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { useFormik } from 'formik'
import { defaultCoursePackageSchema } from '../../../schemas'
import { IForm } from './GeneralCoursePackage'
import {
  changePackageFormat,
  deleteCoursePackage,
  duplicatePackage,
  updatePackage,
} from '../../../helpers/api/coursePackages'
import { toast } from 'react-toastify'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import { useNavigate } from 'react-router-dom'
import { formats } from './types'
import ChangeConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { usePermissions } from '../../../hooks/usePermissions'
import { CoursesPermissions } from '../../../sharedTypes'

export interface TabsHeaderProps {
  coursePackage: TCoursePackage | null
  coursesCount: number
  fetchCoursePackage: () => void
}

export const PackageHeader = ({
  coursePackage,
  coursesCount,
  fetchCoursePackage,
}: TabsHeaderProps) => {
  const [deletePackage, setDeletePackage] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [changeFormatConfirmation, setChangeFormatConfirmation] = useState<
    number | null
  >(null)

  const navigate = useNavigate()

  const permissions = {
    edit: usePermissions(CoursesPermissions.EDIT_PACKAGE),
    clone: usePermissions(CoursesPermissions.ADD_CLONE_PACKAGE),
    delete: usePermissions(CoursesPermissions.DELETE_PACKAGE),
  }

  const onSubmit = async () => {
    try {
      if (coursePackage) {
        await updatePackage(+coursePackage.id, form.values)
        setEditMode(false)
      }
    } catch (e) {
      handleError(e)
    }
  }

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      format: CoursePackageFormatsEnum.PACKAGE,
    },
    validationSchema: defaultCoursePackageSchema,
    onSubmit,
  })

  useEffect(() => {
    if (coursePackage) {
      form.setValues({
        name: coursePackage.name,
        description: coursePackage.description || '',
        format: coursePackage.format,
      })
    }
  }, [coursePackage])

  const changedFormat = useMemo(() => {
    if (
      coursePackage &&
      coursePackage.format === CoursePackageFormatsEnum.PACKAGE
    ) {
      return formats[CoursePackageFormatsEnum.TRAINING_DAY]
    } else {
      return formats[CoursePackageFormatsEnum.PACKAGE]
    }
  }, [coursePackage?.format])

  const changeFormatMessage = useMemo(() => {
    return (
      <div>
        {`Are you sure you want to change to ${changedFormat}?`}
        <div>
          {changedFormat === CoursePackageFormatsEnum.PACKAGE
            ? 'All digital courses will be lost.'
            : 'All schedules will be lost.'}
        </div>
      </div>
    )
  }, [changedFormat])

  const onDelete = useCallback(async () => {
    try {
      if (coursePackage) {
        await deleteCoursePackage(+coursePackage.id)
        toast(
          `${coursePackage.name} has been successfully deleted!`,
          successToastOptions,
        )
        navigate('/courses') // TODO change after list is ready
      }
    } catch (e) {
      handleError(e)
    }
  }, [coursePackage?.id])

  const handleDuplicate = async (id: number) => {
    if (coursePackage) {
      try {
        const data = await duplicatePackage(id)
        navigate(`/courses/packages/manage/${data.id}`)

        toast(
          `Package ${coursePackage.name} has been successfully cloned!`,
          successToastOptions,
        )
      } catch (e) {
        handleError(e)
      }
    }
  }

  const handleChangePackageFormat = async () => {
    if (coursePackage && changeFormatConfirmation) {
      try {
        await changePackageFormat(changeFormatConfirmation)
        setChangeFormatConfirmation(null)
        fetchCoursePackage()
      } catch (e) {
        handleError(e)
      }
    }
  }

  return (
    <>
      <Row className='nav justify-content-between m-2 nav-tabs-custom rounded card-header-tabs border-bottom-1'>
        <Col sm={editMode ? '12' : '7'}>
          {!editMode ? (
            <>
              <div className='d-flex gap-3'>
                <p className={'fs-17 fw-semibold mb-0'}>{form.values.name}</p>
                <span className='badge badge-soft-primary fs-13'>
                  {' '}
                  {formats[form.values.format]}
                </span>
                <span className='badge badge-soft-grey fs-13 fw-normal'>
                  {' '}
                  {coursesCount} Course{coursesCount > 1 ? 's' : ''}
                </span>
              </div>

              <p className='fs-16 text-muted fw-light'>
                {form.values.description}
              </p>
            </>
          ) : (
            <Row className='mb-2'>
              <Col sm={4}>
                <label htmlFor='title' className='form-label'>
                  Name
                </label>
                <Input
                  className='form-control'
                  id='title'
                  name={`name`}
                  placeholder='Name this package'
                  type='text'
                  invalid={!!form.errors.name}
                  value={form.values.name}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                {form.touched.name && form.errors.name ? (
                  <FormFeedback type='invalid'>{form.errors.name}</FormFeedback>
                ) : null}
              </Col>
              <Col sm={7}>
                <label htmlFor='title' className='form-label'>
                  Description
                </label>
                <Input
                  className='form-control'
                  id='title'
                  name={`description`}
                  type='text'
                  invalid={!!form.errors.description}
                  value={form.values.description}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                {form.touched.description && form.errors.description ? (
                  <FormFeedback type='invalid'>
                    {form.errors.description}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col className='d-flex align-items-end justify-content-end'>
                <Button color='primary' onClick={() => form.handleSubmit()}>
                  Save
                </Button>
              </Col>
            </Row>
          )}
        </Col>
        {!editMode && coursePackage && (
          <Col>
            <div className='d-flex gap-4 justify-content-end'>
              {permissions.edit && (
                <span
                  className={`${
                    coursePackage.isDraft
                      ? 'text-primary cursor-pointer'
                      : 'text-muted'
                  } gap-2 d-flex`}
                  onClick={
                    coursePackage.isDraft ? () => setEditMode(true) : undefined
                  }
                >
                  <i className='ri-edit-box-line'></i>
                  <b>Edit General Info</b>
                </span>
              )}

              {/*<span*/}
              {/*  className={`${*/}
              {/*    coursePackage.isDraft*/}
              {/*      ? 'text-primary cursor-pointer'*/}
              {/*      : 'text-muted'*/}
              {/*  } gap-2 d-flex`}*/}
              {/*  onClick={*/}
              {/*    coursePackage.isDraft*/}
              {/*      ? () => {*/}
              {/*          setChangeFormatConfirmation(coursePackage.id)*/}
              {/*        }*/}
              {/*      : undefined*/}
              {/*  }*/}
              {/*>*/}
              {/*  <i className='bx bx-transfer'></i>*/}
              {/*  <b>*/}
              {/*    Change to{' '}*/}
              {/*    {form.values.format === CoursePackageFormatsEnum.PACKAGE*/}
              {/*      ? formats[CoursePackageFormatsEnum.TRAINING_DAY]*/}
              {/*      : formats[CoursePackageFormatsEnum.PACKAGE]}*/}
              {/*  </b>*/}
              {/*</span>*/}
              {permissions.clone && (
                <span
                  className='text-primary cursor-pointer gap-2 d-flex'
                  onClick={() => {
                    handleDuplicate(coursePackage.id)
                  }}
                >
                  <i className='bx bx-duplicate cursor-pointer'></i>
                  <b>Clone</b>
                </span>
              )}
              {permissions.delete && (
                <span
                  className={`${
                    coursePackage.isAssigned
                      ? 'pe-none text-muted'
                      : 'text-danger cursor-pointer '
                  } gap-2 d-flex`}
                  onClick={() => {
                    setDeletePackage(true)
                  }}
                >
                  <i className='bx bx-trash'></i>
                  <b>Delete</b>
                </span>
              )}
            </div>
          </Col>
        )}
      </Row>
      {permissions.delete && (
        <DeleteConfirmation
          title='Delete Package'
          message='Are you sure you want to delete this package?'
          isOpen={deletePackage}
          onClose={() => setDeletePackage(false)}
          onDelete={onDelete}
        />
      )}

      <ChangeConfirmation
        isOpen={!!changeFormatConfirmation}
        confirmLabel={'Change'}
        confirmIcon={'bx bx-transfer text-danger'}
        title={`Change to ${changedFormat}`}
        message={changeFormatMessage}
        onDelete={handleChangePackageFormat}
        onClose={() => {
          setChangeFormatConfirmation(null)
        }}
      />
    </>
  )
}
