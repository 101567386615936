import React, { Fragment, useEffect, useState } from 'react'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import { getCourseReportById } from '../../../../helpers/api/courses'
import { TCourseReport } from '../../../../sharedTypes/models/courseReport'
import { handleError } from '../../../../helpers/toast_helper'
import BreadCrumb from '../../../../Components/Common/BreadCrumb'
import { Col, Container, Row } from 'reactstrap'
import Feedback from './Feedback'
import HeaderInfo from './HeaderInfo'

const CourseReportItem = ({ router }: WithRouterProps) => {
  const [courseReport, setCourseReport] = useState<TCourseReport | null>(null)
  const [courseReportId, setCourseReportId] = useState<number>()

  useEffect(() => {
    if (
      !router.params.reportId ||
      Number.isNaN(+router.params.reportId) ||
      typeof +router.params.reportId !== 'number'
    ) {
      return router.navigate('/404')
    }

    setCourseReportId(+router.params.reportId)
  }, [router.params.reportId])

  useEffect(() => {
    if (courseReportId) {
      getCourseReportById(courseReportId)
        .then(res => {
          setCourseReport(res)
        })
        .catch(handleError)
    }
  }, [courseReportId])

  if (!courseReport) {
    return <></>
  }

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={'Course Report'}
            items={[
              {
                linkTo: '/courses/report',
                title: 'Course Reports',
              },
              {
                active: true,
                title: 'Course Feedback',
              },
            ]}
          />
          <Row>
            <Col>
              <HeaderInfo
                courseReport={courseReport}
                courseReportId={courseReportId}
                setCourseReportId={setCourseReportId}
              />

              <Feedback
                courseId={courseReport.courseId}
                courseFormat={courseReport.courseFormat}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default withRouter(CourseReportItem)
