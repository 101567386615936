import React from 'react'
import { Routes, Route } from 'react-router-dom'

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout'
import VerticalLayout from '../Layouts/index'
import { CourseContextProvider } from '../context/CourseContext'

//routes
import {
  authProtectedRoutes,
  publicRoutes,
  courseStepsRoutes,
} from './allRoutes'
import { AuthProtected } from './AuthProtected'

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <NonAuthLayout
                  forceLogin={
                    typeof route.forceLogin === 'boolean'
                      ? route.forceLogin
                      : true
                  }
                >
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
              // exact={true}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected permission={route.permission}>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </AuthProtected>
              }
              key={idx}
              // exact={true}
            />
          ))}
        </Route>
        <Route element={<CourseContextProvider />}>
          {courseStepsRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected permission={route.permission}>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </AuthProtected>
              }
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  )
}

export default Index
