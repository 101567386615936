//USERS
import {
  ChangeUserPasswordDTO,
  GetAnalyticsForLast30DaysDTO,
  GetAnnualEducationOverdueDTO,
  GetEditRequestFieldsDto,
  GetUsersCourseStatusAnalyticsDTO,
  GetUsersDTO,
  GetUserStatusAnalyticsDTO,
  PatchUserRoleUpdateDTO,
  PostEditRequestFieldsDto,
  TUser,
  UpdateUserCredentialDto,
} from '../../sharedTypes'
import { AdminNotificationSettings } from '../../sharedTypes/models'
import {
  ANALYTICS_FOR_LAST_30_DAYS,
  GET_EDIT_REQUEST,
  GET_ME,
  GET_USERS,
  POST_EDIT_REQUEST,
  PUT_CHANGE_PASSWORD,
  USER_COURSE_STATUS_ANALYTICS,
  USER_REPORT,
  USER_STATUS_ANALYTICS,
  MY_NOTIFICATION_SETTINGS,
  USERS_WITH_COURSE_OVERDUE,
  UPDATE_USER_CREDENTIAL,
  DELETE_USER_CREDENTIAL,
} from '../url_helper'
import { API } from '../api_helper'
import { GetUserReportDTO } from '../../sharedTypes/api/userReport'

export const getUsers = (params: GetUsersDTO.Request) => {
  return API.get<GetUsersDTO.Response>(GET_USERS, { params })
}
export const getUserById = (userId: number) => {
  return API.get<TUser>(GET_USERS + `/${userId}`)
}
export const deactivateUserById = (userId: number) => {
  return API.delete<TUser>(GET_USERS + `/${userId}`)
}

export const getRequestEditFields = () => {
  return API.get<GetEditRequestFieldsDto.Response['fields']>(
    GET_EDIT_REQUEST,
  ).then(d => d.data)
}

export const getRequestEditFieldsById = (userId: number) => {
  return API.get<GetEditRequestFieldsDto.Response['fields']>(
    GET_EDIT_REQUEST + `/${userId}`,
  ).then(d => d.data)
}

export const postRequestEditFields = (
  data: PostEditRequestFieldsDto.Request,
  userId: number | undefined,
) => {
  const requestData: {
    fields: [PostEditRequestFieldsDto.Request]
    userId?: number | undefined
  } = { fields: [data] }
  if (userId) {
    requestData.userId = userId
  }
  return API.post(POST_EDIT_REQUEST, requestData).then(d => d.data)
}

export const changePassword = (data: ChangeUserPasswordDTO.Request) => {
  return API.put<ChangeUserPasswordDTO.Response>(PUT_CHANGE_PASSWORD, data)
}

export const getMe = () => {
  return API.get<TUser>(GET_ME).then(d => d.data)
}

export const putMyNotificationSettings = (data: AdminNotificationSettings) => {
  return API.put<AdminNotificationSettings>(
    MY_NOTIFICATION_SETTINGS,
    data,
  ).then(d => d.data)
}

export const getMyNotificationSettings = () => {
  return API.get<AdminNotificationSettings>(MY_NOTIFICATION_SETTINGS).then(
    d => d.data,
  )
}

export const updateUserRole = (
  userId: number,
  data: PatchUserRoleUpdateDTO.Request,
) => {
  return API.patch<PatchUserRoleUpdateDTO.Response>(
    GET_USERS + `/${userId}/role`,
    data,
  )
}

export const getUserReport = (params: GetUserReportDTO.Request) => {
  return API.get(USER_REPORT, {
    params,
  })
}

export const getUserCourseStatusAnalytics = (
  params: GetUsersCourseStatusAnalyticsDTO.Request,
) => {
  return API.get<GetUsersCourseStatusAnalyticsDTO.Response>(
    USER_COURSE_STATUS_ANALYTICS,
    {
      params,
    },
  ).then(d => d.data)
}

export const getUserStatusAnalytics = (
  params: GetUserStatusAnalyticsDTO.Request,
) => {
  return API.get<GetUserStatusAnalyticsDTO.Response>(USER_STATUS_ANALYTICS, {
    params,
  }).then(d => d.data)
}

export const getAnalyticsForLast30Days = (
  params: GetAnalyticsForLast30DaysDTO.Request,
) => {
  return API.get<GetAnalyticsForLast30DaysDTO.Response>(
    ANALYTICS_FOR_LAST_30_DAYS,
    {
      params,
    },
  ).then(d => d.data)
}

export const getAnnualEducationOverdue = (
  params: GetAnnualEducationOverdueDTO.Request,
) => {
  return API.get<GetAnnualEducationOverdueDTO.Response>(
    USERS_WITH_COURSE_OVERDUE,
    {
      params,
    },
  )
}

export const updateCredential = (
  data: UpdateUserCredentialDto.Request,
  userId: number,
) => {
  return API.post<UpdateUserCredentialDto.Response>(
    UPDATE_USER_CREDENTIAL + `/${userId}`,
    data,
  ).then(d => d.data)
}

export const removeCredential = (credentialId: number) => {
  return API.delete(DELETE_USER_CREDENTIAL + `/${credentialId}`)
}
