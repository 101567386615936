import { TLanguage } from './language'
import { TAttachment } from './attachments'

export type TCompanyNews = {
  id: number
  status: NewsStatusTypes
  isDraft: boolean
  facilityIds: number[] | null
  groupIds: number[] | null
  facilityNames?: string | null
  groupNames: string | null
  sendDate: Date | null
  publishedDate: Date | null
  newsMedia: TAttachment | null
  newsMediaId: number | null
  newsCover: TAttachment | null
  createdAt: string
  updatedAt: string
  translations: TCompanyNewsTranslations[]
}

export type TCompanyNewsTranslations = {
  id?: number
  title: string
  content: string
  companyNewsId?: number
  languageId?: number
  createdAt?: string
  updatedAt?: string
  language?: TLanguage
}

export type TCompanyNewsTranslationItem = {
  id?: number
  title: string
  content: string
  languageId: number
}

export enum NewsStatusTypes {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  PUBLISHED = 'published',
}
