import { CREDENTIALS_TYPE, TInstructor, TUser } from '../../sharedTypes'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { getUserDisplayName } from '../../helpers/user'

interface UserInfoProps {
  user?: TUser | TInstructor | null
  globalSearch?: string
}

const UserCell: React.FC<UserInfoProps> = ({ user, globalSearch }) => {
  if (!user) {
    return <td></td>
  }
  const email =
    user.credentials?.find(i => i.credentialType === CREDENTIALS_TYPE.EMAIL)
      ?.credential || ''
  return (
    <td>
      <div className='d-flex align-items-center'>
        <div className='flex-shrink-0'>
          {user.photo ? (
            <img src={user.photo} alt='' className='avatar-xs rounded-circle' />
          ) : (
            <div className='avatar-xs'>
              <div className='avatar-title rounded-circle bg-soft-primary text-primary'>
                {user.firstName[0]} {user.lastName[0]}
              </div>
            </div>
          )}
        </div>

        <div className='flex-grow-1 ms-2'>
          <p className='card-title mb-0 fs-14'>
            <Highlighter
              highlightClassName='text-highlight'
              searchWords={[globalSearch || '']}
              highlightTag={'span'}
              autoEscape={true}
              textToHighlight={getUserDisplayName(user) || ''}
            />
          </p>
          <p className='text-muted mb-0 fs-12'>
            <Highlighter
              highlightClassName='text-highlight'
              searchWords={[globalSearch || '']}
              highlightTag={'span'}
              autoEscape={true}
              textToHighlight={
                user.isInstructor ? user.qualification.join(', ') : email
              }
            />
          </p>
        </div>
      </div>
    </td>
  )
}
export default UserCell
