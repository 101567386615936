import React, { useEffect, useState, useCallback } from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import {
  Card,
  Col,
  Form,
  Container,
  Input,
  Label,
  Row,
  CardBody,
  FormFeedback,
  CardHeader,
} from 'reactstrap'
import { useFormik } from 'formik'
import Slider from 'react-rangeslider'
import {
  getDefaultCourseSettings,
  putDefaultCourseSettings,
  getCertificateTemplates,
  getAdmins,
  getAllLanguages,
} from '../../../helpers/api_helper'
import {
  IDefaultCourseSettings,
  ICerticateTemplate,
  SettingsPermissions,
} from '../../../sharedTypes'
import { toast } from 'react-toastify'
import { SignatureUpload } from '../../../Components/Common/SignatureUpload'
import { successToastOptions } from '../../../helpers/toast_helper'
import { Button } from 'reactstrap'
import handleAcceptedFile from '../../../utils/handleAcceptedFile'
import * as Yup from 'yup'
import { usePermissions } from '../../../hooks/usePermissions'
import { AsyncSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import { UserManagementPermissions } from '../../../sharedTypes'
import { getUserDisplayName } from '../../../helpers/user'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import { ENGLISH_LANGUAGE_CODE } from '../../../helpers/common'

interface Option {
  value: number
  label: string | number
}

interface IForm extends Omit<IDefaultCourseSettings, 'signatoryId'> {
  signatoryId: null | Option | undefined
}

const initialValues = {
  completeDays: 0,
  quizQuestions: 0,
  quizAttempts: 100,
  passingScore: 100,
  watchPercentage: 100,
  enforceOrder: true,
  attestationStatement: true,
  attestationStatementText: '',
  certificateId: 7,
  signature: undefined,
  signatoryPosition: '',
  signatoryId: undefined,
  signatory: undefined,
  messages: {
    success: '',
    retake: '',
    fail: '',
  },
  languages: [],
}
const DefaultCourseSettings = ({ router }: WithRouterProps) => {
  document.title = 'Default Settings | Mastered - Admin & Dashboard'

  const [templates, setTemplates] = useState<ICerticateTemplate[]>([])
  const [isLoading, setLoading] = useState(false)
  const hasPermissionToSave = usePermissions(
    SettingsPermissions.EDIT_DEFAULT_COURSE_SETTINGS,
  )

  const fetchAdmins = (inputValue?: string): Promise<Option[]> => {
    const params = {
      page: 1,
      limit: 10,
      key: inputValue,
    }

    return getAdmins({
      ...params,
      permission: UserManagementPermissions.VIEW_ADMIN_LISTING,
    })
      .then(d => d.data)
      .then(({ admins }) => {
        return admins.map(admin => ({
          value: admin.id,
          label: getUserDisplayName(admin),
        }))
      })
      .catch(() => [])
  }

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values: IForm, formik) => {
      try {
        const form = new FormData()
        form.append('completeDays', String(values.completeDays))
        form.append('quizQuestions', String(values.quizQuestions))
        form.append('quizAttempts', String(values.quizAttempts))
        form.append('passingScore', String(values.passingScore))
        form.append('watchPercentage', String(values.watchPercentage))
        form.append('enforceOrder', String(values.enforceOrder))
        form.append('signatoryPosition', String(values.signatoryPosition))
        form.append('attestationStatement', String(values.attestationStatement))
        form.append(
          'attestationStatementText',
          String(values.attestationStatementText),
        )
        form.append('certificateId', String(values.certificateId))
        form.append('messages', JSON.stringify(values.messages))

        if (values.signatoryId) {
          form.append('signatoryId', String((values.signatoryId as any).value))
        }
        if (values.signature) {
          form.append('signature', values.signature as Blob)
        }
        if (values.certificateId) {
          form.append('certificateId', String(values.certificateId))
        }
        form.append('languages', JSON.stringify(values.languages))

        const response = await putDefaultCourseSettings(form)

        updateFormValues({ ...response, languages: values.languages })

        toast('Successfully updated', successToastOptions)
      } catch (e) {}
    },
    validationSchema: Yup.object({
      signature: Yup.mixed()
        .nullable()
        .required('Required')
        .test(
          'valid-size',
          'Max allowed size is 1mb',
          value => value?.size <= 1000000,
        ),
      signatoryPosition: Yup.string().nullable().required('Required'),
      completeDays: Yup.number().min(1).nullable().required('Required'),
      quizQuestions: Yup.number().min(1).nullable().required('Required'),
      quizAttempts: Yup.number().min(1).nullable().required('Required'),
      passingScore: Yup.number().min(0).nullable().required('Required'),
      watchPercentage: Yup.number().min(1).nullable().required('Required'),
      signatoryId: Yup.mixed().required('Required'),
    }),
  })

  const updateFormValues = useCallback((settings: IDefaultCourseSettings) => {
    form.setValues({
      ...settings,
      signatoryId: settings.signatory
        ? {
            label: getUserDisplayName(settings.signatory),
            value: settings.signatory.id,
          }
        : null,
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    Promise.all([
      getDefaultCourseSettings({
        permission: SettingsPermissions.VIEW_DEFAULT_COURSE_SETTINGS,
      }),
      getCertificateTemplates(),
      getAllLanguages(),
    ])
      .then(([settings, templates, languages]) => {
        updateFormValues({ ...settings, languages })
        setTemplates(templates)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='DEFAULT SETTINGS'
            items={[
              {
                title: 'Settings',
                linkTo: '/',
              },
              {
                title: 'Default Settings',
                active: true,
              },
            ]}
          />
          {isLoading ? (
            <Card className='m-0 h-100' style={{ display: 'block' }}>
              <CardBody>
                <div className='d-flex flex-column align-items-center py-5'>
                  <p style={{ fontSize: 20, fontWeight: 600 }}>Loading...</p>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Form
              onSubmit={e => {
                e.preventDefault()
                form.handleSubmit()
                return false
              }}
              action='#'
            >
              <Row className='gy-4 mb-3'>
                <Col xxl={6} md={6}>
                  <Card className='p-4'>
                    <CardHeader
                      className='border-bottom-0 p-0 pb-2 fs-20'
                      style={{ fontWeight: 700 }}
                    >
                      Course Settings
                    </CardHeader>
                    <Row className='gy-4 mb-3'>
                      <Col xxl={6} md={6} className='d-flex align-items-start'>
                        <div className='w-100'>
                          <Label htmlFor='basiInput' className='form-label'>
                            Number of days to complete course
                          </Label>
                          <Input
                            type='number'
                            className='form-control'
                            name='completeDays'
                            value={form.values.completeDays}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            id='basiInput'
                            invalid={
                              !!(
                                form.touched.completeDays &&
                                form.errors.completeDays
                              )
                            }
                          />
                          {form.touched.completeDays &&
                          form.errors.completeDays ? (
                            <FormFeedback type='invalid'>
                              {form.errors.completeDays}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={6} md={6} className='d-flex align-items-start'>
                        <div className='w-100'>
                          <Label htmlFor='basiInput' className='form-label'>
                            Number of Test Questions
                          </Label>
                          <Input
                            type='number'
                            className='form-control'
                            name='quizQuestions'
                            value={form.values.quizQuestions}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            id='basiInput'
                            invalid={
                              !!(
                                form.touched.quizQuestions &&
                                form.errors.quizQuestions
                              )
                            }
                          />
                          {form.touched.quizQuestions &&
                          form.errors.quizQuestions ? (
                            <FormFeedback type='invalid'>
                              {form.errors.quizQuestions}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={6} md={6} className='d-flex align-items-start'>
                        <div className='w-100'>
                          <Label htmlFor='basiInput' className='form-label'>
                            Test attempts before having to restart the course{' '}
                          </Label>
                          <Input
                            type='number'
                            className='form-control'
                            name='quizAttempts'
                            value={form.values.quizAttempts}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            id='basiInput'
                            invalid={
                              !!(
                                form.touched.quizAttempts &&
                                form.errors.quizAttempts
                              )
                            }
                          />
                          {form.touched.quizAttempts &&
                          form.errors.quizAttempts ? (
                            <FormFeedback type='invalid'>
                              {form.errors.quizAttempts}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row className='gy-4 mb-3'>
                      <Col xxl='6'>
                        <label htmlFor='bio' className='form-label mb-4'>
                          Required watch percentage
                        </label>
                        <Slider
                          value={form.values.watchPercentage}
                          tooltip={false}
                          orientation='horizontal'
                          handleLabel={String(form.values.watchPercentage)}
                          onChange={(value: number) => {
                            form.setFieldValue('watchPercentage', value)
                          }}
                        ></Slider>
                      </Col>
                      <Col xxl='6'>
                        <label htmlFor='bio' className='form-label mb-4'>
                          Passing scores for each course added
                        </label>
                        <Slider
                          value={form.values.passingScore}
                          tooltip={false}
                          orientation='horizontal'
                          handleLabel={String(form.values.passingScore)}
                          onChange={(value: number) => {
                            form.setFieldValue('passingScore', value)
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className='gy-4 mb-3'>
                      <Col xxl='12'>
                        <div>
                          <label htmlFor='bio' className='form-label'>
                            Enforce watch order
                          </label>
                          <div className='form-check mb-2'>
                            <Input
                              className='form-check-input'
                              type='radio'
                              name='enforceOrder'
                              checked={form.values.enforceOrder}
                              onChange={() => {
                                form.setFieldValue('enforceOrder', true)
                              }}
                              id='flexRadioDefault1'
                            />
                            <Label
                              className='form-check-label'
                              htmlFor='flexRadioDefault1'
                            >
                              Yes
                            </Label>
                          </div>
                          <div className='form-check'>
                            <Input
                              className='form-check-input'
                              type='radio'
                              name='enforceOrder'
                              checked={!form.values.enforceOrder}
                              onChange={() => {
                                form.setFieldValue('enforceOrder', false)
                              }}
                              defaultChecked
                            />
                            <Label
                              className='form-check-label'
                              htmlFor='flexRadioDefault2'
                            >
                              No
                            </Label>
                          </div>
                        </div>
                      </Col>

                      <Col sm='12'>
                        <Row className='w-100 pt-3'>
                          <Col sm='6'>
                            <div>
                              <label htmlFor='title' className='form-label'>
                                Test Success Message
                              </label>
                              <Input
                                className='form-control'
                                id='title'
                                name={`messages.success`}
                                placeholder='Enter message'
                                type='textarea'
                                rows='3'
                                value={form.values?.messages?.success}
                                onChange={e => {
                                  form.setFieldValue(
                                    'messages.success',
                                    e.target.value,
                                  )
                                }}
                                onBlur={() =>
                                  form.setFieldTouched(`messages.success`)
                                }
                              />
                            </div>
                          </Col>

                          <Col sm='6'>
                            <div>
                              <label htmlFor='title' className='form-label'>
                                Test Fail Message
                              </label>
                              <Input
                                className='form-control'
                                id='title'
                                name={`messages.fail`}
                                placeholder='Enter message'
                                type='textarea'
                                rows='3'
                                value={form.values?.messages?.fail}
                                onChange={e => {
                                  form.setFieldValue(
                                    `messages.fail`,
                                    e.target.value,
                                  )
                                }}
                                onBlur={() =>
                                  form.setFieldTouched(`messages.fail`)
                                }
                              />
                            </div>
                          </Col>

                          <Col sm='12' className='mt-3'>
                            <div>
                              <label htmlFor='title' className='form-label'>
                                Test Retake Message
                              </label>
                              <Input
                                className='form-control'
                                id='title'
                                name={`messages.retake`}
                                placeholder='Enter message'
                                type='textarea'
                                rows='3'
                                value={form.values?.messages?.retake}
                                onChange={e => {
                                  form.setFieldValue(
                                    `messages.retake`,
                                    e.target.value,
                                  )
                                }}
                                onBlur={() =>
                                  form.setFieldTouched(`messages.retake`)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className='g-1 mb-3'>
                      <Label
                        className='form-check-label'
                        htmlFor='flexRadioDefault1'
                      >
                        Certificate Template
                      </Label>
                      {templates.map(t => (
                        <Col key={t.id} xxl='4'>
                          <div
                            onClick={() => {
                              form.setFieldValue('certificateId', t.id)
                            }}
                            style={{
                              width: 300,
                              height: 234,
                              cursor: 'pointer',
                              background: '#F7F8F9',
                              padding: 10,
                              borderWidth: 2,
                              borderStyle: 'solid',
                              borderColor:
                                form.values.certificateId === t.id
                                  ? '#6963F1'
                                  : 'transparent',
                            }}
                          >
                            <img src={t.preview} className='w-100 h-100' />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Card>

                  <Card className='p-4'>
                    <CardHeader className='border-bottom-0 p-0 pb-4 fs-20 fw-bold'>
                      Language Settings
                    </CardHeader>
                    <Row className='gy-4 mb-3'>
                      {form.values.languages.map((language, i) => (
                        <Col xxl={12} key={language.id}>
                          <div className='form-check form-switch mb-2'>
                            <Input
                              className='form-check-input'
                              type='checkbox'
                              role='switch'
                              disabled={language.code === ENGLISH_LANGUAGE_CODE}
                              id={`switch${language.id}`}
                              checked={language.active}
                              onChange={e => {
                                form.setFieldValue(
                                  `languages[${i}].active`,
                                  e.target.checked,
                                )
                              }}
                            />
                            <Label
                              className='form-check-label'
                              chekc
                              htmlFor={`switch${language.id}`}
                            >
                              {language.name}
                            </Label>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                </Col>

                <Col xxl='6' md='6' className='d-flex align-items-start'>
                  <Row>
                    <Col sm='12'>
                      <Card className='p-4'>
                        <CardHeader
                          className='border-bottom-0 p-0 pb-2 fs-20'
                          style={{ fontWeight: 700 }}
                        >
                          Signatory
                        </CardHeader>
                        <Row className='gy-4 mb-3'>
                          <Col sm='6'>
                            <div>
                              <label className='form-label'>Signatory</label>
                              <div className='d-flex'>
                                <div className='flex-grow-1'>
                                  <AsyncSelectWithSearch
                                    styles={{
                                      control: baseStyles => ({
                                        ...baseStyles,
                                        borderRadius: '4px 0px 0px 4px',
                                        minHeight: 39,
                                        borderColor:
                                          form.touched.signatoryId &&
                                          form.errors.signatoryId
                                            ? '#f06548 !important'
                                            : undefined,
                                      }),
                                    }}
                                    placeholder='Select instructor'
                                    className='select2-container is-invalid'
                                    name='instructorId'
                                    id='instructorId'
                                    onChange={option =>
                                      form.setFieldValue('signatoryId', option)
                                    }
                                    onBlur={() => {
                                      form.setFieldTouched('signatoryId', true)
                                    }}
                                    value={form.values.signatoryId}
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    defaultOptions
                                    loadOptions={fetchAdmins}
                                    error={
                                      form.touched.signatoryId
                                        ? form.errors.signatoryId
                                        : undefined
                                    }
                                  />
                                </div>
                                <div></div>
                              </div>
                              {form.touched.signatoryId &&
                              form.errors.signatoryId ? (
                                <FormFeedback type='invalid'>
                                  {form.errors.signatoryId}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col sm='6' className='d-flex align-items-start'>
                            <div className='w-100'>
                              <Label htmlFor='basiInput' className='form-label'>
                                Signatory position
                              </Label>
                              <Input
                                type='text'
                                className='form-control'
                                name='signatoryPosition'
                                value={form.values.signatoryPosition || ''}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                id='basiInput'
                                invalid={
                                  !!(
                                    form.touched.signatoryPosition &&
                                    form.errors.signatoryPosition
                                  )
                                }
                              />
                              {form.touched.signatoryPosition &&
                              form.errors.signatoryPosition ? (
                                <FormFeedback type='invalid'>
                                  {form.errors.signatoryPosition}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col sm='12'>
                            <SignatureUpload
                              error={form.errors.signature}
                              signature={form.values.signature}
                              isSubmitting={form.isSubmitting}
                              onDrop={acceptedFiles => {
                                handleAcceptedFile(
                                  acceptedFiles,
                                  'signature',
                                  form.setFieldValue,
                                )
                              }}
                              onDelete={() => {
                                form.setFieldValue('signature', null)
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>

                    <Col sm='12'>
                      <Card className='p-4'>
                        <CardHeader
                          className='border-bottom-0 p-0 pb-2 fs-20'
                          style={{ fontWeight: 700 }}
                        >
                          Attestation
                        </CardHeader>
                        <Row className='gy-4 mb-3'>
                          <Col sm='6'>
                            <div>
                              <label htmlFor='bio' className='form-label'>
                                Require attestation statement
                              </label>
                              <div className='form-check mb-2'>
                                <Input
                                  className='form-check-input'
                                  type='radio'
                                  name='attestationStatement'
                                  checked={form.values.attestationStatement}
                                  onChange={() => {
                                    form.setFieldValue(
                                      'attestationStatement',
                                      true,
                                    )
                                  }}
                                  id='flexRadioDefault1'
                                />
                                <Label
                                  className='form-check-label'
                                  htmlFor='flexRadioDefault1'
                                >
                                  Yes
                                </Label>
                              </div>
                              <div className='form-check'>
                                <Input
                                  className='form-check-input'
                                  type='radio'
                                  name='attestationStatement'
                                  checked={!form.values.attestationStatement}
                                  onChange={() => {
                                    form.setFieldValue(
                                      'attestationStatement',
                                      false,
                                    )
                                  }}
                                />
                                <Label
                                  className='form-check-label'
                                  htmlFor='flexRadioDefault2'
                                >
                                  No
                                </Label>
                              </div>
                            </div>
                          </Col>
                          <Col sm='12'>
                            <div>
                              <label
                                htmlFor='description'
                                className='form-label'
                              >
                                Attestation Statement Text
                              </label>
                              <Input
                                className='form-control'
                                id='description'
                                name='attestationStatementText'
                                placeholder='Enter Attestation Statement Text'
                                type='textarea'
                                rows='3'
                                value={form.values.attestationStatementText}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col sm='12'>
                      <Card className='p-4' style={{ zIndex: 10 }}>
                        <CardHeader
                          className='border-bottom-0 p-0 pb-2 fs-20'
                          style={{ fontWeight: 700 }}
                        >
                          Admin Notifications
                        </CardHeader>
                        <Row className='gy-4 mb-3'>
                          <Col sm='12'>
                            <label
                              htmlFor='digitalCourseReplaced'
                              className='form-label'
                            >
                              Digital Course Replaced
                            </label>
                            <AsyncSelectWithSearch
                              name='digitalCourseReplaced'
                              id='digitalCourseReplaced'
                              onChange={option => {}}
                              isMulti
                              isClearable={false}
                              isSearchable={true}
                              placeholder={'Select admins'}
                              defaultOptions
                              loadOptions={fetchAdmins}
                            />
                          </Col>
                          <Col sm='12'>
                            <label
                              htmlFor='courseExpiring'
                              className='form-label'
                            >
                              Course Expiring with no Published Revision
                            </label>
                            <AsyncSelectWithSearch
                              name='courseExpiring'
                              onChange={option => {}}
                              isMulti
                              isClearable={false}
                              isSearchable={true}
                              placeholder={'Select admins'}
                              defaultOptions
                              loadOptions={fetchAdmins}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                <Col className='mt-0'>
                  <Card className='py-2 px-2'>
                    <Row>
                      <Col>
                        <div className='d-flex justify-content-start sticky-row'>
                          <Button
                            color='success'
                            type='submit'
                            className='btn-soft-primary'
                            onClick={() => {
                              router.navigate('/courses')
                            }}
                            disabled={!form.dirty || form.isSubmitting}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Col>
                      {hasPermissionToSave && (
                        <Col>
                          <div className='d-flex justify-content-end sticky-row'>
                            <Button
                              color='success'
                              type='submit'
                              onClick={() => form.handleSubmit}
                              disabled={!form.dirty || form.isSubmitting}
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DefaultCourseSettings)
