import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { USER_COURSE_STATUSES } from '../../../sharedTypes'

interface FilterTabsProps {
  navTab?: USER_COURSE_STATUSES
  navToggle: (tab?: USER_COURSE_STATUSES) => void
  style?: React.CSSProperties
}
const tabs = [
  { label: 'All', value: undefined },
  { label: 'No Courses', value: USER_COURSE_STATUSES.NO_COURSES },
  { label: 'On Schedule', value: USER_COURSE_STATUSES.ON_SCHEDULE },
  {
    label: 'Pending Completion',
    value: USER_COURSE_STATUSES.PENDING_COMPLETION,
  },
  { label: 'Complete', value: USER_COURSE_STATUSES.COMPLETE },
]

const FilterTabs = ({ navTab, navToggle, style }: FilterTabsProps) => {
  return (
    <Nav className='nav-customs-bordered' style={style}>
      {tabs.map(tab => (
        <NavItem key={tab.label}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
            }}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
