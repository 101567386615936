import { TUser } from '../models'
import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import {
  CoursesPermissions,
  FacilityManagementPermissions,
  SettingsPermissions,
  USER_STATUSES,
  UserManagementPermissions,
} from '../lib/types'
import { CountriesEnum, StatesEnum } from '../lib/locations'

export enum AdminsSortBy {
  FACILITY_NAME = '"User->facility"."name"',
  GROUP_NAME = '"User->group"."name"',
  POSITION = '"User"."rawPosition"',
  ROLE_NAME = '"User->role"."name"',
  ID = '"User"."id"',
  STATUS = '"User"."status"',
  USER = 'user',
}

export type GetAdminPermissions =
  | CoursesPermissions.ADD_COURSE_SCHEDULE
  | CoursesPermissions.EDIT_COURSE_SCHEDULE
  | CoursesPermissions.ADD_PACKAGE_SCHEDULE
  | CoursesPermissions.EDIT_PACKAGE_SCHEDULE
  | UserManagementPermissions.VIEW_ADMIN_LISTING
  | FacilityManagementPermissions.VIEW_FACILITY_MANAGEMENT
  | SettingsPermissions.ASSIGN_ROLE_TO_USERS

export namespace GetAdminsDTO {
  export interface Request extends PaginationRequest {
    facilityIds?: number[]
    groupIds?: number[]
    sortBy?: AdminsSortBy
    key?: string
    status?: USER_STATUSES
    permission: GetAdminPermissions
  }
  export interface Response extends PaginatedBaseResponse {
    admins: TUser[]
  }
}

export namespace PatchAdminsDTO {
  export interface Request {
    id?: number
    firstName: string
    lastName: string
    phone: string
    rawPosition: string
    email: string
    oldEmail?: string
    country: CountriesEnum
    state: StatesEnum
    roleId: number
    facilityId: number | null
    departmentIds?: number[]
    groupId: number | null
    avatar?: Blob
  }
  export interface Response extends TUser {}
}
