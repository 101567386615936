import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  OrderType,
  UserManagementPermissions,
  AnnualEducationOverdueSortBy,
  GetAnnualEducationOverdueDTO,
} from '../../../sharedTypes'
import { handleError } from '../../../helpers/toast_helper'
import { getAnnualEducationOverdue } from '../../../helpers/api/users'
import { Table } from 'reactstrap'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import { Link } from 'react-router-dom'
import { usePermissions } from '../../../hooks/usePermissions'
import { TabProps, Tabs } from '../types'
import moment from 'moment/moment'
import UserCellWithDetails from '../../../Components/Common/UserCellWithDetails'
import { useAppSelector } from '../../../hooks/redux'
import UserDepartments from '../../../Components/Common/UserDepartments'

const Columns = [
  {
    name: 'ID',
    sortBy: AnnualEducationOverdueSortBy.ID,
    style: { width: 80 },
  },
  {
    name: 'User',
    sortBy: AnnualEducationOverdueSortBy.USER,
    style: { width: 260 },
  },
  {
    name: 'Department',
    style: { width: 200 },
  },
  {
    name: 'Overdue',
    sortBy: AnnualEducationOverdueSortBy.OVERDUE,
    style: { width: 100 },
  },
]

const AnnualEducationOverdue = ({ setTotalCounts }: TabProps) => {
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )
  const admin = useAppSelector(state => state.User.user)

  const containerRef = useRef<HTMLDivElement>(null)
  const scrollPositionRef = useRef(0)

  const hasPermission = usePermissions(
    UserManagementPermissions.VIEW_USER_PROFILE,
  )

  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [data, setData] = useState<GetAnnualEducationOverdueDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    assignments: [],
  })

  const [query, setQuery] = useState<GetAnnualEducationOverdueDTO.Request>({
    page: 1,
    limit: 30,
    sortBy: AnnualEducationOverdueSortBy.ID,
    orderBy: OrderType.DESC,
  })

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      getAnnualEducationOverdue(query)
        .then(res => {
          setData({
            assignments:
              query.page === 1
                ? res.data.assignments
                : [...data.assignments, ...res.data.assignments],
            page: res.data.page,
            pages: res.data.pages,
            count: res.data.count,
          })
          if (query.page === 1) {
            setTotalCounts(prev => ({
              ...prev,
              [Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION]: res.data.count,
            }))
          }
          afterScroll()
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  useEffect(() => {
    if (selectedGroupId && selectedGroupId !== query.groupId) {
      setQuery(q => ({ ...q, groupId: selectedGroupId, facilityIds: [] }))
    }

    if (selectedFacilityId && selectedFacilityId !== query.facilityId) {
      setQuery(q => ({
        ...q,
        facilityId: selectedFacilityId,
        groupId: undefined,
      }))
    }

    if (!selectedFacilityId && !selectedGroupId) {
      setQuery(q => ({ ...q, facilityId: undefined, groupId: undefined }))
    }

    setFetchData(true)
  }, [selectedFacilityId, selectedGroupId])

  const handleSort = useCallback((column: AnnualEducationOverdueSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
      page: 1,
    }))
  }, [])

  const afterScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPositionRef.current
    }
  }

  const handleScroll = () => {
    if (containerRef.current) {
      scrollPositionRef.current = containerRef.current.scrollTop

      const { scrollTop, clientHeight, scrollHeight } = containerRef.current
      if (
        scrollHeight - (scrollTop + clientHeight) <= 1 &&
        query.page !== data.pages
      ) {
        setQuery(prev => ({ ...prev, page: (query.page as number) + 1 }))
      }
    }
  }

  const getWeekCount = (daysOverdue: number) => {
    const startDate = moment().startOf('day')
    const endDate = moment().add(daysOverdue, 'days').endOf('day')

    const totalDays = endDate.diff(startDate, 'days')

    return Math.ceil(totalDays / 7)
  }

  return (
    <NoResultTableWrapper
      isLoading={isLoading}
      isFiltering={false}
      pages={data.pages}
    >
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className='scroll-not-visible'
        id='dashboard-annual-education-overdue'
      >
        <div className='table-card'>
          <div className='overflow-x-auto'>
            <Table
              className='align-middle mb-0'
              style={{ tableLayout: 'fixed' }}
            >
              <thead>
                <tr className='text-muted fs-14'>
                  {Columns.map(column => (
                    <th
                      scope='col'
                      className='align-middle'
                      style={column.style}
                      key={column.name}
                    >
                      <div className='d-flex align-items-center gap-1'>
                        <span>{column.name}</span>
                        {!!column.sortBy && (
                          <ColumnSortIcon<AnnualEducationOverdueSortBy>
                            sortOrder={query.orderBy}
                            sortedColumn={query.sortBy}
                            column={column.sortBy}
                            handleSort={handleSort}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.assignments.map(assignment => (
                  <tr key={assignment.userId} className='fs-14'>
                    <td>
                      <Link
                        to={
                          hasPermission
                            ? `/user-listing/profile/${assignment.userId}`
                            : '#'
                        }
                        target='_blank'
                        className='fw-normal text-primary'
                      >
                        <span>{assignment.userId}</span>
                      </Link>
                    </td>
                    <UserCellWithDetails
                      user={assignment.user}
                      globalSearch={''}
                      displayFacility={!admin?.facilityId}
                    />
                    <UserDepartments
                      departments={assignment.user.departments || []}
                      key={''}
                    />
                    <td>
                      <span className={`badge badge-soft-grey fs-12 fw-normal`}>
                        {getWeekCount(assignment.daysOverdue)} weeks
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </NoResultTableWrapper>
  )
}

export default AnnualEducationOverdue
