import React, { Fragment, useMemo, useRef } from 'react'
import { Button, Col, Table } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import moment from 'moment/moment'
import _ from 'lodash'
import AsyncSelect from 'react-select/async'
import {
  formattedDuration,
  getAssignedCode,
  loadScheduleOptions,
} from '../../../helpers/course'
import { DigitalAssign, LiveAssign, Step3Props } from './types'
import { COURSE_FILTER_FORMATS, OnlineCourseItem } from '../../../sharedTypes'
import { IColumn } from '../../Common/UsersTable'
import { TCoursePackage } from '../../../sharedTypes/models/coursePackage'

export enum SelectedCoursesSortBy {
  CODE = 'courseCode',
  COURSE = 'name',
  PACKAGE = 'package',
  CREDIT_HOURS = 'duration',
}

interface ICourseColumn extends Omit<IColumn, 'sortBy'> {
  sortBy?: SelectedCoursesSortBy
}

const Columns: ICourseColumn[] = [
  {
    name: 'Code',
    sortBy: SelectedCoursesSortBy.CODE,
    style: { width: '10%' },
  },
  {
    name: 'Package',
    sortBy: SelectedCoursesSortBy.PACKAGE,
    style: { width: '10%' },
  },
  {
    name: 'Course',
    sortBy: SelectedCoursesSortBy.COURSE,
    style: { width: '20%' },
  },
  {
    name: 'Duration',
    sortBy: SelectedCoursesSortBy.CREDIT_HOURS,
    style: { width: '5%' },
  },
]

interface SelectedCourseList {
  type: 'digital' | 'live'
  courses: DigitalAssign[] | LiveAssign[]
  title: string
  onDelete: Step3Props['onDelete']
  onSetDate: Step3Props['onSetDate']
  facilityId?: number
}

const SelectedCourseList = ({
  type,
  courses,
  title,
  onDelete,
  onSetDate,
  facilityId,
}: SelectedCourseList) => {
  const dateAvailableRefs = useRef<{ [key: string]: Flatpickr | null }>({})
  const dueDatePickerRefs = useRef<{ [key: string]: Flatpickr | null }>({})

  const isValidColumn = (columnName: 'dateAvailable' | 'dueDate') => {
    return courses.some(item => !item.isValid && !item[columnName])
  }

  const columns = useMemo(() => {
    let changedColumns = [...Columns]
    if (type === 'digital') {
      changedColumns = [
        ...changedColumns,
        {
          name: 'Date Available*',
          style: {
            width: '15%',
            paddingLeft: '2%',
            color: isValidColumn('dateAvailable') ? '#f06548' : '',
          },
        },
        {
          name: 'Due Date*',
          style: {
            width: '15%',
            paddingLeft: '2%',
            color: isValidColumn('dueDate') ? '#f06548' : '',
          },
        },
      ]
    } else {
      changedColumns = [
        ...changedColumns,
        {
          name: 'Date and Time*',
          style: {
            width: '30%',
            paddingLeft: '2%',
            color: isValidColumn('dueDate') ? '#f06548' : '',
          },
        },
      ]
    }

    return [
      ...changedColumns,
      {
        name: '',
        style: { width: '5%' },
      },
    ]
  }, [type, isValidColumn])

  if (courses.length === 0) {
    return null
  }

  return (
    <div>
      <div
        className={`d-flex justify-content-between align-items-center ${
          type === 'live' ? 'pt-3' : ''
        }`}
      >
        <p className={'fw-medium fs-14'}>{title}</p>
        <span className='text-muted fw-medium'>Total: {_.size(courses)}</span>
      </div>
      <div className='courses-table-container bg-white'>
        <Table className='align-middle table-nowrap mb-0'>
          <thead className='table-light'>
            <tr className='text-muted fs-14'>
              {columns.map(column => (
                <th
                  scope='col'
                  className='fw-normal align-middle bg-white'
                  style={column?.style}
                  key={column.name}
                >
                  {column.name}
                  {/*{!!column.sortBy && (
                    <ColumnSortIcon<SelectedCoursesSortBy>
                      sortOrder={query.orderBy}
                      sortedColumn={query.sortBy}
                      column={column.sortBy}
                      handleSort={handleSort}
                    />
                  )}*/}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {courses.map((item, i: number) => (
              <Fragment key={i}>
                <tr className={'fs-14 fw-light my-1'}>
                  <td className='text-primary fw-normal'>
                    {getAssignedCode(item)}
                  </td>
                  <td className='text-primary fw-normal'>
                    {(item.course as OnlineCourseItem).package?.code
                      ? _.get(item.course, 'package.code', '')
                      : '-'}
                  </td>
                  <td className={'text-truncate course-name pe-5'}>
                    {(item.course as TCoursePackage).name ??
                      _.get(item.course, 'translations[0].content.name', '-')}
                  </td>
                  <td>
                    <span
                      className={`badge badge-soft-grey fs-12 fw-normal mt-1`}
                    >
                      {'duration' in item.course &&
                        !item.course.available_formats.includes(
                          COURSE_FILTER_FORMATS.COMPETENCY_ONLY,
                        ) &&
                        formattedDuration(item.course.duration)}
                    </span>
                  </td>
                  {type === 'digital' && (
                    <>
                      <td>
                        <div className='form-icon right'>
                          <Flatpickr
                            className={`form-control form-control-icon border-0 ${
                              !item.isValid && !item.dateAvailable
                                ? 'is-invalid'
                                : ''
                            }`}
                            ref={ref =>
                              (dateAvailableRefs.current[
                                `date-available-${i}`
                              ] = ref)
                            }
                            id={`date-available-${i}`}
                            name='deadline'
                            placeholder='--/--/----'
                            onChange={option => {
                              onSetDate(type, {
                                ...item,
                                dateAvailable: option[0],
                                isValid: true,
                              })
                            }}
                            value={item.dateAvailable}
                            options={{
                              dateFormat: 'm-d-Y',
                              formatDate: date =>
                                moment(date).format('MM/DD/YYYY'),
                              minDate: Date.now(),
                              allowInvalidPreload: true,
                              allowInput: true,
                            }}
                          />
                          <i
                            className='ri-calendar-2-line fs-20 text-primary cursor-pointer'
                            onClick={() => {
                              dateAvailableRefs.current[
                                `date-available-${i}`
                              ]?.flatpickr.open()
                            }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className='form-icon right'>
                          <Flatpickr
                            className={`form-control form-control-icon border-0 ${
                              !item.isValid &&
                              'dueDate' in item &&
                              (!item.dueDate ||
                                !moment(item.dueDate).isAfter(
                                  moment(item.dateAvailable),
                                ))
                                ? 'is-invalid'
                                : ''
                            }`}
                            id={`due-date-${i}`}
                            ref={ref =>
                              (dueDatePickerRefs.current[`due-date-${i}`] = ref)
                            }
                            name='deadline'
                            placeholder='--/--/----'
                            onChange={option => {
                              onSetDate(type, {
                                ...item,
                                dueDate: option[0],
                                isValid: true,
                              })
                            }}
                            value={'dueDate' in item ? item.dueDate : undefined}
                            options={{
                              dateFormat: 'd-m-Y',
                              formatDate: date =>
                                moment(date).format('MM/DD/YYYY'),
                              minDate: item.dateAvailable || Date.now(),
                              allowInvalidPreload: true,
                            }}
                          />
                          <i
                            className='ri-calendar-2-line fs-20 text-primary cursor-pointer'
                            onClick={() => {
                              dueDatePickerRefs.current[
                                `due-date-${i}`
                              ]?.flatpickr.open()
                            }}
                          ></i>
                        </div>
                      </td>
                    </>
                  )}
                  {type === 'live' && (
                    <>
                      <td>
                        <AsyncSelect
                          className='select2-container'
                          key={courses.length}
                          classNamePrefix={`select2-selection ${
                            !item.isValid ? 'is-invalid' : ''
                          } form-select`}
                          isSearchable={false}
                          placeholder={'Select Date & Time'}
                          defaultOptions
                          loadOptions={() =>
                            loadScheduleOptions(item, facilityId)
                          }
                          onChange={option => {
                            onSetDate(type, {
                              ...item,
                              dateAvailable: moment().toDate(),
                              dueDate: option
                                ? moment(option.label).toDate()
                                : undefined,
                              isValid: true,
                              scheduleId: option?.value,
                            })
                          }}
                          value={
                            'scheduleId' in item
                              ? {
                                  value: item.scheduleId,
                                  label: moment(item.dueDate).format(
                                    'MM/DD/YYYY, hh:mm A',
                                  ),
                                }
                              : undefined
                          }
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                        />
                      </td>
                    </>
                  )}
                  <td className='text-center'>
                    <i
                      className='ri-delete-bin-2-line text-danger cursor-pointer'
                      onClick={() => onDelete(item)}
                    ></i>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

const ChooseDateTime = ({
  onCancel,
  onNext,
  assignOptions,
  onDelete,
  onSetDate,
}: Step3Props) => {
  return (
    <Fragment>
      <div className='px-3 py-2 choose-courses-date-time'>
        <SelectedCourseList
          type='digital'
          courses={assignOptions.digital}
          title={'Digital Courses and Competency Only'}
          onDelete={onDelete}
          onSetDate={onSetDate}
        />
        <SelectedCourseList
          type='live'
          courses={assignOptions.live}
          title={'In-Person and Virtual Conference Courses'}
          onDelete={onDelete}
          onSetDate={onSetDate}
          facilityId={assignOptions.facilities[0]}
        />
      </div>
      <div className='px-3 py-2 row g-3'>
        <Col lg={12}>
          <div className='hstack gap-2 justify-content-between'>
            <Button
              className='btn-soft-primary align-middle'
              color='secondary'
              onClick={onCancel}
            >
              Back
            </Button>
            <Button className='align-middle' color='primary' onClick={onNext}>
              Next
            </Button>
          </div>
        </Col>
      </div>
    </Fragment>
  )
}

export default ChooseDateTime
