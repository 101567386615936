import Highlighter from 'react-highlight-words'
import { UncontrolledTooltip } from 'reactstrap'
import React, { useMemo } from 'react'
import { TDepartment } from '../../sharedTypes'

const LIMIT = 1

interface UserDepartmentsProps {
  departments: TDepartment[]
  key: string
  className?: string
}

const UserDepartments = ({
  departments,
  key,
  className = '',
}: UserDepartmentsProps) => {
  const departmentNames = useMemo(
    () => departments.map(d => d.name),
    [departments],
  )

  return (
    <td className='text-break' style={{ whiteSpace: 'normal' }}>
      <span className='text-body'>
        <Highlighter
          highlightClassName='text-highlight'
          className={className}
          searchWords={[key || '']}
          highlightTag={'span'}
          autoEscape={true}
          textToHighlight={departments?.[0]?.name}
        />

        {departmentNames.length > LIMIT && (
          <>
            <UncontrolledTooltip placement='top' target={'viewMore'}>
              {departmentNames.slice(LIMIT, departmentNames.length).join(', ')}
            </UncontrolledTooltip>

            <span className='ps-1 cursor-pointer' id={'viewMore'}>
              +{departmentNames.length - LIMIT}
            </span>
          </>
        )}
      </span>
    </td>
  )
}

export default UserDepartments
