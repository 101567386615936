import {
  FacilityManagementPermissions,
  UserManagementPermissions,
} from '../lib/types'
import { TCompany, TFacilityStatus } from '../models'
import { OrderType } from './pagination'

export enum CompaniesSortBy {
  FACILITY_NAME = 'facilities.name',
  GROUP_NAME = 'groups.name',
  STATUS = 'facilities.status',
}

export enum GetCompanyFacilitiesPermissions {
  VIEW_FACILITY_MANAGEMENT = FacilityManagementPermissions.VIEW_FACILITY_MANAGEMENT,
  VIEW_FACILITY_DETAILS = FacilityManagementPermissions.VIEW_FACILITY_DETAILS,
  ADD_NEW_ADMIN = UserManagementPermissions.ADD_NEW_ADMIN,
  EDIT_ADMIN = UserManagementPermissions.EDIT_ADMIN,
}

export namespace GetCompaniesFacilitiesDTO {
  export interface Request {
    key?: string
    status?: TFacilityStatus
    orderBy?: OrderType
    sortBy?: CompaniesSortBy
    permission?: GetCompanyFacilitiesPermissions
    accessible?: boolean
  }
  export type Response = TCompany[]
}
