import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import _ from 'lodash'
import moment from 'moment/moment'
import Highlighter from 'react-highlight-words'
import { Link } from 'react-router-dom'
import { CoursesGridViewProps } from './types'
import defaultCourseCover from '../../../assets/images/course-default-cover.png'
import {
  ONLINE_COURSE_STATUS,
  OnlineCourseFormatsEnum,
} from '../../../sharedTypes'
import {
  BadgeDarkColorByStatus,
  formattedDuration,
} from '../../../helpers/course'

const CoursesGridView = ({
  courses,
  query,
  handleDelete,
  handleClone,
  onSelect,
  selectedEntities,
  permissions,
}: Omit<CoursesGridViewProps, 'handleSort'>) => {
  const [showMore, setShowMore] = useState<number | null>(null)

  return (
    <Row className='d-flex mt-4 flex-wrap position-relative course-card-container'>
      {courses.map((course, index) => (
        <div className='course-card position-relative' key={index}>
          <Card className={showMore === course.id ? 'expended-card' : ''}>
            <div className='position-absolute select-course'>
              <Input
                className='form-check-input cursor-pointer m-0'
                type='checkbox'
                name={`selectCourse${course.id}`}
                id={`selectCourse${course.id}`}
                disabled={
                  course.status === ONLINE_COURSE_STATUS.DRAFT ||
                  moment(course.expirationDate).isBefore(moment(), 'minute')
                }
                onChange={() => {
                  onSelect(course)
                }}
                checked={!!_.find(selectedEntities, { id: course.id })}
              />
            </div>
            <div className='delete-action position-absolute d-flex align-items-start'>
              <div className='d-flex gap-2'>
                <span className='badge text-light-purple fs-12 fw-medium course-duration'>
                  {!(course.formats || []).includes(
                    OnlineCourseFormatsEnum.COMPETENCY,
                  ) && formattedDuration(course.duration)}
                </span>
                <span
                  className={`badge badge-${
                    BadgeDarkColorByStatus[course.status]
                  } fs-12 fw-medium text-capitalize`}
                >
                  {course.status}
                </span>
              </div>
            </div>
            <Link
              className='cursor-pointer'
              to={`/courses/general/${course.id}`}
              target='_blank'
            >
              <div className='course-cover'>
                <img
                  className='card-img-top img-fluid rounded w-100 h-100'
                  src={course.cover?.path ?? defaultCourseCover}
                  alt='NO IMAGE'
                  style={{
                    aspectRatio: 1,
                    objectFit: 'cover',
                  }}
                />
              </div>
            </Link>

            <CardBody className='d-flex p-0'>
              <Col className='d-flex flex-column align-items-start justify-content-center gap-2 p-3'>
                <div className='d-flex justify-content-between w-100'>
                  <span className='text-muted fs-14'>
                    <Link
                      className='text-muted cursor-pointer'
                      to={`/courses/general/${course.id}`}
                      target='_blank'
                    >
                      <Highlighter
                        highlightClassName='text-highlight'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={course.code}
                      />
                    </Link>
                  </span>
                  <div className='d-flex align-items-center gap-2'>
                    {permissions.clone && (
                      <>
                        <UncontrolledTooltip
                          placement='top'
                          target={'cloneCourse' + course.id}
                        >
                          Clone
                        </UncontrolledTooltip>
                        <i
                          className='bx bx-duplicate cursor-pointer text-muted'
                          id={'cloneCourse' + course.id}
                          onClick={() => {
                            handleClone(course)
                          }}
                        ></i>
                      </>
                    )}

                    {permissions.delete && (
                      <>
                        <UncontrolledTooltip
                          placement='top'
                          target={`deleteCourse${course.id}`}
                        >
                          Delete
                        </UncontrolledTooltip>
                        <i
                          className={`${
                            !course.deletable
                              ? 'pe-none text-disabled'
                              : 'text-danger cursor-pointer'
                          } ri-delete-bin-2-line`}
                          id={`deleteCourse${course.id}`}
                          onClick={() => handleDelete(course)}
                        ></i>
                      </>
                    )}
                  </div>
                </div>

                <Link
                  className='fs-14 text-body fw-medium cursor-pointer'
                  to={`/courses/general/${course.id}`}
                  target='_blank'
                >
                  <Highlighter
                    highlightClassName='text-highlight'
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={_.get(
                      course,
                      'translations[0].content.name',
                      '-',
                    )}
                  />
                </Link>
                <div>
                  <span
                    className={`badge badge-outline-info fs-12 fw-normal text-capitalize`}
                  >
                    {course.type}
                  </span>
                  {/*<span*/}
                  {/*  className={`badge badge-soft-info fs-12 fw-normal text-capitalize`}*/}
                  {/*>*/}
                  {/*  {'Infusion Therapy'}*/}
                  {/*</span>*/}
                </div>
                <span
                  className={`text-muted fs-14 course-description
                ${showMore !== course.id ? 'text-truncate-two-lines' : ''}`}
                >
                  <Highlighter
                    highlightClassName='text-highlight'
                    searchWords={[query.key || '']}
                    highlightTag={'span'}
                    autoEscape={true}
                    textToHighlight={_.get(
                      course,
                      'translations[0].content.description',
                      '-',
                    )}
                  />
                </span>
                {showMore !== course.id && (
                  <span
                    className='text-primary fw-medium cursor-pointer'
                    onClick={() => {
                      setShowMore(course.id)
                    }}
                  >
                    Show More
                    <i className='ri-arrow-down-s-line ms-1'></i>
                  </span>
                )}
                {showMore === course.id && (
                  <>
                    <Row className='w-100'>
                      <Col md={6}>
                        <span className='fw-medium text-body fs-14'>
                          Publish Date:{' '}
                        </span>
                      </Col>
                      <Col md={6} className='pe-0'>
                        <span className='text-muted fs-14'>
                          {course.publishedDate
                            ? moment(course.publishedDate).format('MM/DD/YYYY')
                            : '-'}
                        </span>
                      </Col>
                    </Row>
                    {course.expirationDate && (
                      <Row className='w-100'>
                        <Col md={6}>
                          <span className='fw-medium text-body fs-14'>
                            Expiration Date:{' '}
                          </span>
                        </Col>
                        <Col md={6} className='pe-0'>
                          <span className='text-muted fs-14'>
                            {moment(course.expirationDate).format('MM/DD/YYYY')}
                          </span>
                        </Col>
                      </Row>
                    )}
                    <Row className='w-100'>
                      <Col md={6}>
                        <span className='fw-medium text-body fs-14'>
                          Formats Available:{' '}
                        </span>
                      </Col>
                      <Col md={6} className='pe-0'>
                        <span className='text-muted fs-14'>
                          {course.available_formats.join(', ')}
                        </span>
                      </Col>
                    </Row>
                    <span
                      className='text-primary fw-medium cursor-pointer'
                      onClick={() => {
                        setShowMore(null)
                      }}
                    >
                      Show Less
                      <i className='ri-arrow-up-s-line ms-1'></i>
                    </span>
                  </>
                )}
              </Col>
            </CardBody>
          </Card>
        </div>
      ))}
    </Row>
  )
}

export default CoursesGridView
