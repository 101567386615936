import React, { useEffect, useState } from 'react'
import { Card, Col, CardBody, CardHeader } from 'reactstrap'

import {
  FacilityManagementPermissions,
  GetUsersDTO,
  OrderType,
  TFacility,
  TUser,
  UsersSortBy,
} from '../../../sharedTypes'
import UsersTable, {
  Departments,
  IColumn,
  UserCourseStatus,
  UserInfo,
} from '../../../Components/Common/UsersTable'
import { getUsers } from '../../../helpers/api/users'
import { SearchInput } from '../../../Components/Common/SearchInput'
import moment from 'moment'
import { handleError } from '../../../helpers/toast_helper'

const Columns: IColumn[] = [
  {
    name: 'User',
    sortBy: UsersSortBy.USER,
    component: UserInfo,
  },
  {
    name: 'Department',
    component: Departments,
  },
  {
    name: 'Position',
    sortBy: UsersSortBy.POSITION_NAME,
    selector: 'position.name',
  },
  {
    name: 'Hire Date',
    sortBy: UsersSortBy.HIRE_DATE,
    selector: 'hireDate',
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  {
    component: UserCourseStatus,
    name: 'Status',
    style: { width: 150 },
    sortBy: UsersSortBy.COURSE_STATUS,
  },
]

interface UserInfoProps {
  facility: TFacility
}
const FacilityEmployees = ({ facility }: UserInfoProps) => {
  const [users, setUsers] = useState<TUser[]>([])
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)
  const [globalSearch, setSearch] = useState<string>('')

  const [sortedColumn, setSortedColumn] = useState<UsersSortBy | null>(null)
  const [sortOrder, setSortOrder] = useState<OrderType>(OrderType.ASC)

  const handleSort = (column: UsersSortBy) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === OrderType.ASC ? OrderType.DESC : OrderType.ASC)
    } else {
      setSortedColumn(column)
      setSortOrder(OrderType.ASC)
    }
  }

  //Changed order, need to re-fetch users, keep same page
  useEffect(() => {
    fetchUsers()
  }, [sortedColumn, sortOrder])

  useEffect(() => {
    fetchUsers(0)
  }, [globalSearch])

  const onPageChanged = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage)
    }
  }

  useEffect(() => {
    fetchUsers(page)
  }, [page])

  const fetchUsers = (_page?: number) => {
    const params: GetUsersDTO.Request = {
      page: typeof _page === 'number' ? _page + 1 : page + 1,
      limit: 10,
      key: globalSearch ? globalSearch : undefined,
      facilityIds: [facility.id],
      permission: FacilityManagementPermissions.VIEW_FACILITY_EMPLOYEES,
    }

    if (sortedColumn) {
      params.sortBy = sortedColumn
      params.orderBy = sortOrder
    }

    getUsers(params)
      .then(res => {
        setUsers(res.data.users)
        setTotalPages(res.data.pages)
        setTotalUsers(res.data.count)
        onPageChanged(res.data.page - 1)
      })
      .catch(handleError)
  }

  return (
    <Col>
      <Card>
        <CardHeader>
          <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
            <div className='flex-grow-1'>
              <p className='fs-16 mb-0' style={{ color: '#7C8193' }}>
                Employees ({totalUsers})
              </p>
            </div>
            <SearchInput
              style={{ maxWidth: 400 }}
              onChange={setSearch}
              value={globalSearch}
            />
          </div>
        </CardHeader>
        <CardBody>
          <UsersTable
            columns={Columns}
            users={users}
            page={page}
            totalPages={totalPages}
            totalUsers={totalUsers}
            onPageChanged={onPageChanged}
            sortOrder={sortOrder}
            sortedColumn={sortedColumn}
            handleSort={handleSort}
            globalSearch={globalSearch}
          />
        </CardBody>
      </Card>
    </Col>
  )
}

export default FacilityEmployees
