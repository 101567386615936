import { combineReducers } from 'redux'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from 'redux-state-sync'

// Authentication
import UserReducer from './auth/user/reducer'
import FacilitiesReducer from './facilities/reducer'
import LanguagesReducer from './languages/reducer'

// Dashboard Analytics
import { configureStore } from '@reduxjs/toolkit'

const rootReducer = combineReducers({
  User: UserReducer,
  Languages: LanguagesReducer,
  FacilityOptions: FacilitiesReducer,
})

const persistConfig = {
  key: 'mastered',
  storage,
  whitelist: ['User', 'FacilityOptions'],
  version: 2,
}
const config = {
  blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
}
const syncStateMiddleware = [createStateSyncMiddleware(config)]

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(syncStateMiddleware)
  },
})

export const persistor = persistStore(store)

// initMessageListener(store)
initStateWithPrevTab(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
