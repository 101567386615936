import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import UsersTable, {
  Departments,
  UserId,
  UserInfo,
} from '../../../Components/Common/UsersTable'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  GetUsersDTO,
  OrderType,
  USER_REGISTERED_STATUSES,
  USER_STATUSES,
  UserManagementPermissions,
  UsersSortBy,
} from '../../../sharedTypes'
import moment from 'moment/moment'
import { getUsers } from '../../../helpers/api/users'
import { handleError } from '../../../helpers/toast_helper'
import { TabProps, Tabs } from '../types'
import { useAppSelector } from '../../../hooks/redux'
import _ from 'lodash'

const Columns = [
  {
    name: 'ID',
    component: UserId,
    sortBy: UsersSortBy.ID,
    style: { width: '15%' },
  },
  {
    name: 'User',
    component: UserInfo,
    sortBy: UsersSortBy.USER,
    style: { width: '45%' },
  },
  {
    name: 'Facility',
    sortBy: UsersSortBy.FACILITY_NAME,
    style: { width: '20%' },
    selector: 'facility.name',
  },
  {
    name: 'Department',
    component: Departments,
    style: { width: '20%' },
  },
  {
    name: 'Hire Date',
    sortBy: UsersSortBy.HIRE_DATE,
    selector: 'hireDate',
    style: { width: '20%' },
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
]

const UsersNotRegistered = ({ setTotalCounts }: TabProps) => {
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )
  const user = useAppSelector(state => state.User.user)

  const containerRef = useRef<HTMLDivElement>(null)
  const scrollPositionRef = useRef(0)

  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [data, setData] = useState<GetUsersDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    users: [],
  })

  const [query, setQuery] = useState<GetUsersDTO.Request>({
    page: 1,
    limit: 30,
    facilityIds: [],
    departmentIds: [],
    positionIds: [],
    courseStatus: undefined,
    sortBy: UsersSortBy.ID,
    orderBy: OrderType.ASC,
    permission: UserManagementPermissions.VIEW_USER_LISTING,
    registeredStatuses: [USER_REGISTERED_STATUSES.No],
    status: [USER_STATUSES.ACTIVE],
  })

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      getUsers(query)
        .then(res => {
          setData({
            users:
              query.page === 1
                ? res.data.users
                : [...data.users, ...res.data.users],
            page: res.data.page,
            pages: res.data.pages,
            count: res.data.count,
          })
          if (query.page === 1) {
            setTotalCounts(prev => ({
              ...prev,
              [Tabs.USERS_NOT_REGISTERED]: res.data.count,
            }))
          }
          afterScroll()
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  useEffect(() => {
    if (selectedGroupId && !_.isEqual([selectedGroupId], query.groupIds)) {
      setQuery(q => ({ ...q, groupIds: [selectedGroupId], facilityIds: [] }))
    }

    if (
      selectedFacilityId &&
      !_.isEqual([selectedFacilityId], query.facilityIds)
    ) {
      setQuery(q => ({ ...q, facilityIds: [selectedFacilityId], groupIds: [] }))
    }

    if (!selectedFacilityId && !selectedGroupId) {
      setQuery(q => ({ ...q, facilityIds: [], groupIds: [] }))
    }

    setFetchData(true)
  }, [selectedFacilityId, selectedGroupId])

  const handleSort = useCallback((column: UsersSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
      page: 1,
    }))
  }, [])

  const columns = useMemo(() => {
    if (user && user.facilityId) {
      return Columns.filter(column => column.name !== 'Facility')
    }
    return Columns
  }, [user])

  const afterScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPositionRef.current
    }
  }

  const handleScroll = () => {
    if (containerRef.current) {
      scrollPositionRef.current = containerRef.current.scrollTop

      const { scrollTop, clientHeight, scrollHeight } = containerRef.current
      if (
        scrollHeight - (scrollTop + clientHeight) <= 1 &&
        query.page !== data.pages
      ) {
        // Load more data when reaching the bottom
        setQuery(prev => ({ ...prev, page: (query.page as number) + 1 }))
      }
    }
  }
  return (
    <NoResultTableWrapper
      isLoading={isLoading}
      isFiltering={false}
      pages={data.pages}
    >
      <div
        ref={containerRef}
        style={{ overflowY: 'auto' }}
        className='scroll-not-visible'
        id='dashboard-not-registered-users'
        onScroll={handleScroll}
      >
        <UsersTable
          columns={columns}
          users={data.users}
          sortedColumn={query.sortBy}
          page={data.page - 1}
          sortOrder={query.orderBy}
          totalPages={data.pages}
          onPageChanged={page => {
            setQuery(prev => ({ ...prev, page: ++page }))
          }}
          handleSort={handleSort}
          totalUsers={data.count}
          globalSearch={query.key || ''}
          showPagination={false}
          id='users-not-registered-table'
        />
      </div>
    </NoResultTableWrapper>
  )
}

export default UsersNotRegistered
