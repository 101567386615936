import {
  Col,
  FormFeedback,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import classnames from 'classnames'
import React, { useCallback } from 'react'
import _ from 'lodash'
import { FormikValues } from 'formik'
import { Option, TLanguage } from '../../../sharedTypes'

interface SurveyTranslationsProps {
  form: FormikValues
  selectedLanguages: Option<number>[]
  languages: TLanguage[]
  selectedLanguage: number
  setSelectedLanguage: (id: number) => void
}

const SurveyTranslations = ({
  form,
  languages,
  selectedLanguages,
  selectedLanguage,
  setSelectedLanguage,
}: SurveyTranslationsProps) => {
  const translationError = useCallback(
    (index: number, field: string) => {
      const error = _.get(form, `errors.translations[${index}].${field}`)
      const touched = _.get(form, `touched.translations[${index}].${field}`)

      return error && touched ? (
        <FormFeedback type='invalid'>{error}</FormFeedback>
      ) : null
    },
    [form],
  )
  return (
    <div className='flex-shrink-0 my-2'>
      <Nav
        tabs
        className='nav justify-content-start mb-2 nav-tabs-custom rounded card-header-tabs'
      >
        {selectedLanguages.map(l => (
          <NavItem key={l.value}>
            <NavLink
              className={`${classnames({
                active: selectedLanguage === l.value,
              })} cursor-pointer `}
              onClick={() => {
                setSelectedLanguage(l.value)
              }}
            >
              {l.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={selectedLanguage}>
        {languages.map((language, index: number) => (
          <TabPane tabId={language.id} key={index}>
            <div className='vstack gap-4 mt-3'>
              <div>
                <label htmlFor='groupName' className='form-label'>
                  Name*
                </label>
                <Input
                  name='name'
                  className='form-control'
                  id='groupName'
                  placeholder='Enter name'
                  type='text'
                  onChange={e => {
                    form.setFieldValue(
                      `translations[${index}]['name']`,
                      e.target.value,
                    )
                  }}
                  onBlur={() =>
                    form.setFieldTouched(`translations[${index}]['name']`)
                  }
                  value={form.values.translations[index]?.name || ''}
                  invalid={!!translationError(index, 'name')}
                />
                {translationError(index, 'name')}
              </div>

              <div>
                <label htmlFor='description' className='form-label'>
                  Description
                </label>
                <Input
                  name='description'
                  className='form-control'
                  id='groupName'
                  type='textarea'
                  onChange={e => {
                    form.setFieldValue(
                      `translations[${index}]['description']`,
                      e.target.value,
                    )
                  }}
                  onBlur={() =>
                    form.setFieldTouched(
                      `translations[${index}]['description']`,
                    )
                  }
                  value={form.values.translations[index]?.description || ''}
                  invalid={!!translationError(index, 'description')}
                />
                {translationError(index, 'description')}
              </div>

              <div>
                <label htmlFor='groupName' className='form-label'>
                  Notification Title
                </label>
                <Input
                  name='title'
                  className='form-control'
                  id='groupName'
                  placeholder='Enter title'
                  type='text'
                  onChange={e => {
                    form.setFieldValue(
                      `translations[${index}]['title']`,
                      e.target.value,
                    )
                  }}
                  onBlur={() =>
                    form.setFieldTouched(`translations[${index}]['title']`)
                  }
                  value={form.values.translations[index]?.title || ''}
                  invalid={!!translationError(index, 'title')}
                />
                {translationError(index, 'title')}
              </div>

              <div>
                <label htmlFor='description' className='form-label'>
                  Notification Message
                </label>
                <Input
                  name='message'
                  className='form-control'
                  id='groupName'
                  type='textarea'
                  onChange={e => {
                    form.setFieldValue(
                      `translations[${index}]['message']`,
                      e.target.value,
                    )
                  }}
                  onBlur={() =>
                    form.setFieldTouched(`translations[${index}]['message']`)
                  }
                  value={form.values.translations[index]?.message || ''}
                  invalid={!!translationError(index, 'message')}
                />
                {translationError(index, 'message')}
              </div>
            </div>
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

export default SurveyTranslations
